import React from "react";

const SquarePage = () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Square Component</h1>
      </div>

      <p className="clone_para">
        A Square component will always be a perfect square and centers its
        content.
      </p>

      <h2 id="style-properties" className="clone_head">
        Style Properties
      </h2>
      <p className="clone_para">
        Style properties allow you to customize the look and feel of the
        component. Combinations of styles applied to components can be saved as
        Stylesheets to easily reuse styles throughout your app. Styles can also
        be set dynamically using Variables. To learn more about all the
        different styling properties and how they work, take a look at Intro to
        Styling.
      </p>

      <h2 id="configuration-properties" className="clone_head">
        Configuration Properties
      </h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Size</td>
            <td>Define the size of the square.</td>
          </tr>
          <tr>
            <td>Bg Color</td>
            <td>Set the background color of the square.</td>
          </tr>
        </tbody>
      </table>

      <h2 id="data-properties" className="clone_head">
        Data Properties
      </h2>
      <h5 id="conditional-display" className="clone_heading">
        Conditional Display
      </h5>
      <p className="clone_para">
        You can conditionally display a component based on a given condition.
        Learn more about conditionally displaying components in the Conditional
        Display doc.
      </p>
    </>
  );
};

export default SquarePage;
