import React from "react";
import Stylesheets from "../../../assets/Image/Stylesheet.png";
// import Stylesheets from "../../../assets/Image/Stylesheets.png";
// import Stylesheets from "../../../assets/Image/Stylesheets.png";

const StyleSheet = () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Stylesheets</h1>
      </div>

      <p className="clone_para">
        Like in CSS, stylesheets in React Native are a set of saved style
        properties that are used to control the layout and design of a screen or
        component. These stylesheets allow you to apply consistent styling
        across components and update them from a single place.
      </p>

      <p className="clone_para">
        New stylesheets can be created in the Styles panel of any component.
        When you create a Saved Stylesheet, you can configure the style
        properties for a component or Screen the way you like, save the style
        configuration to a Stylesheet, and then apply it to any other component
        of the same type.
      </p>

      <p className="clone_para">
        You can also switch components between stylesheets quickly, duplicate a
        stylesheet to make a new variant, make changes and push an update to all
        versions of those stylesheets, and more.
      </p>

      <p className="clone_para">
        Saved stylesheets will allow you to make your apps more consistent and
        easier to style, and save you a ton of time as your app gets more
        complex.
      </p>

      <h2 id="style-properties" className="clone_head">
        How It Works
      </h2>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Stylesheets} alt="" width="80%" />
      </span>
      <p className="clone_para">
        On the right-hand side of any screen or component, you can select an
        existing stylesheet from the dropdown or create a new stylesheet by
        typing a name for that stylesheet and hitting enter. Remember, when you
        save a new stylesheet, you can only use it with other components of the
        same type (i.e., Buttons).
      </p>

      <p className="clone_para">
        After you create or select a stylesheet, you'll see the styles that are
        set turn from yellow to purple. This indicates that this style is set
        but stored in the stylesheet. You can still override styles set by a
        stylesheet if you need a specific component to look a little different
        than the rest.
      </p>

      <h2 id="style-properties" className="clone_head">
        Stylesheet Management
      </h2>
      <p className="clone_para">
        The buttons next to the stylesheet allow you to Duplicate, Update, or
        Reset:
      </p>

      <p className="clone_para">
        <strong>Duplicate:</strong> This creates a new stylesheet with the same
        set of properties, allowing you to quickly create a new variant.
      </p>
      <p className="clone_para">
        <strong>Update:</strong> This takes all of the set styles and overrides
        and stores them in the stylesheet. All components using this stylesheet
        will be updated accordingly.
      </p>
      <p className="clone_para">
        <strong>Reset:</strong> This resets all of the overrides for a given
        component back to the stylesheet default.
      </p>

      {/* <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Stylesheets} alt="" width="80%" />
      </span> */}
      <p className="clone_para">
        You can also view a specific stylesheet and remove a specific style prop
        from it, updating the stylesheet immediately.
      </p>
      {/* <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Stylesheets} alt="" width="80%" />
      </span> */}
    </>
  );
};

export default StyleSheet;
