import React from "react";
import Size from "../../../assets/Image/Size.png";
import Position from "../../../assets/Image/Position.png";
import NotesBox from "../../docPageNote/NotesBox";

const SizePage = () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Size & Position</h1>
      </div>
      <h2 id="size-properties" className="clone_head">
        Size
      </h2>
      <p className="clone_para">
        Specify minimum, maximum, or specifically set heights and widths for the
        element.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Size} alt="" width="50%" />
      </span>
      <h5 id="component-specific-styles" className="clone_heading">
        Property Descriptions
      </h5>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>auto</td>
            <td>
              The default setting that is calculated based on what other
              elements, if any, are inside the element.
            </td>
          </tr>
          <tr>
            <td>pt</td>
            <td>Explicitly set an exact height/width.</td>
          </tr>
          <tr>
            <td>percentage</td>
            <td>
              Set the height/width as a percentage of the height/width of the
              parent element.
            </td>
          </tr>
        </tbody>
      </table>
      <h2 id="position-properties" className="clone_head">
        Position
      </h2>
      <NotesBox noteText="positions" />{" "}
      <p className="clone_para">
        Determines how a child element is placed in its parent container.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Position} alt="" />
      </span>
      <h5 id="component-specific-styles" className="clone_heading">
        Property Descriptions
      </h5>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Relative</td>
            <td>
              Positions the element relative to the other elements in the parent
              container.
            </td>
          </tr>
          <tr>
            <td>Absolute</td>
            <td>
              Takes the element out of the flow of all other elements in the
              child container. The top, right, left, and bottom values are used
              to determine the position of the element.
            </td>
          </tr>
          <tr>
            <td>Z-index</td>
            <td>
              Determines the level of the element. The higher the Z-Index, the
              higher priority the element will have in regards to stacking.
            </td>
          </tr>
          <tr>
            <td>Overflow</td>
            <td>
              Determines the behavior of remaining content that goes beyond the
              bounds of the container.
            </td>
          </tr>
        </tbody>
      </table>
      <h2 id="additional-resources" className="clone_head">
        Additional Resources
      </h2>
      <p className="clone_para">
        For more information, refer to these resources:
      </p>
      <li>
        <a
          href="https://reactnative.dev/docs/layout-props#position"
          target="_blank"
          rel="noopener noreferrer"
        >
          React Native Position Absolute Property
        </a>
      </li>
      <li>
        <a
          href="https://reactnative.dev/docs/zindex"
          target="_blank"
          rel="noopener noreferrer"
        >
          React Native Z-Index Property
        </a>
      </li>
      <li>
        <a
          href="https://developer.mozilla.org/en-US/docs/Web/CSS/position"
          target="_blank"
          rel="noopener noreferrer"
        >
          MDN: CSS Position Property
        </a>
      </li>
    </>
  );
};

export default SizePage;
