// ModalEditor.js

import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { FormatAlignLeft, DataObject, Title } from "@mui/icons-material";
import TextFieldSvg from "../../../../common/svgComponents/TextFieldSvg";
import TextSvg from "../../../../common/svgComponents/TextSvg";

const ModalEditor = ({
  modalIsOpen,
  setModalIsOpen,
  closeModalEditor,
  onLabelChange,
  onDisabledChange,
  modalData,
  onJsonOptionChangeHandler,
  setJsonText,
  jsonText,
  setText,
  text,
}) => {
  const [activeTab, setActiveTab] = useState("text");

  const [error, setError] = useState("");
  const [savedContent, setSavedContent] = useState(null); // State to hold saved content

  console.log(modalData, "modalData");

  useEffect(() => {
    if (text || jsonText) {
      setJsonText(text);
    }
  }, [text]);

  const switchToTextTab = () => {
    setActiveTab("text");
    setText(jsonText); // Mirror JSON text back to text editor
    setError(""); // Clear any previous errors
  };
  console.log(text, "text");
  console.log(jsonText, "jsonText");

  const switchToJsonTab = () => {
    setActiveTab("json");
    if (!isValidJson(jsonText)) {
      setError("Invalid JSON format. Please correct the text.");
    } else {
      setError(""); // Clear any previous errors
    }
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleJsonChange = (e) => {
    setJsonText(e.target.value);
    if (activeTab === "json" && !isValidJson(e.target.value)) {
      setError("Invalid JSON format. Please correct the text.");
    } else {
      setError("");
    }
  };

  const formatJson = () => {
    try {
      const formatted = JSON.stringify(JSON.parse(jsonText), null, 2);
      setJsonText(formatted);
      setError("");
      setText(formatted); // Mirror formatted JSON to text editor
    } catch (error) {
      setError("Invalid JSON");
    }
  };

  const handleSave = () => {
    if (activeTab === "json" && !isValidJson(jsonText)) {
      setError("Invalid JSON format. Please correct the text.");
      return;
    }

    if (activeTab === "json") {
      try {
        const jsonParsed = JSON.parse(jsonText);
        setError("");
        setSavedContent(jsonText);

        if (modalData === "label") {
          onLabelChange(jsonText);
        } else if (modalData === "dropdownOptions") {
          onJsonOptionChangeHandler(jsonText);
        }

        // Save JSON text
        setModalIsOpen(false);
        // alert("JSON Saved Successfully");
      } catch (error) {
        setError("Invalid JSON");
      }
    } else {
      setSavedContent(text);
      onLabelChange(text);

      onDisabledChange(text);

      setModalIsOpen(false);
    }
  };

  const isValidJson = (text) => {
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      {/* <div className="ModalEditor">
        <button onClick={openModal}>Open Modal</button> */}

      {/* Render saved content */}
      {/* <div className="saved-content">
          {savedContent !== null && (
            <div>
              <h2>Saved Content:</h2>
              {isValidJson(savedContent) ? (
                <pre>{savedContent}</pre> // Render JSON content
              ) : (
                <p>{savedContent}</p> // Render plain text content
              )}
            </div>
          )}
        </div>
      </div> */}

      <Modal
        open={modalIsOpen}
        onClose={closeModalEditor}
        center
        classNames={{
          modal: "EditorModal",
        }}
      >
        <div className="modal-header">
          <div className="inner">
            <div className="left_sec">
              <p>
                {activeTab === "text"
                  ? "Edit text for Label"
                  : "Edit JSON for Label"}
              </p>
            </div>
            <div className="right_sec">
              <button
                className={`tab-button ${activeTab === "text" ? "active" : ""}`}
                onClick={switchToTextTab}
              >
                <Title />
                Text
              </button>
              <button
                className={`tab-button ${activeTab === "json" ? "active" : ""}`}
                onClick={switchToJsonTab}
              >
                <DataObject />
                JSON
              </button>
            </div>
          </div>
        </div>
        {activeTab === "text" && (
          <div className="bg_text">
            <textarea
              className="modal-textarea"
              value={text}
              onChange={handleTextChange}
              rows={20}
              cols={50}
              placeholder="Enter text here. For JSON Objects, switch mode above."
            />
          </div>
        )}
        {activeTab === "json" && (
          <div className="bg_text">
            <button onClick={formatJson}>
              <FormatAlignLeft />
              Format
            </button>
            <textarea
              className="modal-textarea"
              value={jsonText}
              onChange={handleJsonChange}
              rows={20}
              cols={50}
              placeholder="Enter a value in JSON format. use label and value"
            />
          </div>
        )}

        <div className="error-message">{error}</div>

        {/* {activeTab === "text" ? <div className="empty"></div> : null} */}

        <div className="modal-footer">
          <button onClick={() => setModalIsOpen(false)}>Cancel</button>
          <button onClick={handleSave}>Save</button>
        </div>
      </Modal>
    </>
  );
};

export default ModalEditor;
