import React from "react";
import NotesBox from "../docPageNote/NotesBox";

const Workspace = () => {
  return (
    <>
      {" "}
      <div>
        {" "}
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Workspaces</h1>
        </div>
        <div>
          <p className="clone_para">
            Workspaces lets you build apps collaboratively.
          </p>
          <p className="clone_para">
            With Workspaces, you are able to invite team members to collaborate
            on apps together.
          </p>
          <h2 id="setting-up-a-workspace" className="clone_head">
            Setting Up a Workspace
          </h2>
          <p className="clone_para">
            To create a Workspace, click the Create Workspace button under the
            "MY WORKSPACES" menu on the Dashboard As a Workspace owner, you can
            create apps by clicking Create Project and &nbsp;
            <a href="#">Invite </a>&nbsp;collaborators, and change &nbsp;
            <a href="#">Workspace Settings </a>&nbsp; .
          </p>
          <iframe
            className="video_iframe"
            src="https://www.youtube.com/embed/ePAPd9qzGyM"
            title="Server Components in React (Simple Tutorial)"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
          <h2 id="workspace-settings" className="clone_head">
            Workspace Settings
          </h2>
          <h5 id="settings" className="clone_heading">
            Settings
          </h5>
          <p className="clone_para">
            Change the Workspace Name and Icon on this page. Additionally, you
            can permanently delete the entire workspace. After clicking Delete
            Workspace, you will be asked to confirm its deletion.
          </p>
          <iframe
            className="video_iframe"
            src="https://www.youtube.com/embed/ePAPd9qzGyM"
            title="Server Components in React (Simple Tutorial)"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>

          <h5 id="collaborators" className="clone_heading">
            Collaborators
          </h5>
          <p className="clone_para">
            Invite new workspace collaborators and view the list of existing
            collaborators. To remove a collaborator, click the Trash icon and
            click Remove Collaborator.
            <iframe
              className="video_iframe"
              src="https://www.youtube.com/embed/kUs-fH1k-aM"
              title="Do you REALLY need SSR?"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </p>
          <h2 id="inviting-collaborators" className="clone_head">
            Inviting Collaborators
          </h2>
          <p className="clone_para">
            Click the Invite button on the top right of the Collaborators screen
            under the Workspace, you'd like to invite a Collaborator(s) to.
          </p>
          <p className="clone_para">
            Once clicked, a modal will appear for you to enter the email of the
            teammate you'd like to add. You can also remove existing
            collaborators in this modal by clicking the Trash icon next to their
            names.{" "}
          </p>
          <p className="clone_para">
            New collaborators will receive an email invitation to join your
            Workspace. Once they accept the invitation to join, their status
            will change from Invited to Collaborator in your Workspace.{" "}
          </p>
          <NotesBox noteText="Workspace" />
        </div>
      </div>
    </>
  );
};

export default Workspace;
