import React from "react";
import NotesBox from "../../docPageNote/NotesBox";
import InputText from "../../../assets/Image/InputText.png";
import Data_Fetch from "../../../assets/Image/Data_Fetch.png";
import ImageFlash from "../../../assets/Image/ImageFlash.png";
import TextFlash from "../../../assets/Image/TextFlash.png";
import Flashlist from "../../../assets/Image/Flashlist.png";
import FlashFetch from "../../../assets/Image/FlashFetch.png";
import BlockComponenet from "../../../assets/Image/BlockComponenet.png";
export const FetchingData = () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Fetching Data</h1>
      </div>

      <p className="clone_para">
        Fetching data is an essential function in most apps, and Draftbit
        provides a couple of ways for you to pull in data from REST APIs and use
        that data on your Screens.
      </p>

      <h2 id="fetch-component" className="clone_head">
        Fetch Component
      </h2>
      <p className="clone_para">
        First, make sure you've set up at least one REST API Service and an
        Endpoint that returns some data. The Fetch component is a component that
        you add to your Screen in the components tree. Once added to the Screen,
        you can nest other components inside of it, and any child components you
        add that are data-enabled—such as a List—will have access to the data
        returned by the Fetch component.
      </p>

      <p className="clone_para">
        The Fetch component lets you select one of your configured API services
        and endpoints. There are also several Triggers available on the Fetch
        component that allow you to take Actions based on different conditions,
        such as the status code of the response (e.g., 404).
      </p>
      <p className="clone_para">
        Check out the Fetch component documentation for more details.
      </p>

      <h2 id="api-request-action" className="clone_head">
        API Request Action
      </h2>
      <p className="clone_para">
        First, make sure you've set up at least one REST API Service and an
        Endpoint that returns some data. The API Request Action lets you make an
        API request in reaction to Triggers. You can use the API Request Action
        to also make an API request to fetch data. This is often done using the
        Screen's On Screen Focus Trigger or with a Button's On Pressed Trigger,
        where you can then pass the response data to a Variable.
      </p>
      <p className="clone_para">
        Check out the Action Editor and Actions documentation for more details.
      </p>

      <h2 id="data-enabled-components" className="clone_head">
        Data-Enabled Components
      </h2>
      <p className="clone_para">
        Many components in Draftbit are data-enabled, which means you can pass
        data into them for specific purposes. For example, the Text component
        lets you pass data to display as the rendered text, a List component
        accepts an array of data to render its items, and you can pass a URL to
        the Image component.
      </p>

      <p className="clone_para">
        A component's data props are set under the Data tab in the right panel
        of the Builder. Props that accept data will have a dropdown where you
        can select the data to pass. Refer to a specific component's
        documentation for more details about its data props.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={InputText} alt="" width="50%" />
      </span>
      <h3 id="fetch-list-example" className="clone_heading">
        Configure an Endpoint to Fetch a List of Items
      </h3>
      <p className="clone_para">
        For an example, consider a REST API endpoint that returns the following
        array of items:
      </p>
      <pre>
        <code>
          {`{
    "records": [
        {
            "id": "rec1VCM4FYcnc0NDz",
            "fields": {
                "item": "Notebook"
            },
            "createdTime": "2021-11-12T07:44:52.000Z"
        },
        {
            "id": "recBusEqFIfm3JzKY",
            "fields": {
                "item": "Apples"
            },
            "createdTime": "2021-11-12T07:44:52.000Z"
        },
        {
            "id": "recz5WRdCMYyedKPt",
            "fields": {
                "item": "Bananas"
            },
            "createdTime": "2021-11-12T07:44:52.000Z"
        }
    ]
}`}
        </code>
      </pre>
      <p className="clone_para">
        Once you've created a Service in the API & Cloud services modal, you'll
        be prompted to add a new endpoint. Let's consider the above endpoint
        example to fetch a list of items from an Airtable base. You will add a
        new endpoint to fetch data using a GET HTTP method by following these
        steps:
      </p>
      <ol>
        <li>
          Click <strong>Add endpoint</strong>.
        </li>
        <li>
          In Step 1: enter the Name for the endpoint. Make sure the Method
          selected is GET.
        </li>
        <li>
          Set the Role to <strong>Get Many</strong> since the endpoint is
          fetching a list of items, not just a single item. Set the Object Type
          to <strong>todos</strong>.
        </li>
        <li>
          In Step 2: add the base name path and/or any query parameters to the
          API's base URL. Currently, the endpoint has no query parameters or
          additional endpoint-specific paths, so just enter <code>/</code>.
        </li>
        <li>
          In Step 4: click the <strong>Test</strong> button next to the Endpoint
          input to verify the response coming from the API service.
        </li>
        <li>
          The last step is to save the endpoint. Click the <strong>Save</strong>{" "}
          button.
        </li>
      </ol>

      <h3 id="display-list" className="clone_heading">
        Display a List of Data with Fetch Component
      </h3>
      <p className="clone_para">
        After adding the endpoint to fetch a list of items, the next step is to
        display it on an app screen. The Fetch component is used to get data
        from a REST API service directly into your app using the HTTP GET
        request.
      </p>
      <p className="clone_para">
        Typically, when a Fetch component is used to display a list of data, the
        Components tree for a screen is generally structured as shown below:
      </p>
      <pre>
        <span style={{ display: "flex", justifyContent: "center" }}>
          <img src={BlockComponenet} alt="" width="50%" />
        </span>
      </pre>
      <p className="clone_para">
        To populate the list of components with the external data, a series of
        steps are required:
      </p>
      <ol>
        <li>Select the Fetch component from the Components tree.</li>
        <li>Navigate to the Fetch component’s Data tab.</li>
        <li>Select the REST API service name from the Service dropdown.</li>
        <li>Select the endpoint from the Endpoint dropdown.</li>
      </ol>

      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Data_Fetch} alt="" width="100%" />
      </span>
      <p className="clone_para">
        The next step is to configure the List component nested inside the Fetch
        component to map the list of items.
      </p>
      <ol>
        <li>Select the List component in the Components tree.</li>
        <li>Navigate to the List component’s Data tab.</li>
        <li>Select the value from the dropdown for the Data property.</li>
        <li>Select the endpoint from the Endpoint dropdown.</li>
      </ol>
      {/* 
      <h3 id="display-item-name" className="clone_heading">
        Display the List Item Name
      </h3> */}
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Flashlist} alt="" width="100%" />
      </span>

      <p className="clone_para">
        The next step is to display the list item name. Map the field of the
        item name to a Text component.
      </p>
      <ol>
        <li>
          Select the data-enabled component (for example, in the Components tree
          below, Text is a data-enabled component).
        </li>
        <li>Go to the component’s Data Tab.</li>
        <li>
          Add a <code>{"variable"}</code> in the Input Text.
        </li>
        <li>
          Then, map the value for <code>{"variable"}</code> from the dropdown
          under Variables.
        </li>
      </ol>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={TextFlash} alt="" width="100%" />
      </span>
      <p className="clone_para">
        You can repeat these steps for any other data-enabled component such as
        Image Background, Image components, etc.
      </p>

      <h3 id="fetch-single-item" className="clone_heading">
        Configure an Endpoint to Fetch a Single Item
      </h3>
      <p className="clone_para">
        For an example, consider an endpoint that requires an id as the query
        parameter to fetch a record of a single item. When provided a value for
        id, it returns the following:
      </p>
      <pre>
        {/* <code>
          {`{
    "id": "rec1VCM4FYcnc0NDz",
    "fields": {
        "item": "Notebook"
    },
    "createdTime": "2021-11-12T07:44:52.000Z"
}`}
        </code> */}
      </pre>
      <p className="clone_para">
        Let's consider the above endpoint example to fetch the single item
        record from an Airtable base. You will add a new endpoint to fetch data
        using a GET HTTP method by following these steps:
      </p>
      <ol>
        <li>
          Click <strong>Add endpoint</strong>.
        </li>
        <li>
          In Step 1: enter the Name for the endpoint. Make sure the Method
          selected is GET.
        </li>
        <li>
          Set the Role to <strong>Get One</strong> since the endpoint is
          configured to get a single item. Set the Object Type to{" "}
          <strong>todos</strong>.
        </li>
        <li>
          In Step 2: add the base name path and/or any query parameters to the
          API's base URL. Currently, the endpoint uses a dynamic variable{" "}
          <code>/{"id"}</code> to fetch the record based on the value of the id
          provided.
        </li>
        <li>Provide an id of the existing record under Path Test Values.</li>
        <li>
          In Step 4: click the <strong>Test</strong> button next to the Endpoint
          input to verify the response coming from the API service.
        </li>
        <li>
          The last step is to save the endpoint. Click the <strong>Save</strong>{" "}
          button.
        </li>
      </ol>

      <h3 id="display-single-data" className="clone_heading">
        Display Single Data Object Using Fetch Component
      </h3>
      <p className="clone_para">
        When a Fetch component is used to render a single data object, the
        Components tree for a screen is generally structured as shown below:
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={FlashFetch} alt="" width="50%" />
      </span>

      {/* <p className="clone_para">
        To populate the Components tree with the external data, a series of
        steps are required:
      </p>
      <ol>
        <li>Select the Fetch component from the Components tree.</li>
        <li>
          Navigate to the Fetch component’s Data tab (third tab on the
          right-hand side Properties Panel).
        </li>
        <li>Select the REST API service name from the Service dropdown.</li>
        <li>Select the endpoint from the Endpoint dropdown.</li>
        <li>
          Under Configuration, from the dropdown, select the Navigation
          Parameter (ideally, coming from a List screen) such as Navigation id.
        </li>
      </ol>

      <h3 id="map-item-name" className="clone_heading">
        Map the Field of the Item Name
      </h3>
      <p className="clone_para">
        The next step is to display the list item name. Map the field of the
        item name to a Text component.
      </p>
      <ol>
        <li>
          Select the data-enabled component (for example, in the Components tree
          below, Text is a data-enabled component).
        </li>
        <li>Go to the component’s Data Tab.</li>
        <li>
          Add a <code>{"variable"}</code> in the Input Text. In the example
          below, the item's id and name are displayed so there are two separate
          Text components, each with its own variable.
        </li>
        <li>
          Then, map the value for <code>{"variable"}</code> from the dropdown
          under Variables.
        </li>
      </ol>
      <p className="clone_para">
        Here is how the details of an item are displayed when navigating from
        List to an item's Details screen:
      </p>

      <h2 id="additional-resources" className="clone_head">
        Additional Resources
      </h2>
      <p className="clone_para">
        Learn more about Role and Object Type when configuring an endpoint and
        why they are necessary for caching.
      </p> */}
    </>
  );
};
