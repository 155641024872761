import React from "react";

const AspectRatio = () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Aspect Ratio Component</h1>{" "}
      </div>

      <p className="clone_para">
        The Aspect Ratio component constrains the dimensions of its child(ren)
        to the selected aspect ratio.
      </p>

      <h2 id="how-to-use" className="clone_head">
        How to Use
      </h2>
      <p className="clone_para">
        The Aspect Ratio component is similar to a standard View. You can nest a
        component, such as an Image, inside Aspect Ratio and have the image
        constrained by the ratio that you select.
      </p>
      <p className="clone_para">
        For example, selecting a ratio of 1:1 would create a square where the
        length and height are proportionally equal. A ratio of 3:1 means the
        length is 3 times the height. A ratio of 4:3 would mean the height is
        3/4 of the width.
      </p>

      <h2 id="style-properties" className="clone_head">
        Style Properties
      </h2>
      <p className="clone_para">
        Style properties allow you to customize the look and feel of the
        component. Combinations of styles applied to components can be saved as
        Stylesheets to easily reuse styles throughout your app. Styles can also
        be set dynamically using Variables. To learn more about all the
        different styling properties and how they work, take a look at Intro to
        Styling.
      </p>

      <h2 id="style-properties" className="clone_head">
        Properties
      </h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Aspect Ratio</td>
            <td>
              Aspect ratio of the view. Defines the ratio between the width and
              height of the component.
            </td>
          </tr>
        </tbody>
      </table>

      <h2 id="configuration-properties" className="clone_head">
        Configuration Properties
      </h2>
      <table className="table table-striped">
        <tbody>
          <tr>
            <td>Component name</td>

            <td>
              The name of the component as it appears in the Screen's component
              tree.
            </td>
          </tr>
        </tbody>
      </table>

      <h2 id="data-properties" className="clone_head">
        Data Properties
      </h2>
      <h5 id="data-properties" className="clone_heading">
        Conditional Display{" "}
      </h5>
      <p className="clone_para">
        You can conditionally display a component based on a given condition.
        Learn more about conditionally displaying components in the Conditional
        Display doc.
      </p>
    </>
  );
};

export default AspectRatio;
