import React from "react";

function SvgComponent({ showSvg, onClick, onClose }) {
  return (
    <div style={{ cursor: "pointer" }}>
      {" "}
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fal"
        data-icon="arrows-from-dotted-line"
        class="svg-inline--fa fa-arrows-from-dotted-line fa-xs "
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        width="10px"
        height="10px"
        onClick={onClick}
      >
        <path
          fill="black"
          d="M235.3 4.7c-3-3-7.1-4.7-11.3-4.7s-8.3 1.7-11.3 4.7l-80 80c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L208 54.6V176c0 8.8 7.2 16 16 16s16-7.2 16-16V54.6l52.7 52.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-80-80zM8 256a24 24 0 1 0 48 0A24 24 0 1 0 8 256zm384 0a24 24 0 1 0 48 0 24 24 0 1 0 -48 0zm-72-24a24 24 0 1 0 0 48 24 24 0 1 0 0-48zM200 256a24 24 0 1 0 48 0 24 24 0 1 0 -48 0zm-72-24a24 24 0 1 0 0 48 24 24 0 1 0 0-48zm96 280c4.2 0 8.3-1.7 11.3-4.7l80-80c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L240 457.4 240 336c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 121.4-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l80 80c3 3 7.1 4.7 11.3 4.7z"
        ></path>
      </svg>
    </div>
  );
}

export default SvgComponent;
