import React from "react";
import MarginsPadding from "../../../assets/Image/MarginsPadding.png";

const MarginPadding = () => {
  return (
    <>
      {/* Header for the page */}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Margins & Padding</h1>
      </div>

      {/* Introduction to Margins & Padding */}
      <h2 id="margins-padding" className="clone_head">
        Margins & Padding
      </h2>
      <p className="clone_para">
        Adding a margin creates extra space around an element while adding
        padding creates extra space within an element.
      </p>
      <p className="clone_para">
        The value can be a number (pt: pixel ratio) or a percentage dimension
        (%).
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={MarginsPadding} alt="" width="50%" />
      </span>

      {/* Box Model Explanation */}
      <p className="clone_para">This input is based on the Box Model.</p>
      <p className="clone_para">
        You can adjust the Margin by typing in a value in the outer ring and you
        can adjust the Padding by typing in a value in the inner ring.
      </p>

      {/* Warning about negative margins */}
      {/* <p className="clone_para" style={{ color: "red" }}>
        ⚠️ Negative margins aren't supported on Android.
      </p> */}

      {/* Additional Resources Section */}
      <h2 id="additional-resources" className="clone_head">
        Additional Resources
      </h2>
      <p className="clone_para">
        For more information, refer to these resources:
      </p>

      <li>
        <a
          href="https://reactnative.dev/docs/layout-props#margin"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pixel Ratio in React Native
        </a>
      </li>
      <li>
        <a
          href="https://reactnative.dev/docs/layout-props#padding"
          target="_blank"
          rel="noopener noreferrer"
        >
          Percentage Dimensions in React Native
        </a>
      </li>
    </>
  );
};

export default MarginPadding;
