import React from "react";
import FigmaCloneDoc from "./pages/FigmaCloneDoc";
import DashboardDoc from "./pages/DashboardDoc";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import Workspace from "./pages/Workspace";
import CommunityResources from "./pages/CommunityResources";
import Facq from "./pages/Facq";
import IntroToComponent from "./pages/IntroToComponent";
import BasicPage from "./pages/basic/BasicPage";
import SubScreen from "./pages/basic/SubScreen";
import { IconPage } from "./pages/basic/IconPage";
import { TextPage } from "./pages/basic/TextPage";
import ViewPage from "./pages/basic/ViewPage";
import ButtonPage from "./pages/buttons/ButtonPage";
import IconButtonPage from "./pages/buttons/IconButtonPage";
import PressablePage from "./pages/buttons/PressablePage";
import ButtonsList from "./pages/buttons/ButtonsList";
import AspectRatio from "./pages/flexlayout/AspectRatio";
import FlexList from "./pages/flexlayout/FlexList";
import CenterPage from "./pages/flexlayout/CenterPage";
import CirclePage from "./pages/flexlayout/CirclePage";
import SquarePage from "./pages/flexlayout/SqurePage";
import BlockPage from "./pages/BlockPage";
import StylingList from "./pages/styling/Stylinglist";
import StyleSheet from "./pages/styling/StyleSheet";
import SizePage from "./pages/styling/SizePage";
import BorderBackground from "./pages/styling/BorderBackground";
import MarginPadding from "./pages/styling/MarginPadding";
import TypographyPage from "./pages/styling/TypographyPage";
import FlexLayout from "./pages/styling/FlexLayout";
import { ConditionDisplayPage } from "./pages/styling/ConditionDisplayPage";
import { DynamicStyling } from "./pages/styling/DynamicStyling";
import RestApiService from "./pages/ConnectingToData/RestApiService";
import { RestApiEndpoints } from "./pages/ConnectingToData/RestApiEndpoints";
import { FetchingData } from "./pages/WorkingWithData/FetchingData";
import { SubmittingData } from "./pages/WorkingWithData/SubmittingData";
import ExampleData from "./pages/DataIntergations/ExampleData";
import { RestAPIIntegrations } from "./pages/DataIntergations/RestAPIIntegrations";
import { IntroToPublish } from "./pages/publish/IntroToPublish";

const Content = ({
  tabs,
  navigateBackward,
  navigateForward,
  listItems,
  docId,
}) => {
  const currentIndex = listItems?.findIndex((item) => item?.url === docId);

  const nextItem =
    currentIndex < listItems?.length - 1 ? listItems[currentIndex + 1] : null;
  const prevItem = currentIndex > 0 ? listItems[currentIndex - 1] : null;
  return (
    <div>
      <main className="content">
        {tabs === "welcome-to-figma-clone" && <FigmaCloneDoc />}
        {tabs === "dashboard" && <DashboardDoc />}
        {tabs === "workspaces" && <Workspace />}
        {tabs === "community-resources" && <CommunityResources />}
        {tabs === "faqs" && <Facq />}
        {tabs === "intro-to-components" && <IntroToComponent />}
        {tabs === "basic" && <BasicPage />}
        {tabs === "screen" && <SubScreen />}
        {tabs === "icon" && <IconPage />}
        {tabs === "text" && <TextPage />}
        {tabs === "view" && <ViewPage />}
        {tabs === "buttons" && <ButtonsList />}
        {tabs === "button" && <ButtonPage />}
        {tabs === "icon-button" && <IconButtonPage />}
        {tabs === "pressable" && <PressablePage />}
        {tabs === "flex-layout" && <FlexList />}
        {tabs === "aspect-ratio" && <AspectRatio />}
        {tabs === "center" && <CenterPage />}
        {tabs === "circle" && <CirclePage />}
        {tabs === "square" && <SquarePage />}
        {tabs === "blocks" && <BlockPage />}
        {tabs === "intro-to-styling" && <StylingList />}
        {tabs === "stylesheets" && <StyleSheet />}
        {tabs === "size-position" && <SizePage />}
        {tabs === "borders-background-effect" && <BorderBackground />}
        {tabs === "margins-padding" && <MarginPadding />}
        {tabs === "typography" && <TypographyPage />}
        {tabs === "flex-layout-style" && <FlexLayout />}
        {tabs === "conditional-display" && <ConditionDisplayPage />}
        {tabs === "dynamic-styling" && <DynamicStyling />}
        {tabs === "rest-api-services" && <RestApiService />}
        {tabs === "rest-api-endpoints" && <RestApiEndpoints />}
        {tabs === "fetching-data" && <FetchingData />}
        {tabs === "submitting-data" && <SubmittingData />}
        {tabs === "rest-api-integrations" && <RestAPIIntegrations />}
        {tabs === "example-data" && <ExampleData />}
        {tabs === "intro-to-publishing" && <IntroToPublish />}
        {/* {tabs === "intro-to-publishing" && <WelcomeFigmaClone />} */}
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"></div>
        <div className="button_group ">
          {prevItem && (
            <div className="button_backward" onClick={navigateBackward}>
              <WestIcon /> {prevItem.name}
            </div>
          )}
          {nextItem && (
            <div className="button_forward" onClick={navigateForward}>
              {nextItem.name} <EastIcon />
            </div>
          )}
        </div>{" "}
      </main>
      {/* <div className="button_group">
        <div className="button_forward">
          <button>starting new project</button>
        </div>
        <div className="button_backward">
          <button>Workspaces</button>
        </div>
      </div> */}
    </div>
  );
};

export default Content;
