import React from "react";
import ApiService from "../../../assets/Image/ApiService.png";
import ApiServiceBasic from "../../../assets/Image/ApiServiceBasic.png";
import ApiServiceCreate from "../../../assets/Image/ApiServiceCreate.png";
import NotesBox from "../../docPageNote/NotesBox";

const RestApiService = () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">REST API Services</h1>
      </div>
      <p className="clone_para">
        REST APIs allow you to connect your app to a server or backend using
        standard HTTPS.
      </p>
      <p className="clone_para">
        You can manage all your API services in the API & Cloud Services modal
        accessible from the option in the left menu bar of the Builder.
      </p>
      <p className="clone_para">
        Apart from adding a custom REST API service, Draftbit supports various
        services such as Xano, Supabase, Backendless, Airtable, and more. Just
        about any REST-like API that sends and receives JSON is compatible with
        Draftbit.
      </p>
      <p className="clone_para">
        Once you have added an API service, you can then create Endpoints for
        that connection to use with API Request Actions and Fetch components
        throughout your app.
      </p>
      <h2 id="adding-service" className="clone_head">
        Adding a Service
      </h2>
      <p className="clone_para">
        Click on one of the available options on the left to begin configuring a
        new service.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={ApiService} alt="" width="80%" />
      </span>
      <h2 id="importing-service" className="clone_head">
        Importing from Another App
      </h2>
      <p className="clone_para">
        You can also copy an existing configured Service from another app using
        the <strong>Copy from another app</strong> button in the left panel.
        This will import the Service and all of its associated REST API
        Endpoints.
      </p>
      <h2 id="configuring-service" className="clone_head">
        Configuring a Service
      </h2>
      <p className="clone_para">
        Use the configuration options to set up your new API service.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={ApiServiceBasic} alt="" width="80%" />
      </span>
      <h3 id="api-basics" className="clone_heading">
        API Basics
      </h3>
      <p className="clone_para">
        Here you can set the Name and Base URLs for your new API service. In
        this example, <code>https://api.example.com</code> is the base URL for
        the API and <code>/posts/123</code> is the endpoint path.
      </p>
      <p className="clone_para">
        If you were setting up a new connection to the above example API, our
        Base URL would be <code>https://api.example.com</code>.
      </p>
      <p className="clone_para">
        When you create Endpoints for this new service, you can use the specific
        path to get a post by its id using <code>/posts/id</code>. You'll be
        able to pass values to any variables that you create on the Service's
        Endpoints when using an API Request Action or Fetch component in your
        app.
      </p>
      <NotesBox noteText="HTTPPage" /> <NotesBox noteText="LocalhostPage" />{" "}
      <p className="clone_para">
        API services need to be accessible over the internet. To connect
        Draftbit with your localhost, you'll need a secure tunnel that supports
        HTTPS. Expose is a handy service for this.
      </p>
      <h5 id="service-properties" className="clone_heading">
        Service Properties
      </h5>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
            <th>Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Name</td>
            <td>A name for this connection</td>
            <td>Xano API</td>
          </tr>
          <tr>
            <td>Dev URL</td>
            <td>
              The base URL that will be used while developing in the Builder and
              when publishing using the Development Environment
            </td>
            <td>https://xxxx-yyyy-zzzz.a1.xano.io/api :abc123</td>
          </tr>
          <tr>
            <td>Prod URL</td>
            <td>
              The base URL that will be used when publishing using the
              Production Environment
            </td>
            <td>https://xxxx-yyyy-zzzz.a1.xano.io/api:abc123</td>
          </tr>
          <tr>
            <td>Configuration Mode</td>
            <td>
              You can either create your endpoints manually, or import from an
              OpenAPI schema. See OpenAPI Spec section below for more.
            </td>
            <td>Manual or OpenAPI</td>
          </tr>
        </tbody>
      </table>
      <h3 id="openapi-schema" className="clone_heading">
        OpenAPI Schema
      </h3>
      <p className="clone_para">
        This section is enabled when you have chosen OpenAPI for the service's
        Configuration Mode. The OpenAPI Specification allows an API to provide a
        description of itself encoded in a JSON document. If your API provides
        an OpenAPI schema, Draftbit can use that to automatically create the
        Endpoints for the API service, and keep them updated, so you don't have
        to manage them manually.
      </p>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <h5 className="clone_heading">Schema Properties</h5>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Schema URL</td>
            <td>
              A URL which can be accessed with a GET request, which returns your
              OpenAPI schema.
            </td>
          </tr>
          <tr>
            <td>Refresh Interval</td>
            <td>
              You may configure your schema to be automatically refreshed at
              regular intervals. You can always re-fetch your schema manually
              using the Fetch Schema button.
            </td>
          </tr>
          <tr>
            <td>Headers</td>
            <td>
              Include any headers needed to fetch your schema. For example, if
              your schema endpoint requires authentication you might need an
              Authorization header.
            </td>
          </tr>
        </tbody>
      </table>
      <NotesBox noteText="ImportantPage" />{" "}
      <p className="clone_para">
        When you choose OpenAPI as the Configuration Mode for your service, the
        Endpoints which are automatically created will only allow certain
        properties to be changed - such as Test/Default values. If you need to
        make changes to these Endpoints, it's recommended to either update your
        OpenAPI schema and then run Fetch Schema to get the latest changes
        synced to Draftbit OR switch the Endpoint's Configuration Mode to
        Manual.
      </p>
      <h3 className="clone_heading">Headers</h3>
      <p className="clone_para">
        If you need to set custom HTTP headers for ALL Endpoints that will be
        associated with this Service, you can set them here. If you only need to
        set HTTP headers for specific Endpoints, you can set those up when
        creating the Endpoints.
      </p>
      <h5 className="clone_heading">Header Properties</h5>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
            <th>Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Key</td>
            <td>The HTTP header name</td>
            <td>X-APP-ID</td>
          </tr>
          <tr>
            <td>Value</td>
            <td>The value associated with the header</td>
            <td>x1y2z3</td>
          </tr>
        </tbody>
      </table>
      <NotesBox noteText="JsonDataPage" />{" "}
      <p className="clone_para">
        By default, Draftbit sets the Accept and Content-Type headers to
        application/json since Draftbit only supports JSON data.
      </p>
      <h3 className="clone_heading">Editing a Service</h3>
      <p className="clone_para">
        To edit an API Service created previously, click on the service from
        your list of services.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={ApiServiceCreate} alt="" width="80%" />
      </span>
      <h3 className="clone_heading">Duplicating a Service</h3>
      <p className="clone_para">
        To duplicate a Service, click the overflow menu (...) for the service
        you want to duplicate and then click Duplicate.
      </p>
      <h3 className="clone_heading">Deleting a Service</h3>
      <p className="clone_para">
        To delete a Service, click the overflow menu (...) for the service you
        want to delete and then click Delete. This action is permanent, and when
        you delete an API Service, all associated Endpoints will also be
        deleted, and any Fetch components and API Request Actions that reference
        the Endpoints will also be reset. This can't be undone!
      </p>
      <NotesBox noteText="Permanent" />{" "}
      <h4 className="clone_heading">Important Note</h4>
      <p className="clone_para">
        Ensure that you have backed up any necessary configurations or data
        before proceeding with deletion, as this action is irreversible.
      </p>
    </>
  );
};

export default RestApiService;
