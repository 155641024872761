import React from "react";
import Border from "../../../assets/Image/Border.png";
import Background from "../../../assets/Image/Background.png";

const BorderBackground = () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Borders, Background, Effects</h1>
      </div>

      <h2 id="borders" className="clone_head">
        Borders
      </h2>
      <p className="clone_para">
        Set the border radius of a component, along with the width and style of
        the border surrounding a component.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Border} alt="" width="50%" />
      </span>
      <h5 id="component-specific-styles" className="clone_heading">
        Property Descriptions
      </h5>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Radius</td>
            <td>
              Determines how the edges of the border will be rounded. You can
              set the radius for all edges using <strong>All</strong> or set it
              for the individual border using <strong>Top</strong> or{" "}
              <strong>Bottom</strong> properties.
            </td>
          </tr>
          <tr>
            <td>Size</td>
            <td>The width of the border on each side of the element.</td>
          </tr>
          <tr>
            <td>Color</td>
            <td>
              The color of the border. You can select it from a color picker.
            </td>
          </tr>
          <tr>
            <td>Style</td>
            <td>Select a solid, dotted, or dashed line as the border style.</td>
          </tr>
        </tbody>
      </table>

      <h2 id="background" className="clone_head">
        Background
      </h2>
      <p className="clone_para">
        Specify a background color. The value of the color is selected from the
        color picker. Values available in the color picker can be modified or
        added through Themes & Colors.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Background} alt="" width="50%" />
      </span>
      {/* 
      <h2 id="effects" className="clone_head">
        Effects
      </h2>
      <h5 id="component-specific-styles" className="clone_heading">
        Property Descriptions
      </h5>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Opacity</td>
            <td>
              Increase or decrease an element's transparency, allowing the
              background or other elements behind it to show through.
            </td>
          </tr>
        </tbody>
      </table> */}
    </>
  );
};

export default BorderBackground;
