import React from "react";
import NotesBox from "../../docPageNote/NotesBox";
import Add_Rest from "../../../assets/Image/Add_Rest.png";
import BasicCom from "../../../assets/Image/BasicCom.png";
import Data_Fetch from "../../../assets/Image/Data_Fetch.png";
import AddComponenet from "../../../assets/Image/AddComponenet.png";

const ExampleData = () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Example Data</h1>
      </div>
      <h2 className="clone_head">What is Example Data Service?</h2>
      <p className="clone_para">
        Example Data Service is a REST API service provided by Figma Clone to
        start building a data-driven app without having to create a backend. It
        uses real (or real-looking) data that can help you make better design
        decisions along the way. Data is gleaned from a number of open or
        creative commons sources. There are different resources of data to build
        a prototype or a Minimum Viable Product (MVP) using any of the
        resources: Books, Articles, Products, Orders, Podcasts, Product Reviews,
        People, etc.
      </p>
      <h2 className="clone_head">Setup Steps in Example Data Service</h2>
      <p className="clone_para">
        You do not need to create an account to use Example Data Service. It is
        open and available to be used as a REST API from any REST client or tool
        like Figma Clone.
      </p>
      <h3 className="clone_heading">Setup Steps in Figma Clone</h3>
      <ol>
        <li>
          Click the <strong>Data</strong> tab from the left menu.
        </li>
        <li>
          In the API & Cloud Services modal, click the <strong>Rest API</strong>{" "}
          button.
        </li>
        <li>Step 1: under Name, enter the name of the REST API service.</li>
        <li>
          Then, add the Dev URL:{" "}
          <code>https://example-data.Figma Clone.com/base-name</code>.
        </li>
        <li>
          For Example Data Service, you do not have to modify configuration
          under Step 2 and Step 3.
        </li>
        <li>
          Click <strong>Save</strong>.
        </li>
      </ol>
      {/* 
      <h4 className="clone_heading">📘 Dev & Prod URLs</h4> */}
      <NotesBox noteText="DevProd" />
      <p className="clone_para">
        The Base URL parameter has been updated to give you the ability to set
        separate base URLs for Development and Production. When you publish your
        app, you can set which base URL to use, Dev or Prod. Read more about
        Environments.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Add_Rest} alt="" width="100%" />
      </span>
      <h2 className="clone_head">Using Example Data API with Figma Clone</h2>
      <h3 className="clone_heading">GET Request to Fetch All Records</h3>
      <p className="clone_para">
        In this section, let's populate a Fetch component with all the data from
        the API Service and then display it in a List component.
      </p>
      <p className="clone_para">
        For reference, here is how the Components tree looks like for this
        screen:
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={AddComponenet} alt="" width="50%" />
      </span>
      <pre>
        {/* <code>
          {`Fetch Component
  └── List Component
      └── Text Component`}
        </code> */}
      </pre>
      <p className="clone_para">
        The next step is to create an endpoint. Let's fetch the data using a GET
        HTTP request. Open the API and Cloud Services modal from the top menu
        bar, select the Example Data service, and then:
      </p>
      <ol>
        <li>
          Click <strong>Add endpoint</strong>.
        </li>
        <li>
          In Step 1: enter the Name for the endpoint and set the Method to GET.
        </li>
        <li>
          Set the Role to <strong>Get Many</strong> as the endpoint is fetching
          a list of items. Set the Object Type to <strong>todos</strong> for
          this example.
        </li>
        <li>
          In Step 2: optionally, add the base name path: <code>/base-name</code>
          . Example Data Service has different resources that can be used as a
          base name. You can also add the query parameters here such as{" "}
          <code>limit={"value"}</code>.
        </li>
        <li>
          For this example, we're using <code>/?_limit={"limit"}</code> endpoint
          with the value of limit being 10.
        </li>
        <li>
          In Step 4: click the <strong>Test</strong> button next to the Endpoint
          input to verify the response coming from the API service.
        </li>
        <li>
          Click <strong>Save</strong>.
        </li>
      </ol>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <h3 className="clone_heading">
        On App Screen:
        <li>
          Select the Fetch component in the Components tree and go to the Data
          tab from the Properties Panel.
        </li>
        <li>For Service, select the name of the API Service.</li>
        <li>
          For Endpoint, select the endpoint you want to fetch the data from.
        </li>
        <li>
          In Configuration, add a value for the query parameter defined during
          the configuration of the endpoint.
        </li>
        <li>
          Select the List component in the Components tree and go to the Data
          tab from the Properties Panel. In Data, select{" "}
          <strong>Top-level response</strong> from the dropdown menu.
        </li>
        <li>
          Then, select the Text component in the Components tree and then go to
          the Data tab from the Properties Panel.
        </li>
        <li>
          Add a <code>{"propertyName"}</code> value (inside the curly braces) to
          represent a data field from the API service. For example, we add{" "}
          <code>{"propertyName"}</code> to display the name of properties.
        </li>
      </h3>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Data_Fetch} alt="" width="100%" />
      </span>
      <h3 className="clone_heading">GET Request to Fetch a Single Record</h3>
      <p className="clone_para">
        In this section, let's populate a Fetch component with a single record
        from Example Data. To fetch a single post, you'll have to specify a
        record id that is coming from the navigation parameters.
      </p>
      <p className="clone_para">
        Data is fetched using a GET HTTP request. Open the Data modal from the
        left menu, select the Example Data service, and then:
      </p>
      <ol>
        <li>
          Click <strong>Add endpoint</strong>.
        </li>
        <li>
          In Step 1: enter the Name for the endpoint and set the Method to GET.
        </li>
        <li>
          Set the Role to <strong>Get One</strong> as the endpoint is fetching a
          single item. Set the Object Type to <strong>todos</strong> for this
          example.
        </li>
        <li>
          In Step 2: add the <code>{"id"}</code> variable. Then, add a Test
          value for the <code>{"id"}</code>.
        </li>
        <li>
          In Step 4: click the <strong>Test</strong> button next to the Endpoint
          input to verify the response coming from the API service.
        </li>
        <li>
          Click <strong>Save</strong>.
        </li>
      </ol>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>{" "}
      <h3 className="clone_heading">On App Screen:</h3>
      <ol>
        <li>
          Select the Fetch component in the Components tree and go to the Data
          tab from the Properties Panel.
        </li>
        <li>For Service, select the name of the API Service.</li>
        <li>
          For Endpoint, select the endpoint you want to fetch the data from.
        </li>
        <li>
          Set the value for the id in the Configuration URL Structure section.
        </li>
      </ol>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Data_Fetch} alt="" width="100%" />
      </span>
      <h3 className="clone_heading">POST Request to Submit New Data</h3>
      <p className="clone_para">
        Submitting new Data from the Figma Clone app to a REST API requires the
        request to be sent using HTTP POST method.
      </p>
      <p className="clone_para">Your component needs to have:</p>
      <ul>
        <li>Accepts user input</li>
        <li>Has a Data Source prop</li>
      </ul>
      <p className="clone_para">
        You can use any component that contains a Data Source prop to log user
        input and submit data. This list of components currently includes:
      </p>
      <ul>
        <li>Text Input</li>
        <li>Text Area</li>
        <li>Styled Text Field</li>
        <li>Checkbox</li>
        <li>Slider</li>
        <li>Radio Button Group</li>
        <li>Radio Button</li>
      </ul>
      <p className="clone_para">
        Once you have the necessary component(s) on your screen, you'll need to
        create a new endpoint in your service:
      </p>
      <ol>
        <li>
          Click <strong>Add endpoint</strong>.
        </li>
        <li>
          In Step 1: enter a name for the endpoint and set the Method to POST.
        </li>
        <li>
          Set the Role to <strong>Create</strong> as the endpoint is used to
          create a new record. Set the Object Type to <strong>todos</strong> for
          this example.
        </li>
        <li>
          In Step 3: add a valid Body structure to submit a POST request. Add
          one or many <code>{"variable"}</code> for test values. Click{" "}
          <strong>Body Preview</strong> to validate the structure of the Body in
          the request.
        </li>
        <li>
          Below is a general breakdown of what the request will look like.
          You're able to add multiple fields to a request, just make sure you
          include a comma after each field.
        </li>
      </ol>
      <ol start={6}>
        <li>
          In Step 5: click the <strong>Test</strong> button next to the Endpoint
          input to verify the response coming from the API service.
        </li>
        <li>
          Click <strong>Save</strong>.
        </li>
      </ol>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>{" "}
      <h3 className="clone_heading">To Submit Data </h3>
      <p className="clone_para">
        You can use a Touchable/Button component to trigger the action API
        Request.
      </p>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <p className="clone_para">
        The <code>textInputValue</code> passed in the above example is the value
        of the Data Source property on the Text Input component:
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={BasicCom} alt="" width="50%" />
      </span>
      <h3 className="clone_heading">Update Data</h3>
      <p className="clone_para">
        The PATCH request is used to update the single entry in the API Service
        record. It accepts similar input components as mentioned in the POST
        request and the API Request is triggered using a Touchable/Button
        component.
      </p>
      <p className="clone_para">Your component needs to have:</p>
      <ul>
        <li>Accepts user input</li>
        <li>Has a Data Source prop to update data using an API Request</li>
      </ul>
      <p className="clone_para">
        You can use any component that contains a Data Source prop to log user
        input and submit data. This list of components currently includes:
      </p>
      <li>Text Input</li>
      <li>Text Area</li>
      <li>Styled Text Field</li>
      <li>Checkbox</li>
      <li>Slider</li>
      <li>Radio Button Group</li>
      <li>Radio Button</li>
      <p className="clone_para">
        Once you have the necessary component(s) on your screen, you'll need to
        create a new endpoint in your service:
      </p>
      <li>
        Click <strong>Add endpoint</strong>.
      </li>
      <li>enter a name for the endpoint and set the Method to PATCH.</li>
      <li>
        Set the Role to <strong>Update</strong>. Set the Object Type to{" "}
        <strong>todos</strong> for this example.
      </li>
      <li>
        add <code>{"id"}</code> where id is provided as a test value of an
        existing record when testing the endpoint. In your app, you will have to
        pass the id of a single record, for example, as a navigation parameter.
      </li>
      <li>
        add a valid Body structure to submit a PATCH request. Add one or many{" "}
        <code>{"variable"}</code> for test values. Click{" "}
        <strong>Body Preview</strong> to validate the structure of the Body in
        the request.
      </li>
      <li>
        click the <strong>Test</strong> button next to the Endpoint input to
        verify the response coming from the API service.
      </li>
      <li>
        Click <strong>Save</strong>.
      </li>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <h3 className="clone_heading">Similar to POST Request:</h3>
      <p className="clone_para">
        You can use a Touchable/Button component to trigger the action API
        Request.
      </p>
      <h3 className="clone_heading">Delete Data</h3>
      <p className="clone_para">
        The DELETE request is used with an item's id to remove that particular
        record from the table.
      </p>
      <p className="clone_para">
        Once you have the necessary component(s) on your screen, you'll need to
        create a new endpoint in your service:
      </p>
      <ol>
        <li>
          Click <strong>Add endpoint</strong>.
        </li>
        <li>
          In Step 1: enter a name for the endpoint and set the Method to DELETE.
        </li>
        <li>
          Set the Role to <strong>Delete</strong>. Set the Object Type to{" "}
          <strong>todos</strong> for this example.
        </li>
        <li>
          In Step 2: add <code>{"id"}</code> where id is provided as a test
          value of an existing record when testing the endpoint. In your app,
          you will have to pass the id of a single record, for example, as a
          navigation parameter.
        </li>
        <li>
          In Step 4: click the <strong>Test</strong> button next to the Endpoint
          input to verify the response from the API service.
        </li>
        <li>
          Click <strong>Save</strong>.
        </li>
      </ol>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </>
  );
};

export default ExampleData;
