import React from "react";
import DynamicallyStyle from "../../../assets/Image/DynamicallyStyle.png";
import NotesBox from "../../docPageNote/NotesBox";

export const DynamicStyling = () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Dynamic Styling</h1>
      </div>
      <p className="clone_para">
        All of the style props on our components can be set dynamically by
        clicking on the prop label. In the tooltip, there's a section where you
        can select a variable or available fetch data. You can even apply a
        Transform Function!
      </p>
      <p className="clone_para">
        The data icon next to the label indicates that the value is being set
        dynamically. If the data evaluates to undefined, the static value you've
        configured will be used instead (inline or Stylesheet).
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={DynamicallyStyle} alt="" width="80%" />
      </span>
      <p className="clone_para">
        In the example above, we're passing Device Variables set to strings of
        different Hex color values into various color props on some of the
        components.
      </p>
      <NotesBox noteText="ColorPage" />{" "}
      <p className="clone_para">
        Hex and RGB color values are allowed for color props. This flexibility
        allows for greater customization and responsiveness in your component
        styling.
      </p>
    </>
  );
};
