import React from "react";
import NotesBox from "../../docPageNote/NotesBox";

function PressablePage() {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Pressable</h1>
      </div>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      {/* <p className="clone_para">
       Pressable component is a new and improved version of Touchable.
      </p> */}
      {/* <NotesBox noteText="PressableComp" /> */}
      <p className="clone_para">
        The Pressable is a wrapper component used to make other components
        responsive to the user's touch. It is useful for creating custom buttons
        or custom touchable containers. You can use it to trigger actions.
      </p>

      <h2 id="style-properties" className="clone_head">
        Style Properties
      </h2>
      <p className="clone_para">
        Style properties allow you to customize the look and feel of the
        component. Combinations of styles applied to components can be saved as
        Stylesheets to easily reuse styles throughout your app. Styles can also
        be set dynamically using Variables. To learn more about all the
        different styling properties and how they work, take a look at Intro to
        Styling.
      </p>

      <h2 id="component-specific-styles" className="clone_head">
        Component Specific Styles
      </h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Disabled Opacity</td>
            <td>The opacity when the component is disabled.</td>
          </tr>
          <tr>
            <td>Borderless Ripple</td>
            <td>
              Android only. Defines if ripple effect should not include border.
            </td>
          </tr>
          <tr>
            <td>Ripple Color</td>
            <td>Android only. Defines the color of the ripple effect.</td>
          </tr>
          <tr>
            <td>Foreground Ripple</td>
            <td>
              Android only. Set to true to add the ripple effect to the
              foreground of the view, instead of the background. This is useful
              if one of your child views has a background of its own, or you're
              displaying images, and you don't want the ripple to be covered by
              them.
            </td>
          </tr>
          <tr>
            <td>Ripple Radius</td>
            <td>Android only. Defines the radius of the ripple effect.</td>
          </tr>
        </tbody>
      </table>

      <h2 id="configuration-properties" className="clone_head">
        Configuration Properties
      </h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Component Name</td>
            <td>
              To alter the name of the component. The name is reflected in the
              Components tree. Defaults to Pressable.
            </td>
          </tr>
          <tr>
            <td>Delay Long Press</td>
            <td>
              Duration (in milliseconds) from onPressIn before onLongPress is
              called.
            </td>
          </tr>
          <tr>
            <td>Hit Slop</td>
            <td>
              Sets additional distance outside of the element in which a press
              can be detected.
            </td>
          </tr>
          <tr>
            <td>Active Opacity</td>
            <td>The opacity when the button is pressed.</td>
          </tr>
          <tr>
            <td>Disabled Opacity</td>
            <td>The opacity when the button is disabled.</td>
          </tr>
        </tbody>
      </table>

      <h2 id="data-properties" className="clone_head">
        Data Properties
      </h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Disabled</td>
            <td>Disables the component when turned on.</td>
          </tr>
        </tbody>
      </table>

      <h2 id="conditional-display" className="clone_head">
        Conditional Display
      </h2>
      <p className="clone_para">
        You can conditionally display a component based on a given condition.
        Learn more about conditionally displaying components in the Conditional
        Display doc.
      </p>

      <h2 id="triggers" className="clone_head">
        Triggers
      </h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Trigger</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>On Press</td>
            <td>Starts the action when the element is pressed.</td>
          </tr>
          <tr>
            <td>On Long Press</td>
            <td>Starts the action when the element is long pressed.</td>
          </tr>
        </tbody>
      </table>
      <h2 id="triggers" className="clone_head">
        Creating a Custom Button From using Pressable{" "}
      </h2>
      <NotesBox noteText="PressableComp" />
    </div>
  );
}

export default PressablePage;
