import React from "react";

const ButtonPage = () => {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Button</h1>
      </div>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <p className="clone_para">
        The Button is an interactive component with a background color, label,
        and optional icon. You can use a Button to trigger actions.
      </p>

      <h2 id="style-properties" className="clone_head">
        Style Properties
      </h2>
      <p className="clone_para">
        Style properties allow you to customize the look and feel of the
        component. Combinations of styles applied to components can be saved as
        Stylesheets to easily reuse styles throughout your app. Styles can also
        be set dynamically using Variables. To learn more about all the
        different styling properties and how they work, take a look at Intro to
        Styling.
      </p>

      <h2 id="component-specific-styles" className="clone_head">
        Component Specific Styles
      </h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Active Opacity</td>
            <td>Allows you to set opacity of the button when active.</td>
          </tr>
          <tr>
            <td>Disabled Opacity</td>
            <td>Allows you to set opacity of the button when disabled.</td>
          </tr>
        </tbody>
      </table>

      <h2 id="configuration-properties" className="clone_head">
        Configuration Properties
      </h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Component Name</td>
            <td>
              To alter the name of the component. The name is reflected in the
              Components tree. Defaults to Button.
            </td>
          </tr>
          <tr>
            <td>Delay Long Press</td>
            <td>
              Allows you to set duration (in milliseconds) from On Press before
              On Long Press is called.
            </td>
          </tr>
          <tr>
            <td>Hit Slop</td>
            <td>
              Allows you to set additional distance outside of the element in
              which a press can be detected.
            </td>
          </tr>
        </tbody>
      </table>

      <h2 id="data-properties" className="clone_head">
        Data Properties
      </h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Button Text</td>
            <td>The text to display as the button's label.</td>
          </tr>
          <tr>
            <td>Icon</td>
            <td>
              Allows you to select an icon from the icon picker to be displayed
              on the Button (Icon will be on the left of the Label).
            </td>
          </tr>
          <tr>
            <td>Custom Icon</td>
            <td>
              Render an icon using a string of the icon's identifier. Overrides
              the 'icon' property. Full list of icons here. Ex:
              'Foundation/align-right', 'Feather/check'.
            </td>
          </tr>
          <tr>
            <td>Loading</td>
            <td>
              Display a loading indicator in the button in lieu of the icon.
            </td>
          </tr>
          <tr>
            <td>Disabled</td>
            <td>
              Removes button functionality and grays out the label and icon.
            </td>
          </tr>
        </tbody>
      </table>

      <h2 id="conditional-display" className="clone_head">
        Conditional Display
      </h2>
      <p className="clone_para">
        You can conditionally display a component based on a given condition.
        Learn more about conditionally displaying components in the Conditional
        Display doc.
      </p>

      <h2 id="triggers" className="clone_head">
        Triggers
      </h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Trigger</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>On Press</td>
            <td>Starts the action when the element is pressed.</td>
          </tr>
          <tr>
            <td>On Long Press</td>
            <td>Starts the action when the element is long pressed.</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ButtonPage;
