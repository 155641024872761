import React, { useEffect, useState, useRef } from "react";
import {
  Replay,
  AddCircle,
  MoreHoriz,
  FolderCopy,
  DeleteOutline,
} from "@mui/icons-material";
import AddScreenPopup from "../../common/DashboardPopup/AddScreenPopup";
import AddComponentPopup from "../../common/DashboardPopup/AddComponentPopup";
import { useSpring, animated } from "@react-spring/web";
import Collapse from "@mui/material/Collapse";
import { alpha, styled } from "@mui/material/styles";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { useDispatch, useSelector } from "react-redux";
import {
  addScreenList,
  getMyScreenList,
  setDefaultValues,
  setSelectedCanvas,
  deleteScreen,
  setFolderStructure,
  setSelectedFolder,
  getScreenData,
  getVersionList,
  getScreenModal,
} from "../../redux/actions/defaultActions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ViewFolder from "../../common/viewfolder/ViewFolder";
import useOutsideClick from "../../common/customHooks/useOutsideClick";
import Modal from "react-responsive-modal";
import Select from "react-select";
import {
  getMyAllScreen,
  getMyProjects,
} from "../../redux/actions/projectsActions";
import ModalDelete from "../../common/svgComponents/ModalDelete";
import FileIcon from "../../assets/Iconcomponent/FileIcon";
import Duplicate from "../../assets/Iconcomponent/Duplicate";

const LeftSidebar = ({
  setActiveTab,
  screen,
  isDraftPreview,
  handleChangeTab,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addPopup, setAddPopup] = useState(false);
  const [showOptionsIndex, setShowOptionsIndex] = useState(null);
  const [addValue, setAddValue] = useState();
  const [deleteConfirmationIndex, setDeleteConfirmationIndex] = useState(null);
  const [componentPopup, setComponentPopup] = useState(true);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [diableBtn, setDisableBtn] = useState(true);
  const [activeLink, setActiveLink] = useState(null);
  const [screenListData, setScreenListData] = useState();
  const [text, setText] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalProject, setModalProject] = useState();
  const [initialData, setInitialData] = useState(true);

  const [editText, setEditText] = useState({
    image: "Image",
    text: "Text",
    view: "View",
  });
  const [iconPopup, setIconPopup] = useState(false);
  const [sameProject, setSameProject] = useState(false);
  const [screenAdd, setcreenAdd] = useState(false);

  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [state, setState] = useState({
    isScreenListOpen: true,
    isViewFolderOpen: true,
  });

  useEffect(() => {
    dispatch(getMyProjects());
  }, []);

  const popupRef = useRef(null);
  const customStylesData = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#F8F8FF",
      borderRadius: "5px",
      width: "730px",
      border: "none",
      minHeight: "50px",
      fontSize: "14px",
      color: "#fff",
      border: "1.5px solid #C5C5FF",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#F8F8FF",
      color: "#283249",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "  rgb(88 103 138 / 56%)" : "#F8F8FF",
      color: isFocused ? "#283249" : "#283249",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#283249",
    }),
    input: (styles) => ({
      ...styles,
      color: "#283249", // Text color set to white
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "white", // Customize the placeholder color
    }),
  };

  const { addCanvasImage } = useSelector((state) => state.auth);
  const {
    defaultValues,
    selectedCanvas,
    addNewScreenList,
    folderStructure,
    selectedFolder,
    getMyScreenData,
  } = useSelector((state) => state.default);

  const {
    getScreenList,
    deleteMyScreen,
    getSdkResourceList,
    getMyScreenModalData,
    editScreenData,
  } = useSelector((state) => state.default);

  const pageLoading = useSelector((state) => state?.auth?.pageLoading);

  const myProjectId = localStorage.getItem("project_id");

  const selectedVersion = localStorage.getItem("sdkVersion");

  const myAllProjectList = useSelector(
    (state) => state?.auth?.myAllProjectList
  );
  const showAllMyScrrens = useSelector(
    (state) => state?.auth?.showAllMyScrrens
  );

  useEffect(() => {
    if (myProjectId) {
      dispatch(getMyScreenList(myProjectId));
    }
  }, [myProjectId, addNewScreenList, deleteMyScreen]);

  const screenLists = getScreenList?.screenList;

  useEffect(() => {
    if (getScreenList?.screenList) {
      setScreenListData(getScreenList?.screenList);
    }
  }, [getScreenList?.screenList]);

  useEffect(() => {
    if (addCanvasImage) {
      dispatch(
        setDefaultValues({
          ...defaultValues,
        })
      );
    }
  });
  const newRef = useRef(HTMLLIElement);

  // const handleAddScreen = () => {
  //   // dispatch(
  //   //   setDefaultValues({
  //   //     ...defaultValues,
  //   //     screenList: [
  //   //       ...defaultValues.screenList,
  //   //       { title: defaultValues?.name },
  //   //     ],
  //   //   })
  //   // );
  //   dispatch(
  //     addScreenList(myProjectId, {
  //       screens: [
  //         {
  //           screenname: defaultValues?.name,
  //           draft: [
  //             {
  //               name: defaultValues?.name,
  //               isFolder: true,
  //               children: [],
  //               type: "view",
  //             },
  //           ],
  //         },
  //       ],
  //     })
  //   );
  //   setAddPopup(false);
  // };

  const generateUniqueId = () => {
    return "id" + Date.now() + Math.floor(Math.random() * 100);
  };

  const initialViewId = generateUniqueId();

  const handleAddScreen = () => {
    dispatch(
      addScreenList(myProjectId, {
        screens: [
          {
            screenname: defaultValues?.name,
            draft: [
              {
                name: defaultValues?.name,
                isFolder: true,
                children: [],
                type: "view",
                id: initialViewId,
                background: "#ffffff",
                customDataType: false,
                size: [
                  { width: "auto", unit: "px" },
                  { height: "auto", unit: "px" },
                  { min_width: "auto", unit: "px" },
                  { min_height: "auto", unit: "px" },
                  { max_width: "auto", unit: "px" },
                  { max_height: "auto", unit: "px" },
                ],
                margin: [
                  { all: "0", unit: "px" },
                  { left: "0", unit: "px" },
                  { top: "0", unit: "px" },
                  { right: "0", unit: "px" },
                  { bottom: "0", unit: "px" },
                ],
                padding: [
                  { all: "0", unit: "px" },
                  { left: "0", unit: "px" },
                  { top: "0", unit: "px" },
                  { right: "0", unit: "px" },
                  { bottom: "0", unit: "px" },
                ],
                direction: "column",
                align: "flex-start",
                justify: "flex-start",
                alignitem: "flex-start",
                alignContent: "flex-start",
                flexWrap: "",
                display: "flex",
              },
            ],
            screendata: [
              {
                name: defaultValues?.name,
                isFolder: true,
                children: [],
                type: "view",
                id: initialViewId,
                background: "#ffffff",
                customDataType: false,
                size: [
                  { width: "auto", unit: "px" },
                  { height: "auto", unit: "px" },
                  { min_width: "auto", unit: "px" },
                  { min_height: "auto", unit: "px" },
                  { max_width: "auto", unit: "px" },
                  { max_height: "auto", unit: "px" },
                ],
                margin: [
                  { all: "0", unit: "px" },
                  { left: "0", unit: "px" },
                  { top: "0", unit: "px" },
                  { right: "0", unit: "px" },
                  { bottom: "0", unit: "px" },
                ],
                padding: [
                  { all: "0", unit: "px" },
                  { left: "0", unit: "px" },
                  { top: "0", unit: "px" },
                  { right: "0", unit: "px" },
                  { bottom: "0", unit: "px" },
                ],
                direction: "column",
                align: "flex-start",
                justify: "flex-start",
                alignitem: "flex-start",
                alignContent: "flex-start",
                flexWrap: "",
                display: "flex",
              },
            ],
          },
        ],
      })
    );
    setAddPopup(false);
    onCloseModalSelect();

    setInputValue("");
  };

  const selectExampleScreen = () => {
    setShowModal(true);
    setSameProject(false);
  };

  const onCloseModalSelect = () => {
    setShowModal(false);
    setDisableBtn(true);
  };

  const titleChangeHandler = (e) => {
    dispatch(
      setDefaultValues({ ...defaultValues, [e.target.name]: e.target.value })
    );
  };

  useOutsideClick(popupRef, () => {
    if (addPopup) {
      setAddPopup(false);
    }
  });

  const closeModelPopup = () => {
    setAddPopup(false);
  };
  const closeModalComponent = () => setComponentPopup(false);

  const handleMouseEnter = (index) => setHoveredItem(index);

  const handleMouseLeave = () => setHoveredItem(null);

  const handleChange = (e) => setText(e.target.innerText);

  const closeIconModelPopup = () => setIconPopup(false);

  const screnid = localStorage.getItem("screen_Id");

  useEffect(() => {
    if (screnid) {
      const projectId = localStorage.getItem("project_id");
      dispatch(getScreenData(projectId, screnid));
    }
  }, [screnid, addNewScreenList, deleteMyScreen, editScreenData]);

  useEffect(() => {
    if (screenListData?.length > 0) {
      setActiveLink(screenListData && screenListData[0]?._id);
      localStorage.setItem(
        "screen_Id",
        screenListData && screenListData[0]._id
      );
      localStorage.setItem(
        "screen_name",
        screenListData && screenListData[0].name
      );

      // localStorage.setItem(
      //   "crntVersion_id",
      //   screenListData &&
      //     screenListData[0] &&
      //     screenListData[0]?.versions[screenListData[0].versions?.length - 1]
      //       ?._id
      // );
      setActiveTab({ screen: screenListData?.[0] });
      dispatch(setSelectedCanvas({ screen: screenListData?.[0] }));
    } else if (screenListData?.length === 0) {
      localStorage.removeItem("screen_Id");
      localStorage.removeItem("screen_name");
      dispatch(setSelectedCanvas(null));
    }
  }, [screenListData]);

  // useEffect(() => {
  //   dispatch(getScreenData(projectId, screen_Id));
  // }, [projectId, screen_Id, addNewScreenList, deleteMyScreen, editScreenData]);

  const handleTabClick = (screen, event) => {
    if (screen?.screen?._id !== selectedCanvas?.screen?._id) {
      const apiDataString = JSON.stringify(getMyScreenData);
      const stateDataString = JSON.stringify(folderStructure);
      const myDifference = apiDataString === stateDataString;

      if (selectedCanvas === null) {
        const screenId = encodeURIComponent(screen.screen._id);
        const screen_Id = localStorage.setItem("screen_Id", screen.screen._id);
        localStorage.setItem(
          "crntVersion_id",
          screen?.screen?.versions[screen.screen.versions.length - 1]?._id
        );

        const datas = {
          p_id: localStorage.getItem("project_id"),
          s_id: screen.screen._id,
        };
        dispatch(getVersionList(datas));
        localStorage.setItem("screen_name", screen.screen.name);
        const projectName = localStorage.getItem("project_name");
        navigate(`/workspace/${projectName}/${screenId}`);
        setActiveTab(screen);
        setActiveLink(screenId, screen_Id);
        dispatch(setSelectedCanvas(screen));

        const rect = event.getBoundingClientRect();
        const popupPosition = { x: rect.left, y: rect.top };
        setPopupPosition({ x: rect.left, y: rect.top });
      } else if (myDifference === true) {
        const screenId = encodeURIComponent(screen.screen._id);
        const screen_Id = localStorage.setItem("screen_Id", screen.screen._id);
        localStorage.setItem(
          "crntVersion_id",
          screen?.screen?.versions[screen.screen.versions.length - 1]?._id
        );

        const datas = {
          p_id: localStorage.getItem("project_id"),
          s_id: screen.screen._id,
        };
        dispatch(getVersionList(datas));
        localStorage.setItem("screen_name", screen.screen.name);
        const projectName = localStorage.getItem("project_name");
        navigate(`/workspace/${projectName}/${screenId}`);
        setActiveLink(screenId, screen_Id);
        setActiveTab(screen);
        dispatch(setSelectedCanvas(screen));
        dispatch(
          setSelectedFolder({
            name: "",
            isFolder: true,
            children: [],
            type: "view",
            backgroundColor: "ffffff",
          })
        );
      } else {
        toast.error("Save your progress first");
      }
    }
  };

  function TransitionComponent(TransitionProps) {
    const style = useSpring({
      to: {
        opacity: TransitionProps.in ? 1 : 0,
        transform: `translate3d(${TransitionProps.in ? 0 : 20}px,0,0)`,
      },
    });

    return (
      <animated.div style={style}>
        <Collapse {...TransitionProps} />
      </animated.div>
    );
  }

  const CustomTreeItem = React.forwardRef((TreeItemProps, ref) => (
    <TreeItem
      {...TreeItemProps}
      TransitionComponent={TransitionComponent}
      ref={newRef}
    />
  ));

  const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
      "& .close": {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  }));

  // const handleDuplicateText = (dataType, index) => {
  //   if (dataType === "text") {
  //     const newImageValue = defaultValues?.textValue;
  //     const selectedTextItem = newImageValue[index];

  //     let list = [...newImageValue];
  //     list.splice(index + 1, 0, selectedTextItem);

  //     dispatch(
  //       setDefaultValues({
  //         ...defaultValues,
  //         textValue: list,
  //       })
  //     );
  //   } else if (dataType === "image") {
  //     const newImageValue = defaultValues?.imgValue;
  //     const selectedImageItem = newImageValue[index];

  //     let list = [...newImageValue];
  //     list.splice(index + 1, 0, selectedImageItem);

  //     dispatch(
  //       setDefaultValues({
  //         ...defaultValues,
  //         imgValue: list,
  //       })
  //     );
  //   }
  // };

  // const handleDeleteText = (dataType, index) => {
  //   if (dataType === "text") {
  //     const newImageValue = defaultValues?.textValue;

  //     let list = [...newImageValue];
  //     list.splice(selectedTextIndex, 1);

  //     dispatch(
  //       setDefaultValues({
  //         ...defaultValues,
  //         textValue: list,
  //       })
  //     );
  //   } else if (dataType === "image") {
  //     const newImageValue = defaultValues?.imgValue;

  //     let list = [...newImageValue];
  //     list.splice(selectedImageIndex, 1);

  //     dispatch(
  //       setDefaultValues({
  //         ...defaultValues,
  //         imgValue: list,
  //       })
  //     );
  //   }
  // };
  // const setSelectedComponent = (type, index) => {
  //   dispatch(
  //     setDefaultValues({
  //       ...defaultValues,
  //       selectedComponent: type,
  //       selectedComponentValue: index,
  //     })
  //   );
  // };

  const handleToggleOptions = (index) => {
    setShowOptionsIndex(index === showOptionsIndex ? null : index);
    setDeleteConfirmationIndex(false);
  };

  const handleOptionClick = (option, index) => {
    if (option === "delete") {
      setDeleteConfirmationIndex(index);
    }
    setShowOptionsIndex(null);

    // if (option === "delete") {
    //   setDeleteConfirmationIndex(
    //     deleteConfirmationIndex === index ? null : index
    //   );
    // }
  };

  const handleConfirmDelete = () => {
    const screenId = localStorage.getItem("screen_Id");
    dispatch(deleteScreen(myProjectId, screenId, setFolderStructure));
    setDeleteConfirmationIndex(null);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmationIndex(null);
  };

  const handleDuplicate = (type, index, scrName) => {
    const duplicatedFolderStructure = folderStructure.map((item) => {
      const duplicatedItem = { ...item };
      duplicatedItem.id = generateUniqueId();

      return duplicatedItem;
    });

    dispatch(
      addScreenList(myProjectId, {
        screens: [
          {
            screenname: scrName,
            draft: duplicatedFolderStructure,
          },
        ],
      })
    );
    setShowOptionsIndex(null);
  };

  const openAddScreen = () => {
    setAddPopup(true);
    setComponentPopup(false);
    setIconPopup(false);
  };

  const openComponentScreen = () => {
    setComponentPopup(true);
    setAddPopup(false);
    setIconPopup(false);
  };

  // const openIconPopup = (data, index) => {
  //   setIconPopup(true);
  //   setAddPopup(false);
  //  setComponentPopup(false);
  //   setSelectedImageIndex(index);
  //   setSelectedTextIndex(index);
  // };
  const toggleScreenList = () => {
    setState({ ...state, isScreenListOpen: !state.isScreenListOpen });
  };

  const toggleViewFolder = () => {
    setState({ ...state, isViewFolderOpen: !state.isViewFolderOpen });
  };

  const selectScreenHandler = () => {
    dispatch(
      addScreenList(myProjectId, {
        screens: [
          {
            screenname: getMyScreenModalData?.screendata[0]?.name,
            draft: getMyScreenModalData?.draft,
            screendata: getMyScreenModalData?.screendata,
            draftData: getMyScreenModalData?.draftData,
          },
        ],
      })
    );

    onCloseModalSelect();
  };

  const handleChangeSelectProject = (newValue) => {
    dispatch(getMyAllScreen(newValue?.value));
    setModalProject(newValue?.value);
  };

  const handleChangeSelectScreen = (newValue) => {
    setDisableBtn(false);
    dispatch(getScreenModal(modalProject, newValue?.value));
  };

  const selectBlankScreen = () => {
    setAddPopup(false);
    setShowModal(true);
    setSameProject(true);
  };

  const handleAddScreenClick = () => {
    handleAddScreen();
    setInputValue("");
  };

  return (
    <>
      <div className="main-leftbar">
        <div className="screen-sec">
          <div className="inner">
            <div className="title">
              <p>Screen{`(${screenListData ? screenListData.length : 0})`}</p>
            </div>

            <div className="icn">
              <div>
                <span onClick={toggleScreenList}>
                  <Replay />
                </span>
                <span onClick={openAddScreen}>
                  <AddCircle />
                </span>
              </div>
              {addPopup && (
                <div className="popupDiv" ref={popupRef}>
                  <AddScreenPopup
                    closeModelPopup={closeModelPopup}
                    titleChangeHandler={titleChangeHandler}
                    handleAddScreen={handleAddScreen}
                    defaultValues={defaultValues}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    selectExampleScreen={selectExampleScreen}
                    setSameProject={setSameProject}
                    selectBlankScreen={selectBlankScreen}
                    initialData={initialData}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="list-sec">
            <div className="inner_list">
              {state.isScreenListOpen && screenListData?.length > 0 && (
                <div className="nav-list">
                  {screenListData?.map((screen, index) => (
                    <div
                      key={index}
                      className={
                        screen._id === activeLink
                          ? "active_link"
                          : "no_active_link"
                      }
                      onClick={(event) => handleTabClick({ screen, event })}
                      style={{ position: "relative" }}
                    >
                      <span className="screen_name">{screen.name}</span>

                      <div
                        className="options-icon"
                        onClick={() => handleToggleOptions(index)}
                      >
                        <span className="three-dots">
                          <MoreHoriz />
                        </span>
                      </div>

                      {showOptionsIndex === index && (
                        <div
                          className="options"
                          style={{
                            position: "absolute",
                            top: popupPosition.y,
                            left: popupPosition.x,
                            height: "100px",
                          }}
                        >
                          <>
                            {/* <button
                              onClick={() =>
                                handleDuplicate(
                                  "duplicate",
                                  index,
                                  screen?.name
                                )
                              }
                            >
                              <FolderCopy />
                              Duplicate
                            </button>
                            <button
                              onClick={() => handleOptionClick("delete", index)}
                            >
                              <DeleteOutline />
                              Delete
                            </button> */}

                            <div className="shareOptionv">
                              <svg
                                width="27"
                                height="16"
                                viewBox="0 0 27 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="arrowsvgv"
                              >
                                <path
                                  d="M14.6604 1.01399L14.6606 1.01423L25.4958 13.4827L25.497 13.484L25.6237 13.6319C25.7035 13.7488 25.75 13.8888 25.75 14.0339C25.75 14.4292 25.4065 14.793 24.9272 14.793L2.07281 14.793C1.59346 14.793 1.25 14.4292 1.25 14.0339C1.25 13.894 1.29075 13.7607 1.36337 13.6451L1.52294 13.4647L1.52295 13.4647L1.52565 13.4616L12.3525 1.01754C12.3529 1.01708 12.3533 1.01661 12.3537 1.01615C12.6291 0.705598 13.0439 0.500942 13.508 0.500942C13.9768 0.500942 14.3889 0.701184 14.6604 1.01399Z"
                                  fill="#1A237E"
                                  stroke="#1A237E"
                                />
                              </svg>
                              <ul className="shareUlv">
                                <li
                                  className="shareLiv"
                                  onClick={() =>
                                    handleDuplicate(
                                      "duplicate",
                                      index,
                                      screen?.name
                                    )
                                  }
                                >
                                  <FileIcon />
                                  Duplicate
                                </li>
                                <li
                                  className="shareLiv"
                                  onClick={() =>
                                    handleOptionClick("delete", index)
                                  }
                                >
                                  <Duplicate />
                                  delete
                                </li>
                              </ul>
                            </div>
                          </>
                        </div>
                      )}

                      {deleteConfirmationIndex === index && (
                        <div className="delete-confirmation">
                          <div className="header_bars">
                            <div className="svg_trashs">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 70 80"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_3067_2481)">
                                  <path
                                    d="M67.5 12.5H54.6281L49.3156 3.63281C47.8281 1.37922 45.5312 0 42.9062 0H27.0938C24.4688 0 22.0312 1.37922 20.6875 3.63281L15.3719 12.5H2.5C1.11328 12.5 0 13.6141 0 15V17.5C0 18.8906 1.11328 20 2.5 20H5V70C5 75.5234 9.47656 80 15 80H55C60.5234 80 65 75.5234 65 70V20H67.5C68.8906 20 70 18.8906 70 17.5V15C70 13.6141 68.8906 12.5 67.5 12.5ZM26.8594 7.95C27.0156 7.67656 27.3281 7.5 27.6562 7.5H42.3438C42.6758 7.5 42.9883 7.67578 43.1445 7.94922L45.875 12.5H24.125L26.8594 7.95ZM55 72.5H15C13.6192 72.5 12.5 71.3808 12.5 70V20H57.5V70C57.5 71.375 56.375 72.5 55 72.5ZM35 65C36.3819 65 37.5 63.8819 37.5 62.5V30C37.5 28.6181 36.3819 27.5 35 27.5C33.6181 27.5 32.5 28.625 32.5 30V62.5C32.5 63.875 33.625 65 35 65ZM22.5 65C23.875 65 25 63.875 25 62.5V30C25 28.6181 23.8819 27.5 22.5 27.5C21.1181 27.5 20 28.625 20 30V62.5C20 63.875 21.125 65 22.5 65ZM47.5 65C48.8819 65 50 63.8819 50 62.5V30C50 28.6181 48.8819 27.5 47.5 27.5C46.1181 27.5 45 28.625 45 30V62.5C45 63.875 46.125 65 47.5 65Z"
                                    fill="#F32028"
                                    fill-opacity="0.74"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_3067_2481">
                                    <rect width="70" height="80" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          </div>
                          <h5>Are you sure?</h5>
                          <p>This item will be permanently deleted?</p>
                          <div className="btns_sec_delete">
                            <button onClick={handleConfirmDelete}>
                              Delete
                            </button>
                            <button onClick={handleCancelDelete}>Cancel</button>
                          </div>
                        </div>
                        // <ConfirmationPopup
                        //   handleConfirmDelete={handleConfirmDelete}
                        //   handleCancelDelete={handleCancelDelete}
                        // />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="folder_sec">
          <div className="inner">
            <div className="title">
              <p>Components</p>
            </div>

            <div className="icn">
              <div>
                <span onClick={toggleViewFolder}>
                  <Replay />
                </span>
                <span onClick={openComponentScreen}>
                  <AddCircle />
                </span>
              </div>

              {componentPopup && (
                <div className="popupDiv">
                  <AddComponentPopup
                    closeModalComponent={closeModalComponent}
                    titleChangeHandler={titleChangeHandler}
                    handleAddScreen={handleAddScreen}
                    getSdkResourceList={getSdkResourceList}
                  />
                </div>
              )}
            </div>
          </div>
          {selectedCanvas && (
            <div style={{ position: "relative" }}>
              <div className="orders-sec">
                {state.isViewFolderOpen && (
                  <div className="inner_sec">
                    {screen && (
                      <ViewFolder
                        folder={folderStructure}
                        isDraftPreview={isDraftPreview}
                        handleChangeTab={handleChangeTab}
                        selectedVersion={selectedVersion}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        open={showModal}
        onClose={onCloseModalSelect}
        classNames={{
          modal: sameProject ? "addScreenProject" : "addScreenModal",
        }}
        center
      >
        <>
          {sameProject ? (
            <div>
              <h3 className="heading-screens">Add New Screen </h3>
              <div className="projectdivx">
                <label htmlFor="">New Screen</label>
                <input
                  type="text"
                  className="add_input"
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    titleChangeHandler(e);
                  }}
                  name="name"
                  value={inputValue}
                  placeholder="Screen name"
                />
              </div>

              <div className="subitScreenDivs">
                <button
                  className="submitScreens"
                  onClick={handleAddScreenClick}
                  disabled={!inputValue.trim()}
                >
                  Add
                </button>
              </div>
            </div>
          ) : (
            <div>
              <h3 className="heading-screen">Add Screen From Other Project</h3>
              <div className="projectdivs">
                <label> Project Name</label>
                <Select
                  placeholder="Select Project "
                  styles={customStylesData}
                  className="inputfor"
                  hideSelectedOptions
                  name="option-select"
                  noOptionsMessage={() => <div>No Projects Available</div>}
                  onChange={handleChangeSelectProject}
                  options={myAllProjectList?.map((data) => ({
                    value: data?._id,
                    label: data.projectname,
                  }))}
                />
              </div>
              <div className="projectdivs">
                <label>Screen Name</label>
                <Select
                  placeholder="Screen Name"
                  styles={customStylesData}
                  className="inputfor"
                  hideSelectedOptions
                  name="option-select"
                  noOptionsMessage={() => <div>No Screens Available</div>}
                  onChange={handleChangeSelectScreen}
                  options={showAllMyScrrens?.screens?.screenList?.map(
                    (data) => ({
                      value: data?._id,
                      label: data.name,
                    })
                  )}
                />
              </div>
              <div className="subitScreenDiv">
                <button
                  onClick={selectScreenHandler}
                  className="submitScreen"
                  disabled={diableBtn}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </>
      </Modal>
    </>
  );
};

export default LeftSidebar;
