import React from "react";
import NotesBox from "../../docPageNote/NotesBox";

export const IconPage = () => {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Icon</h1>
      </div>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <p className="clone_para">
        The Icon component is a visual indicator to show a small image or a
        symbol. There are hundreds of different open source icons for you to
        choose from.
      </p>
      <NotesBox noteText="IconPage" />{" "}
      <p className="clone_para">
        You can pick an icon from the available list of icon sets:
      </p>
      <h2 className="clone_head" id="icon-sets">
        Icon Sets
      </h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th scope="col">Components</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Ant Design
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Entypo
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Evil Icons
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Feather
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Font Awesome
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Foundation
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Ionicons
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Material Community Icons
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Material Icons
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Octicon
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Simple Line Icons
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Zocial
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <h2 id="style-properties" className="clone_head">
        Style Properties
      </h2>
      <p className="clone_para">
        Style properties allow you to customize the look and feel of the
        component. Combinations of styles applied to components can be saved as
        Stylesheets to easily reuse styles throughout your app. Styles can also
        be set dynamically using Variables. To learn more about all the
        different styling properties and how they work, take a look at Intro to
        Styling.
      </p>
      <h3 id="component-specific-styles" className="clone_head">
        Component Specific Styles
      </h3>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Color</td>
            <td>The color of the icon. Defaults to strong.</td>
          </tr>
          <tr>
            <td>Size</td>
            <td>The width and height of the icon. Defaults to 24.</td>
          </tr>
        </tbody>
      </table>
      <h2 id="configuration-properties" className="clone_head">
        Configuration Properties
      </h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Component Name</td>
            <td>
              To alter the name of the component. The name is reflected in the
              Components tree. Defaults to Icon.
            </td>
          </tr>
        </tbody>
      </table>
      <h2 id="data-configuration" className="clone_head">
        Data Configuration
      </h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Icon</td>
            <td>
              The icon is selected from the icon picker. Defaults to photo.
            </td>
          </tr>
          <tr>
            <td>Custom Icon</td>
            <td>
              Render an icon using a string of the icon's identifier. Overrides
              the 'icon' property. Full list of icons here. Ex:
              'Foundation/align-right', 'Feather/check'
            </td>
          </tr>
        </tbody>
      </table>
      <h2 id="conditional-display" className="clone_head">
        Conditional Display
      </h2>
      <p className="clone_para">
        You can conditionally display a component based on a given condition.
        Learn more about conditionally displaying components in the Conditional
        Display doc.
      </p>
    </div>
  );
};
