import React, { useEffect, useState } from "react";
import { Info } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import EditMode from "../../common/svgComponents/EditMode";
import LiveMode from "../../common/svgComponents/LiveMode";
import MainScroll from "./RightbarTab/MainScroll";
import ThirdScroll from "./RightbarTab/ThirdScroll";
import SecondTab from "./RightbarTab/SecondTab";
import FourthTab from "./RightbarTab/FourthTab";
import database from "../../assets/Icons/database.svg";
import brush_icon from "../../assets/Icons/brush_icon.svg";
import magic_icon from "../../assets/Icons/magic_icon.svg";
import painticon from "../../assets/Icons/painticon.svg";
import { useDispatch, useSelector } from "react-redux";
import ImageSvg from "../../common/svgComponents/ImageSvg";
import TextSvg from "../../common/svgComponents/TextSvg";
import ViewSvg from "../../common/svgComponents/ViewSvg";
import FlashListSvg from "../../common/svgComponents/FlashListSvg.js";
import FetchSvg from "../../common/svgComponents/FetchSvg.js";
import VersionTab from "./RightbarTab/VersionTab.js";
import { getVersionList } from "../../redux/actions/defaultActions.js";
import TextFieldSvg from "../../common/svgComponents/TextFieldSvg.js";
import BottomSheetSvg from "../../common/svgComponents/BottomSheetSvg.js";
import PressableSvg from "../../common/svgComponents/PressableSvg.js";
import DatePickerSvg from "../../common/svgComponents/DatePickerSvg.js";
import TabBarsSvg from "../../common/svgComponents/TabBarsSvg.js";
import ButtonsSvg from "../../common/svgComponents/ButtonsSvg.js";

const RightSidebar = ({
  settingBar,
  handleChangeTab,
  openModal,
  screen,
  selectedFont,
  mergeNewArrayIntoFlashlist,
  DirectmergeNewArrayIntoFlashlist,
  handleWebPreview,
  handleDraftPreview,
  exportComponentAsJPEG,
  componentRef,
  setmydatas,
  setMySettings,
  setResponseValue,
  screenVersionList,

  showTooltipData,
  setShowTooltipData,
  runtheSaveFuntion,
  runfuntion2,
  setRunFuntion2,
}) => {
  const {
    defaultValues,
    folderStructure,
    selectedFolder,
    setSelectedFont,
    addToPublish,
    deleteMyVersion,
    screnOnlyData,
  } = useSelector((state) => state.default);
  const { flashlistKeyData, addFlashlistKey } = useSelector(
    (state) => state.flashlist
  );

  const [isDraftModeActive, setIsDraftModeActive] = useState(true);
  const [curntUrlType, setCurntUrlType] = useState("dev");
  const [versions, setVersions] = useState();

  const dispatch = useDispatch();
  const pageLoading = useSelector((state) => state?.auth?.pageLoading);

  const ids = localStorage.getItem("screen_Id");

  useEffect(() => {
    if (screenVersionList) {
      const data =
        screenVersionList?.versions?.[screenVersionList.versions.length - 1]
          ?._id;
      localStorage.setItem("crntVersion_id", data);
      setVersions(data);
    }
  }, [screenVersionList]);

  useEffect(() => {
    localStorage.setItem("urlType", "dev");

    if (ids) {
      const data = {
        p_id: localStorage.getItem("project_id"),
        s_id: ids,
      };

      dispatch(getVersionList(data));
    }
  }, [addToPublish, deleteMyVersion, ids]);

  const draftMode = () => {
    handleDraftPreview();
    setIsDraftModeActive(true);
  };

  const webMode = () => {
    handleWebPreview();
    setIsDraftModeActive(false);
  };

  const getIconForType = () => {
    switch (selectedFolder?.type) {
      case "image":
        return <ImageSvg width="20" height="20" change={true} />;
      case "text":
        return <TextSvg width="28" height="28" change={true} />;
      case "view":
        return <ViewSvg width="28" height="28" change={true} />;
      case "textField":
        return <TextFieldSvg width="28" height="28" change={true} />;
      case "fetch":
        return <FetchSvg width="28" height="28" change={true} />;
      case "flashlist":
        return <FlashListSvg width="28" height="28" change={true} />;
      case "bottomSheets":
        return <BottomSheetSvg width="28" height="28" change={true} />;
      case "pressable":
        return <PressableSvg width="28" height="28" change={true} />;
      case "datepicker":
        return <DatePickerSvg width="28" height="28" change={true} />;
      case "tabview":
        return <TabBarsSvg width="28" height="28" change={true} />;
      case "button":
        return <ButtonsSvg width="28" height="28" change={true} />;
      default:
        return null;
    }
  };

  const exportImage = () => {
    exportComponentAsJPEG(componentRef);
  };

  const setUrlType = (data) => {
    const currentUrlType = localStorage.getItem("urlType");
    if (currentUrlType !== data) {
      localStorage.setItem("urlType", data);
      setCurntUrlType(data);
    }
  };

  return (
    <>
      <div className="main-right">
        <div className="inner">
          <div className="preview-bar">
            <div className="top">
              <span>PREVIEW</span>
              <span>ENV</span>
              <span>
                <Tooltip
                  title="You can configure some values in your app to change based on which environment the app is running in. If unspecified, Development (Dev) values will be used."
                  arrow
                >
                  <Info />
                </Tooltip>
              </span>
            </div>
            <div className="bottom">
              <span
                className={`enviorment ${isDraftModeActive ? "active" : ""}`}
                onClick={draftMode}
              >
                <EditMode />
              </span>
              <span
                className={`enviorment ${!isDraftModeActive ? "active" : ""}`}
                onClick={webMode}
              >
                <LiveMode />
              </span>

              <span
                className={
                  curntUrlType === "dev" ? "enviormentActive" : "enviorment"
                }
                onClick={() => setUrlType("dev")}
              >
                Dev
              </span>
              <span
                className={
                  curntUrlType === "prod" ? "enviormentActive" : "enviorment"
                }
                onClick={() => setUrlType("prod")}
              >
                Prod
              </span>
              <span
                className="enviorment2"
                onClick={() => handleChangeTab("set-5")}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9"
                    stroke="#1A237E"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.75 8.2501L15.9 2.1001"
                    stroke="#1A237E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.4999 5.1225V1.5H12.8774"
                    stroke="#1A237E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              {/* {selectedFolder?.id ? (
                <button onClick={exportImage} className="export_button">
                  Export Screen as Image
                </button>
              ) : null} */}
            </div>
          </div>
          <div className="order-bar">
            <div className="inner">
              <span>
                {/* <PhoneIphone /> */}
                {getIconForType()}
              </span>
              <span>{selectedFolder?.name}</span>
            </div>
          </div>
          <div className="setting-bar">
            <div
              className={`${
                settingBar === "set-1"
                  ? "bg-hvrActive"
                  : selectedFolder?.type == "fetch" ||
                    selectedFolder?.customDataType === true
                  ? "bg-hvr"
                  : "bg-hvr"
              }`}
              style={{
                cursor:
                  selectedFolder?.type == "fetch" ||
                  selectedFolder?.customDataType === true
                    ? "not-allowed"
                    : "pointer",
              }}
              onClick={
                selectedFolder?.type == "fetch" ||
                selectedFolder?.customDataType === true
                  ? null
                  : () => handleChangeTab("set-1")
              }
            >
              <img src={brush_icon} alt="" className="icon_all" />
              <span className="firstTabText1"> Style</span>
            </div>

            <div
              className={`set-2  ${
                settingBar === "set-2"
                  ? "bg-hvrActive"
                  : selectedFolder?.type == "fetch" ||
                    selectedFolder?.customDataType === true
                  ? "bg-hvr"
                  : "bg-hvr"
              }`}
              style={{
                cursor:
                  selectedFolder?.type == "fetch" ||
                  selectedFolder?.customDataType === true
                    ? "not-allowed"
                    : "pointer",
              }}
              onClick={
                selectedFolder?.type == "fetch" ||
                selectedFolder?.customDataType === true
                  ? null
                  : () => handleChangeTab("set-2")
              }
            >
              <img src={painticon} alt="" className="icon_all" />
              <span className="firstTabText">Configs</span>
            </div>
            <div
              className={`set-3  ${
                settingBar === "set-3" ? "bg-hvrActive" : "bg-hvr"
              }`}
              onClick={() => handleChangeTab("set-3")}
            >
              <img src={database} alt="" className="icon_all" />
              <span className="firstTabText"> Data</span>
            </div>
            <div
              className={`set-4  ${
                settingBar === "set-4"
                  ? "bg-hvrActive"
                  : selectedFolder?.type !== "pressable" ||
                    selectedFolder?.type !== "datepicker" ||
                    selectedFolder?.customDataType === true
                  ? "bg-hvr"
                  : "bg-hvr"
              }`}
              style={{
                cursor:
                  selectedFolder?.type === "pressable" ||
                  selectedFolder?.type === "datepicker" ||
                  selectedFolder?.customDataType === true
                    ? "pointer"
                    : "not-allowed",
              }}
              onClick={
                selectedFolder?.type === "pressable" ||
                selectedFolder?.type === "datepicker" ||
                selectedFolder?.customDataType === true
                  ? () => handleChangeTab("set-4")
                  : null
              }
            >
              <img src={magic_icon} alt="" className="icon_all" />
              <span className="firstTabText"> Interactions</span>
            </div>
          </div>
          {settingBar === "set-1" && (
            <MainScroll
              screen={screen}
              selectedFont={selectedFont}
              setSelectedFont={setSelectedFont}
              selectedFolder={selectedFolder}
              showTooltipData={showTooltipData}
              setShowTooltipData={setShowTooltipData}
            />
          )}

          {settingBar === "set-2" && (
            <SecondTab
              showTooltipData={showTooltipData}
              setShowTooltipData={setShowTooltipData}
            />
          )}
          {settingBar === "set-3" && (
            <ThirdScroll
              defaultValues={defaultValues}
              openModal={openModal}
              selectedFolder={selectedFolder}
              folderStructure={folderStructure}
              mergeNewArrayIntoFlashlist={mergeNewArrayIntoFlashlist}
              DirectmergeNewArrayIntoFlashlist={
                DirectmergeNewArrayIntoFlashlist
              }
              setmydatas={setmydatas}
              addFlashlistKey={addFlashlistKey}
              flashlistKeyData={flashlistKeyData}
              setMySettings={setMySettings}
              setResponseValue={setResponseValue}
              curntUrlType={curntUrlType}
              showTooltipData={showTooltipData}
              setShowTooltipData={setShowTooltipData}
            />
          )}
          {settingBar === "set-4" && (
            <FourthTab
              selectedFolder={selectedFolder}
              folderStructure={folderStructure}
              showTooltipData={showTooltipData}
              setShowTooltipData={setShowTooltipData}
            />
          )}
          {settingBar === "set-5" && (
            <VersionTab
              screenVersionList={screenVersionList}
              versions={versions}
              setVersions={setVersions}
              lastData={screnOnlyData}
              folderStructure={folderStructure}
              runtheSaveFuntion={runtheSaveFuntion}
              runfuntion2={runfuntion2}
              setRunFuntion2={setRunFuntion2}
              pageLoading={pageLoading}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default RightSidebar;
