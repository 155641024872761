import React from "react";

const NotesBox = ({ noteText }) => {
  return (
    <>
      <div className="pt-4">
        {noteText === "Workspace" ? (
          <div className="box_container">
            <div className="box-comp">
              <span className="callout-icon">📘</span>
              <h4>
                Please Note: Please check your Figma Clone Plan for Eligibility
                and number of collaborators allowed to be invited.
              </h4>
            </div>
          </div>
        ) : noteText === "IconPage" ? (
          <div className="box_container">
            <div className="box-comp">
              <span className="callout-icon">📘</span>
              <h4>For Interactions, use Icon Button</h4>
            </div>
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              If you are looking to add Actions on an Icon to make it
              interactive, use the Icon Button component instead.
            </p>
          </div>
        ) : noteText === "Blockdiagram" ? (
          <div className="box_container">
            <div className="box-comp">
              <span className="callout-icon">📘</span>
              <p className="clone_paras" style={{ paddingLeft: "20px" }}>
                Sharing Blocks between different apps isn't possible yet, but we
                plan to add that ability in the future
              </p>
            </div>
          </div>
        ) : noteText === "TextCompo" ? (
          <div className="box_container">
            <div className="box-comp">
              <span className="callout-icon">📘</span>
              <p className="clone_paras" style={{ paddingLeft: "20px" }}>
                To customize styles of a Text component, check the Styles
                documentation.
              </p>
            </div>
          </div>
        ) : noteText === "PressableComp" ? (
          <div className="box_container">
            <div className="box-comp">
              <span className="callout-icon">📘</span>
              <p className="clone_paras" style={{ paddingLeft: "20px" }}>
                Here you will find instructions to can create your own button
                using a Pressable component provided by Figma Clone. A Pressable
                component provides the same capability as a Button to capture
                tapping gestures. However, it does not provide any default
                styling.
              </p>
            </div>
          </div>
        ) : noteText === "CommunityResources" ? (
          <div className="box_container">
            <div className="box-comp">
              <span className="callout-icon">📘</span>
              <h4>
                To use Figma Clone, you don't have to know React, React Native,
                or JavaScript
              </h4>
            </div>
            <div className="list-para">
              <p className="clone_paras" style={{ paddingLeft: "20px" }}>
                You can use Figma Clone to create your apps without having any
                knowledge about them.
              </p>
              <p className="clone_paras" style={{ paddingLeft: "20px" }}>
                With time, we are continuously invested in creating resources
                that are related to Figma Clone. You can find more of these
                resources at:
              </p>
              <ul>
                <li>Figma Clone's Community</li>
                <li>YouTube Channel</li>
              </ul>
            </div>
          </div>
        ) : noteText === "AlignItemsPage" ? (
          <div className="box_container">
            {/* <div className="box-comp">
              <span className="callout-icon">📘</span>
              <h4>For Interactions, use Icon Button</h4>
            </div> */}
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              For Stretch to have an effect, children must not have a fixed
              dimension along the secondary axis.
            </p>
          </div>
        ) : noteText === "ColorPage" ? (
          <div className="box_container">
            <div className="box-comp">
              <span className="callout-icon">📘</span>
              <h4>Hex and RGB colors</h4>
            </div>
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              Hex and RGB color values are allowed for color props.
            </p>
          </div>
        ) : noteText === "JsonDataPage" ? (
          <div className="box_container">
            <div className="box-comp">
              <span className="callout-icon">📘</span>
              <h4>JSON Data Type</h4>
            </div>
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              By default, Draftbit sets the Accept and Content-Type headers to
              application\json since Draftbit only supports JSON data.
            </p>
          </div>
        ) : noteText === "TestDefaultValuePage" ? (
          <div className="box_container">
            <div className="box-comp">
              <span className="callout-icon">📘</span>
              <h4>Test + Default Values</h4>
            </div>
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              These values will be set when testing your Endpoint before saving
              (see next section) and they will also be used as the default
              values when using the Endpoint with a Fetch component or API
              Requst Action.
            </p>
          </div>
        ) : noteText === "DevProd" ? (
          <div className="box_container">
            <div className="box-comp">
              <span className="callout-icon">📘</span>
              <h4>Dev & Prod URLS</h4>
            </div>
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              The Base URL parameter has been updated to give you the ability to
              set separate base URLs for Development and Production. When you
              publish your app, you can set which base URL to use, Dev or Prod.
              Read more about Environments
            </p>
          </div>
        ) : noteText === "JsonBody" ? (
          <div className="box_container">
            <div className="box-comp">
              <span className="callout-icon">📘</span>
              <h4>JSON Body Format</h4>
            </div>
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              Your API will likely have its own JSON structure for creating
              records in your backend.
            </p>
          </div>
        ) : noteText === "RoleAndText" ? (
          <div className="box_container">
            <div className="box-comp">
              <span className="callout-icon">📘</span>
              <h4>
                Importance of Role and Object Type when configuring an endpoint
              </h4>
            </div>
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              Using the Role and Object Type lets a Draftbit app cache and
              re-fetch data under the hood. Read more here.
            </p>
          </div>
        ) : noteText === "NoteText" ? (
          <div className="box_container">
            <div className="box-comp">
              <span className="callout-icon">📘</span>
              <h4>Note.</h4>
            </div>
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              Please ignore the Field Name prop in the attached screenshots and
              videos as this property does not exist anymore. We will update the
              screenshots and videos soon.{" "}
            </p>
          </div>
        ) : noteText === "CustomCodeWarning" ? (
          <div className="box_container_alert">
            <div className="box-comp">
              <span className="callout-icon">❗️ </span>
              <h4>
                Hiding Custom Code components might result in red screens, so be
                careful!
              </h4>
            </div>
          </div>
        ) : noteText === "Permanent" ? (
          <div className="box_container_alert">
            <div className="box-comp">
              <span className="callout-icon">❗️ </span>
              <h4>This is permanent</h4>
            </div>
            <p className="clone_paras">
              When you delete an API Service all associated Endpoints will also
              be deleted and any Fetch components and API Request Actions that
              reference the Endpoints will also be reset. This can't be undone!
            </p>
          </div>
        ) : noteText === "DeletingBlocks" ? (
          <div className="box_container_update">
            <div className="box-comp">
              <span class="callout-icon">⚠️</span>{" "}
              {/* <h4>
                Hiding Custom Code components might result in red screens, so be
                careful!
              </h4> */}
              <p className="clone_paras">
                When you make any changes to the Block and its child components
                all instances of that Block in any Screens or other Blocks in
                your app will be updated as well.
              </p>
            </div>
          </div>
        ) : noteText === "Deletinganddetaching" ? (
          <div className="box_container_update">
            <div className="box-comp">
              <span class="callout-icon">⚠️</span>{" "}
              {/* <h4>
                Hiding Custom Code components might result in red screens, so be
                careful!
              </h4> */}
              <p>
                If instances of a Block exist on a Screen or other Block, you'll
                need to remove those instances before you can delete a Block.
              </p>
            </div>
          </div>
        ) : noteText === "DetachingBlocks" ? (
          <div className="box_container_update">
            <div className="box-comp">
              <span class="callout-icon">⚠️</span>{" "}
              {/* <h4>
                Hiding Custom Code components might result in red screens, so be
                careful!
              </h4> */}
              <p className="clone_paras" style={{ paddingLeft: "20px" }}>
                Once you detach a Block instance, you can't reattach it or use
                it to update the original Block, but you can use it to create a
                new Block.
              </p>
            </div>
          </div>
        ) : noteText === "AndroidBlocks" ? (
          <div className="box_container_update">
            <div className="box-comp">
              <span class="callout-icon">⚠️</span>{" "}
              <h4>
                Note about using Truncate Text property on Android devices
              </h4>
            </div>
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              On Android, when Max # of Lines is set to a value higher than 1,
              only the tail from Truncate Text value will work correctly.
            </p>
          </div>
        ) : noteText === "WebBlocks" ? (
          <div className="box_container_update">
            <div className="box-comp">
              <span class="callout-icon">⚠️</span>{" "}
              <h4>Note about using Truncate Text property in Web Preview </h4>
            </div>
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              On Web, only the tail from Truncate Text value is supported.
            </p>
          </div>
        ) : noteText === "Negativemargins" ? (
          <div className="box_container_update">
            <div className="box-comp">
              <span class="callout-icon">⚠️</span>{" "}
              <h4>Note about using Truncate Text property in Web Preview </h4>
            </div>
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              Negative margins aren't supported on Android
            </p>
          </div>
        ) : noteText === "positions" ? (
          <div className="box_container_update">
            {/* <div className="box-comp">
              <span class="callout-icon">⚠️</span>{" "}
              <h4>Note about using Truncate Text property in Web Preview </h4>
            </div> */}
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              Usually you want to use Flex Layout to position components on your
              Screen so that your UI will adapt to different device screen
              sizes. The Position props are generally only used in special
              situations.
            </p>
          </div>
        ) : noteText === "HTTPPage" ? (
          <div className="box_container_update">
            <div className="box-comp">
              <span class="callout-icon">⚠️</span> <h4>HTTPS is required </h4>
            </div>
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              All API service Base URLs need to support SSL connections over
              HTTPS.
            </p>
          </div>
        ) : noteText === "LocalhostPage" ? (
          <div className="box_container_update">
            <div className="box-comp">
              <span class="callout-icon">⚠️</span>{" "}
              <h4>Localhost not supported </h4>
            </div>
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              API services need to be accessible over the internet. To connect
              Draftbit with your localhost, you'll need a secure tunnel that
              supports HTTPS. Expose is a handy service for this.
            </p>
          </div>
        ) : noteText === "ImportantPage" ? (
          <div className="box_container_update">
            {/* <div className="box-comp">
              <span class="callout-icon">⚠️</span>{" "}
              <h4>Localhost not supported </h4>
            </div> */}
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              When you choose OpenAPI as the Configuration Mode for your
              service, the Endpoints which are automatically created will only
              allow certain properties to be changed - such as Test/Default
              values. If you need to make changes to these Endpoints, it's
              recommended to either update your OpenAPI schema and then run
              Fetch Schema to get the latest changes synced to Draftbit OR
              switch the Endpoint's Configuration Mode to Manual.
            </p>
          </div>
        ) : noteText === "EndPointPage" ? (
          <div className="box_container_update">
            {/* <div className="box-comp">
              <span class="callout-icon">⚠️</span>{" "}
              <h4>Localhost not supported </h4>
            </div> */}
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              It's recommended to not use periods (".") in endpoint field names
            </p>
          </div>
        ) : noteText === "ObjectandRolePage" ? (
          <div className="box_container_update">
            {/* <div className="box-comp">
              <span class="callout-icon">⚠️</span>{" "}
              <h4>Localhost not supported </h4>
            </div> */}
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              You should only have one Object and Role combination (e.g., Get
              Many Posts) per API service.{" "}
            </p>
          </div>
        ) : noteText === "ObjectandRolePage" ? (
          <div className="box_container_update">
            {/* <div className="box-comp">
              <span class="callout-icon">⚠️</span>{" "}
              <h4>Localhost not supported </h4>
            </div> */}
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              You should only have one Object and Role combination (e.g., Get
              Many Posts) per API service.{" "}
            </p>
          </div>
        ) : noteText === "SkipPage" ? (
          <div className="box_container_update">
            <div className="box-comp">
              <span class="callout-icon">⚠️</span> <h4>Don't use Skip!</h4>
            </div>
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              Using Skip will let you use your Endpoint without testing, BUT you
              will not be shown a list of available fields for data returned by
              this Endpoint when using it in a Component or Action and you'll
              lose other benefits from having a data schema associated with your
              Endpoint.
            </p>
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              If for some reason you really need to skip, you should come back
              and Test then re-save the Endpoint as soon as possible.{" "}
            </p>
          </div>
        ) : noteText === "ConfigurationPage" ? (
          <div className="box_container_update">
            {/* <div className="box-comp">
              <span class="callout-icon">⚠️</span> <h4>Don't use Skip!</h4>
            </div> */}
            <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              If your Endpoint is automatically managed from an OpenAPI spec,
              only a limited set of props will be editable. To make changes,
              it's recommended to update and re-fetch your schema or switch the
              API service to Manual Configuration Mode
            </p>
            {/* <p className="clone_paras" style={{ paddingLeft: "20px" }}>
              If for some reason you really need to skip, you should come back
              and Test then re-save the Endpoint as soon as possible.{" "}
            </p> */}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default NotesBox;
