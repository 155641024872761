import React from "react";
import NotesBox from "../docPageNote/NotesBox";

const IntroToComponent = () => {
  return (
    <div>
      {/* Header Section */}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Intro to Components</h1>
      </div>
      <p className="clone_para">
        Components are used to build the user interface (UI) of your app. Each{" "}
        <a href="#"> Screen </a> and <a href="#"> Block</a> has its own set of
        components which comprise its UI. Draftbit comes with many different
        types of components that can be combined, customized, and styled to make
        your app uniquely yours, including layout containers, text and buttons,
        form inputs, media players, maps, swipers, and more!
      </p>
      <h2 id="component-tree" className="clone_head">
        Component Tree
      </h2>
      <p className="clone_para">
        The component tree is a list representation of the components that exist
        on a selected <a href="#"> Screen </a> or <a href="#"> Block</a> .
      </p>
      <p className="clone_para">
        Components added to Screens and Blocks are stacked vertically from top
        to bottom, just as they appear in the component tree. Components can
        also be reordered and nested using drag-and-drop in the component tree.
      </p>
      <p className="clone_para">
        <a href="#"> Flex Layout </a> can be used to further layout nested
        groups of components, inside a <a href="#"> View </a> for example, to
        build complex user interfaces.
      </p>

      {/* Adding Components Section */}
      <h2 id="adding-components" className="clone_head">
        Adding Components
      </h2>
      <p className="clone_para">
        From the <a href="#"> top section of the left panel </a> , select the{" "}
        <a href="#"> Screen </a> or <a href="#"> Block</a> that you want to add
        the component to.
      </p>
      <p className="clone_para">
        Click the + icon at the top of the <a href="#"> component tree </a> to
        open the component picker and view all the available components.
      </p>
      <p className="clone_para">
        Click on a component to add an instance of that component to the Screen
        or Block.
      </p>
      <p className="clone_para">
        The same process can be used to add a component as a child of another
        component which accepts child components, such as a{" "}
        <a href="#"> View </a> . Simply select the parent component instead of
        the Screen component in the component tree before picking a new
        component to add.
      </p>

      {/* Moving Components Section */}
      <h2 id="moving-components" className="clone_head">
        Moving Components
      </h2>
      <p className="clone_para">
        Drag-and-drop components within the component tree to reorder them on
        the Screen or within their parent component. The blue line displayed in
        the component tree indicates the location where the component will be
        dropped.
      </p>

      {/* Nesting Components Section */}
      <h2 id="nesting-components" className="clone_head">
        Nesting Components
      </h2>
      <p className="clone_para">
        Some components, such as a <a href="#"> View </a> , can contain one or
        more child components. Drag-and-drop components into parent components
        within the component tree to nest them. The component will be
        highlighted blue to indicate where the component will be dropped.
      </p>

      {/* Duplicating Components Section */}
      <h2 id="duplicating-components" className="clone_head">
        Duplicating Components
      </h2>
      <p className="clone_para">
        If you simply want to duplicate an existing component and its child
        components on the same <a href="#"> Screen </a> or{" "}
        <a href="#"> Block</a>, in the <a href="#"> component tree </a> hover
        over the component you'd like to duplicate, click the overflow menu
        (three dots) on the right, then select Duplicate.
      </p>
      <p className="clone_para">
        The duplicate will be added below the component which was duplicated in
        the component tree.
      </p>

      {/* Copy/Pasting Components Section */}
      <h2 id="copy-pasting-components" className="clone_head">
        Copy/Pasting Components
      </h2>
      <p className="clone_para">
        You can also copy components from one Screen or Block and paste them
        into another Screen or Block.
      </p>
      <p className="clone_para">
        In the <a href="#"> component tree </a> hover over the component you'd
        like to copy, click the overflow menu (three dots) on the right, and
        then click Mark For Copy.
      </p>
      <p className="clone_para">
        Once your component is copied, you can then paste it into another Screen
        or Block by selecting the parent component in the{" "}
        <a href="#"> component tree </a> where you'd like to place the copy,
        opening the overflow menu, and clicking Paste "[Name of Component]".
      </p>

      {/* Adding a Parent Component Section */}
      <h2 id="adding-a-parent-component" className="clone_head">
        Adding a Parent Component
      </h2>
      <p className="clone_para">
        You can wrap a component - including its children - in a parent
        component from the overflow menu (three dots) in the component tree.
        Just select Add Parent and pick a compatible component.
      </p>

      {/* Hiding Components Section */}
      <h2 id="hiding-components" className="clone_head">
        Hiding Components
      </h2>
      <p className="clone_para">
        To hide a component, click on the component's icon in the{" "}
        <a href="#"> component tree </a>.
      </p>
      <p className="clone_para">
        When a component is hidden, the component and its children will not be
        rendered on the Screen or included in the generated source code.
      </p>

      <NotesBox noteText="CustomCodeWarning" />

      {/* Deleting Components Section */}
      <h2 id="deleting-components" className="clone_head">
        Deleting Components
      </h2>
      <p className="clone_para">
        In the component tree you can click the overflow menu (three dots) for
        the component you'd like to delete and then click Delete.
      </p>

      {/* Restoring Components Section */}
      <h2 id="restoring-components" className="clone_head">
        Restoring Components
      </h2>
      <p className="clone_para">
        It's possible to restore deleted components on a Screen. You can view
        all the deleted components using the icon at the top of the component
        tree and restore them as needed.
      </p>

      {/* Converting to Blocks Section */}
      <h2 id="converting-to-blocks" className="clone_head">
        Converting to Blocks
      </h2>
      <p className="clone_para">
        Components can be converted into <a href="#"> Saved Blocks </a>so that
        you can reuse them throughout your app while only needing to make
        changes in one place when you want to make an update.
      </p>
      <p className="clone_para">
        Check out the <a href="#"> component tree Saved Blocks doc</a>for more
        details.
      </p>

      {/* Conditional Display Section */}
      <h2 id="conditional-display" className="clone_head">
        Conditional Display
      </h2>
      <p className="clone_para">
        You can conditionally display a component based on a given condition
        (e.g., a variable's value). Learn more about conditionally displaying
        components in the <a href="#"> Conditional Display </a> doc.
      </p>
    </div>
  );
};

export default IntroToComponent;
