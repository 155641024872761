import React from "react";

const StylingList = () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Intro to Styling</h1>
      </div>

      <p className="clone_para">
        There are many ways you can customize the look and feel of your app's
        user interface (UI) by controlling various styling properties that are
        available on components. Styling properties are broken down into
        different sections which you can explore on the pages listed below.
      </p>

      <p className="clone_para">
        Here's a video of a Screen being built from the ground up which
        demonstrates many of the styling properties and layout concepts.
      </p>

      <h2 className="clone_head">Sections</h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th scope="col">Styling Properties</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Stylesheets
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Dynamic Styling
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Conditional Display
              </a>
            </td>
          </tr>

          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Layout
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Typography
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Margins & Padding
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Borders, Background, and Effects
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Size & Position
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default StylingList;
