import React from "react";

export const ConditionDisplayPage = () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Conditional Display</h1>
      </div>

      <p className="clone_para">
        All of Draftbit's available components have Conditional Display Data
        props that let you pass in a value that evaluates to true or false
        (boolean). You can use the Conditional Display feature to hide or show a
        component when a certain condition is met. When a component is hidden,
        that component and all of its child (nested) components will not be
        rendered on the screen.
      </p>

      <p className="clone_para">
        Combining this with Variables or data from an API Endpoint can be a
        useful way to show the component in certain conditions. If the condition
        evaluates to a truthy value, the component will be shown.
      </p>

      <p className="clone_para">
        For example, you might have a List and want to have separate layouts for
        the rendered items based on a certain condition. In this case, you could
        create two different sets of components, and then conditionally display
        each parent component as needed. This could be using a value from the
        data associated with the List item, or a variable that you've set.
      </p>

      <h5 id="property-descriptions" className="clone_heading">
        Property Descriptions
      </h5>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Value</td>
            <td>The value to use as the condition.</td>
            <td>Boolean (true/false)</td>
          </tr>
          <tr>
            <td>Operator</td>
            <td>The operator to apply to the given value.</td>
            <td>Exists (is Truthy) or Doesn't exist (is Falsy)</td>
          </tr>
          <tr>
            <td>Exists (is Truthy)</td>
            <td>Will check whether the value provided evaluates to truthy.</td>
            <td>True if the condition is met.</td>
          </tr>
          <tr>
            <td>Doesn't exist (is Falsy)</td>
            <td>Will check whether the value provided evaluates to falsy.</td>
            <td>False if the condition is not met.</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
