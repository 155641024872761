import React from "react";
import NotesBox from "../../docPageNote/NotesBox";

export const TextPage = () => {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Text</h1>
      </div>
      <p className="clone_para">
        The Text component is used to display the text on the screen. It is one
        of the most frequently used components.
      </p>
      <h2 id="style-properties" className="clone_head">
        Style Properties
      </h2>
      <p className="clone_para">
        Style properties allow you to customize the look and feel of the
        component. Combinations of styles applied to components can be saved as
        Stylesheets to easily reuse styles throughout your app. Styles can also
        be set dynamically using Variables. To learn more about all the
        different styling properties and how they work, take a look at Intro to
        Styling.
      </p>
      <h5 id="component-specific-styles" className="clone_heading">
        Component Specific Styles
      </h5>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Selection Color</td>
            <td>
              Allows you to change the color of the highlight over selected text
              in the input.
            </td>
          </tr>
        </tbody>
      </table>
      <h2 id="configuration-properties" className="clone_head">
        Configuration Properties
      </h2>
      <h5 className="clone_heading">Basic</h5>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Component Name</td>
            <td>
              To alter the name of the component. The name is reflected in the
              Components tree. Defaults to Text.
            </td>
          </tr>
          <tr>
            <td>Max # of Lines</td>
            <td>
              Set the maximum number of lines to be displayed when Truncate Text
              is set.
            </td>
          </tr>
          <tr>
            <td>disabled</td>
            <td>
              Specifies the disabled state of the text view for testing
              purposes.
            </td>
          </tr>
          <tr>
            <td>Truncate Text</td>
            <td>
              Determine how the text will be truncated if the Max # of Lines
              property is set in conjunction.
            </td>
          </tr>
        </tbody>
      </table>
      <h5 className="clone_heading">Advanced</h5>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Max Font Size Multiplier</td>
            <td>
              Specifies largest possible scale a font can reach when
              allowFontScaling is enabled.
            </td>
          </tr>
          <tr>
            <td>Adjusts Font Size to Fit</td>
            <td>
              Specifies whether fonts should be scaled down automatically to fit
              given style constraints.
            </td>
          </tr>
          <tr>
            <td>Allow Font Scaling</td>
            <td>
              When enabled, font sizes in the input will scale to match with the
              text size the user has set in their accessibility settings.
            </td>
          </tr>
          <tr>
            <td>Text Break Strategy</td>
            <td>
              Allows you to set text break strategy on Android API Level 23+,
              possible values are 'simple', 'highQuality' and 'balanced'. The
              default value is 'highQuality'.
            </td>
          </tr>
          <tr>
            <td>Data Detector Type</td>
            <td>
              Allows you to determine the types of data converted to clickable
              URLs in the text element. By default no data types are detected.
              You can provide only one type. Possible values for
              dataDetectorType are: 'phoneNumber', 'link', 'email', 'none',
              'all'.
            </td>
          </tr>
          <tr>
            <td>Selectable</td>
            <td>
              Allows you to select text, to use the native copy and paste
              functionality.
            </td>
          </tr>
          <tr>
            <td>Suppress Highlighting</td>
            <td>
              Allows you to prevent any visual change when text is pressed down.
              By default, a gray oval highlights the text on press down.
            </td>
          </tr>
        </tbody>
      </table>
      <h2 id="data-properties" className="clone_head">
        Data Properties
      </h2>
      <h5 className="clone_heading">Input Text</h5>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Input Text</td>
            <td>
              The text to display in the component. This input supports static
              text or data & variables through the use of brackets, ie " ". For
              example, you could use "Hello" or "https://reddit.com/r/ ".
            </td>
          </tr>
        </tbody>
      </table>
      <h2 id="conditional-display" className="clone_head">
        Conditional Display
      </h2>
      <p className="clone_para">
        You can conditionally display a component based on a given condition.
        Learn more about conditionally displaying components in the Conditional
        Display doc.
      </p>
      <h2 id="using-text-component-for-dynamic-values" className="clone_head">
        Using Text Component for Dynamic Values
      </h2>
      <p className="clone_para">
        A Text component is a Data Enabled Component. This means that the
        property Input Text in the Data tab supports both static text or data
        and variables using double curly braces variable. In this scenario,
        usually, a Text component is nested under a component.
      </p>
      <li>
        All you have to do is define a variable in double curly braces name
        under Input Text property. For example, name .
      </li>
      <li>
        Then, under the Variables property, the variable name you've defined in
        the previous step will appear.
      </li>
      <li>
        Next to the variable name, there is a dropdown where you can select the
        appropriate key name whose value you want it to display on the screen.
      </li>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <NotesBox noteText="TextCompo" />{" "}
      <p className="clone_para">
        In this scenario, usually, a Text component is nested under a Fetch
        component. All you have to do is define a variable in double curly
        braces name under Input Text property. For example,. Then, under the
        Variables property, the variable name you've defined in the previous
        step will appear. Next to the variable name, there is a dropdown where
        you can select the appropriate key name whose value you want it to
        display on the screen.
      </p>
      <h2 id="inline-text-editing" className="clone_head">
        Inline Text Editing
      </h2>
      <p className="clone_para">
        Inline Text Editing allows you to edit a Text component's content by
        double-clicking it when in Draft View mode. After editing the
        component's content, press the Tab key to save the changes.
      </p>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <h2 id="truncating-text" className="clone_head">
        Truncating Text
      </h2>
      <p className="clone_para">
        Truncate Text property under Configs tab (second tab) in Properties
        Panel supports the following values:
      </p>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>head</td>
            <td>
              The line is displayed so that the end fits in the container, and
              the missing text at the beginning of the line is indicated by an
              ellipsis glyph. e.g., "...wxyz".
            </td>
          </tr>
          <tr>
            <td>middle</td>
            <td>
              The line is displayed so that the beginning and end fit in the
              container, and the missing text in the middle is indicated by an
              ellipsis glyph. "ab...yz".
            </td>
          </tr>
          <tr>
            <td>tail</td>
            <td>
              The line is displayed so that the beginning fits in the container,
              and the missing text at the end of the line is indicated by an
              ellipsis glyph. e.g., "abcd...". This is the default value.
            </td>
          </tr>
          <tr>
            <td>clip</td>
            <td>Lines are not drawn past the edge of the text container.</td>
          </tr>
        </tbody>
      </table>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <NotesBox noteText="AndroidBlocks" /> <NotesBox noteText="WebBlocks" />{" "}
    </div>
  );
};
