import React, { useEffect, useMemo, useRef, useState } from "react";
import Switch from "react-switch";
import Accordion from "react-bootstrap/Accordion";
import icon1 from "../../../assets/Icons/icon1.svg";
import iconconfiguration from "../../../assets/Icons/iconconfiguration.svg";
import info_icon from "../../../assets/Icons/info_icon.svg";
import reload_icon from "../../../assets/Icons/reload_icon.svg";
import EditorIcon from "../../../assets/Icons/EditorIcon.svg";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import ReactJson from "react-json-view";
import {
  setFolderStructure,
  setSelectedFolder,
} from "../../../redux/actions/defaultActions";
import {
  addFlashData,
  addFlashlistKeys,
  getEndpointsData,
  myApiData,
  myFlashData,
  myFlashListkeys,
  setMyMergeData,
  setReloadMergeApi,
} from "../../../redux/actions/flashListActions";
import UpdateMyValues from "../../../common/customHooks/UpdateMyValues";
import DateInputsandVariables from "./CommonRightbarTab/DateInputsandVariables";
import ButtonTextCompoenent from "./CommonRightbarTab/ButtonTextCompoenent";
import ToolTipBox from "../../../ToolTip/ToolTipBox";
import { Configuration } from "./CommonRightbarTab/Configuration";
import ModalEditor from "./FunctionComponent/ModalEditor";
import SvgComponent from "./CommonRightbarTab/SvgComponent";

const ThirdScroll = ({
  defaultValues,
  selectedFolder,
  folderStructure,
  mergeNewArrayIntoFlashlist,
  DirectmergeNewArrayIntoFlashlist,
  setmydatas,
  flashlistKeyData,
  addFlashlistKey,
  setMySettings,
  setResponseValue,
  curntUrlType,
  showTooltipData,
  setShowTooltipData,
}) => {
  const setSelectedFolderAction = (folder) =>
    dispatch(setSelectedFolder(folder));

  const setFolderStructureAction = (structure) =>
    dispatch(setFolderStructure(structure));

  const ReverseSwitch = () => {
    const [checked, setChecked] = useState(false);
    const handleChange = (newChecked) => {
      setChecked(newChecked);
    };

    return (
      <label>
        <Switch onChange={handleChange} checked={checked} />
      </label>
    );
  };

  const [selectedOption, setSelectedOption] = useState();
  const [selectedOperator, setSelectedOperator] = useState();
  const [selectedEndpoint, setSelectedEndpoint] = useState();
  const [selectedVariable, setSelectedVariable] = useState(null);
  const [selectedVariable2, setSelectedVariable2] = useState(null);
  const [selectedRole, setSelectedRole] = useState();
  const [variableNames2, setVariableNames2] = useState([]);
  const [variableNames3, setVariableNames3] = useState([]);
  const [flashlistRole, setFlashlistRole] = useState("data");
  const [flashlistResponse, setFlashlistResponse] = useState(null);
  const [addTypeVariables, setaddTypeVariables] = useState({});
  const [variableNames, setVariableNames] = useState();
  const [myInputValues, setMyInputValues] = useState();
  const [myInputValues2, setMyInputValues2] = useState();
  const [flashkeys, setFlashkeys] = useState();
  const [fetchKeys, setFetchKeys] = useState([]);
  const [settingBar, setSettingBar] = useState("set-3");
  const [myFetchOptions, setMyFetchOptions] = useState({});
  const [currentFlashListId, setCurrentFlashListId] = useState();
  const [updatedFlashList, setUpdatedFlashList] = useState([]);
  const [updateEndpointId, setUpdateEndpointId] = useState();
  const [reload, setReload] = useState(false);
  const [tooltipContent, setTooltipContent] = useState();
  const [open, setOpen] = useState(false);
  const [iconSelect, setIconSelect] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [labelValue, setLabelValue] = useState("");

  const dispatch = useDispatch();
  const {
    myJsonData,
    allFlashlist,
    getEndpoints,
    addFlashListData,
    getFlashData,
  } = useSelector((state) => state?.flashlist);

  const currentUrlType = localStorage?.getItem("urlType");

  const getMyMergeData = useSelector(
    (state) => state?.flashlist?.getMyMergeData
  );
  const reloadMergeApi = useSelector(
    (state) => state?.flashlist?.reloadMergeApi
  );

  const handleIconClick = (e, type) => {
    e.stopPropagation();

    if (type === tooltipContent) {
      setShowTooltipData(false);
      setTooltipContent();
    } else {
      setTooltipContent(type);
      setShowTooltipData(true);
    }
  };

  const updateValueRecursiveRES = (folders, folderId, property, newValue) => {
    return folders.map((folder) => {
      if (folder.id === folderId) {
        return { ...folder, [property]: newValue };
      } else if (folder.children && folder.children.length > 0) {
        const updatedChildren = updateValueRecursiveRES(
          folder.children,
          folderId,
          property,
          newValue
        );
        return { ...folder, children: updatedChildren };
      }
      return folder;
    });
  };

  useEffect(() => {
    if (flashlistResponse && selectedFolder?.type === "flashlist") {
      const updatedFamilyTree = updateValueRecursiveRES(
        folderStructure,
        selectedFolder.parentId,
        "mergeResponse",
        flashlistResponse
      );

      setTimeout(() => {
        setFolderStructureAction(updatedFamilyTree);
      }, 1200);
    }
  }, [flashlistResponse]);

  useEffect(() => {
    if (
      selectedFolder?.type === "fetch" &&
      selectedFolder?.flashlistDataId &&
      selectedFolder?.flashlistDataId !== "0"
    ) {
      setFlashkeys(selectedFolder?.flashlistDataId);
      const updatedFamilyTree = addFavoriteFood(
        folderStructure,
        selectedFolder?.id,
        selectedFolder?.flashlistDataId
      );

      setFolderStructureAction(updatedFamilyTree);
    } else if (selectedFolder?.currentFlastlistId) {
      setFlashkeys(selectedFolder?.currentFlastlistId);
    }
  }, [selectedFolder]);

  useEffect(() => {
    if (flashkeys) {
      dispatch(myFlashListkeys(flashkeys));
    }
  }, [flashkeys, addFlashlistKey]);

  useEffect(() => {
    if (selectedFolder?.crnt_res) {
      localStorage?.setItem("crnt_response", selectedFolder?.crnt_res);
    } else {
      localStorage?.setItem("crnt_response", selectedFolder?.response);
    }
  }, [selectedFolder]);

  useEffect(() => {
    if (selectedFolder && selectedFolder?.type === "image") {
      setSelectedRole(selectedFolder?.sourceType);
    }
  }, [selectedFolder]);

  useEffect(() => {
    if (
      selectedFolder &&
      selectedFolder?.type === "flashlist" &&
      selectedFolder?.response !== "response"
    ) {
      setFlashlistResponse(selectedFolder?.response);
    }
  }, [selectedFolder]);

  useEffect(() => {
    if (selectedFolder) {
      const { type, children, response } = selectedFolder;

      if (
        type === "flashlist" &&
        response !== "response" &&
        children?.length === 1
      ) {
        const datas = {
          value: selectedFolder?.response,
          label: selectedFolder?.response,
        };
        // flashlistResponseChange(datas);
      }
    }
  }, [selectedFolder?.children]);

  useEffect(() => {
    if (selectedFolder) {
      setMyInputValues(
        selectedFolder?.type === "text"
          ? selectedFolder?.canvas_text
          : selectedFolder?.image
      );
      setSelectedVariable2(selectedFolder?.lastSelectedKey);
      setSelectedVariable(selectedFolder?.lastSelectedKey);
    }
  }, [selectedFolder]);

  useEffect(() => {
    if (selectedFolder) {
      setMyInputValues2(
        selectedFolder?.type === "button" ? selectedFolder?.button_text : ""
      );
    }
  }, [selectedFolder]);

  useEffect(() => {
    if (selectedFolder) {
      setMyInputValues2(
        selectedFolder?.type === "button" ? selectedFolder?.icon : ""
      );
    }
  }, [selectedFolder]);

  useEffect(() => {
    if (selectedFolder) {
      const data = {
        value: selectedFolder?.services,
        label: selectedFolder?.services,
      };
      const data2 = {
        value: selectedFolder?.endpoint,
        label: selectedFolder?.endpoint,
      };
      setSelectedOperator(data);
      setSelectedOption(data2);
    }
  }, [selectedFolder]);

  const crntEndPoint_id = localStorage.getItem("endpoint_id");

  useEffect(() => {
    const projectId = localStorage.getItem("project_id");
    if (myJsonData) {
      if (
        selectedFolder?.type === "fetch" &&
        selectedFolder?.endpointId !== "0" &&
        crntEndPoint_id !== "null" &&
        selectedFolder?.endpointId !== crntEndPoint_id
      ) {
        dispatch(
          myFlashData(projectId, selectedFolder?.id, selectedFolder?.endpointId)
        );
      }
      dispatch(setReloadMergeApi(false));

      // else if (selectedFolder?.flashId) {
      //   dispatch(myFlashData(projectId, selectedFolder?.flashId));
      // }
    }
  }, [selectedFolder]);

  useEffect(() => {
    const projectId = localStorage.getItem("project_id");
    if (myJsonData) {
      if (
        selectedFolder?.type === "fetch" &&
        selectedFolder?.endpointId !== "0" &&
        crntEndPoint_id !== "null"
      ) {
        dispatch(
          myFlashData(projectId, selectedFolder?.id, selectedFolder?.endpointId)
        );
      }
    }
  }, [addFlashListData]);

  useEffect(() => {
    if (getEndpoints) {
      let optionsEndpoints = [];
      getEndpoints?.forEach((endpoint) => {
        endpoint?.Endpointdata?.forEach((data) => {
          optionsEndpoints.push({
            value: data.name,
            label: data.name,
            id: data._id,
            pathParams: data?.path_parameters?.auth,
            method: data?.method,
          });
        });
      });
      setSelectedEndpoint(optionsEndpoints);
    }
  }, [getEndpoints]);

  useEffect(() => {
    if (getFlashData && flashlistKeyData) {
      setVariableNames(flashlistKeyData?.flashlistkeysdata);
    }
  }, [getFlashData, flashlistKeyData]);

  const onVariableChange = (newValue, e) => {
    if (e?.name === "varImg") {
      setSelectedVariable(newValue);
      setMySettings(newValue.value);
      setmydatas(newValue);

      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "lastSelectedKey",
        newValue
      );
    }
    if (e?.name === "varText") {
      setSelectedVariable2(newValue);
      setMySettings(newValue.value);
      setmydatas(newValue);
      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "lastSelectedKey",
        newValue
      );
    }
  };
  const onLabelChange = (newValue) => {
    // if (e?.name === "varLabel") {
    // setSelectedVariable2(newValue);
    handleStyleUpdate(folderStructure, selectedFolder, "label", newValue);
    // }
  };
  const onDisabledChange = (data) => {
    // if (e?.name === "varLabel") {
    // setSelectedVariable2(newValue);
    // handleStyleUpdate(folderStructure, selectedFolder, "disabled", data);
    // }
  };

  useEffect(() => {
    if (myJsonData && selectedFolder?.type === "fetch") {
      const fetchId = selectedFolder?.type === "fetch" && selectedFolder?.id;
      const data = {
        project_id: localStorage.getItem("project_id"),
        flashlist_id: selectedFolder?.flashlistDataId,
        fetch_id: fetchId,
        flashData: { data: myJsonData },
        endpointid: selectedFolder?.endpointId,
      };

      dispatch(addFlashData(data));
      const allKeys = checkForKeys(myJsonData);

      setFetchKeys(allKeys);
    }
  }, [myJsonData]);

  const changeInputValueHandler = (newValue) => {
    setaddTypeVariables(newValue);
  };

  useEffect(() => {
    if (selectedOperator?.value) {
      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "services",
        selectedOperator?.value
      );
    }
  }, [selectedOperator?.value]);

  const addFavoriteFood = (folders, id, flashlistDataId) => {
    return folders.map((folder) => {
      if (folder.id === id) {
        return addFlashlistDataIdToDescendants({ ...folder });
      } else if (folder.children && folder.children.length > 0) {
        return {
          ...folder,
          children: addFavoriteFood(folder.children, id, flashlistDataId),
        };
      }
      return folder;
    });
  };

  const addFlashlistDataIdToDescendants = (folder) => {
    if (folder.children && folder.children.length > 0) {
      folder.children = folder.children.map((child) =>
        addFlashlistDataIdToDescendants({
          ...child,
          currentFlastlistId: selectedFolder?.flashlistDataId,
        })
      );
    }
    return folder;
  };

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModalEditor = () => {
    setModalIsOpen(false);
  };
  // const addFlashlistDataIdToDescendants = (folder) => {
  //   if (folder.children && folder.children.length > 0) {
  //     folder.children = folder.children.map((child) =>
  //       addFlashlistDataIdToDescendants({
  //         ...child,
  //         currentFlastlistId: selectedFolder?.flashlistDataId,
  //       })
  //     );
  //   }
  //   return folder;
  // };

  // const memoizedProjectId = useMemo(() => {
  //   if (
  //     myJsonData &&
  //     selectedFolder?.type === "fetch" &&
  //     selectedFolder?.endpointId !== "0"
  //   ) {
  //     return localStorage.getItem("project_id");
  //   }
  //   return null;
  // }, [myJsonData, selectedFolder, addFlashListData]);

  // useEffect(() => {
  //   if (memoizedProjectId) {
  //     dispatch(
  //       myFlashData(
  //         memoizedProjectId,
  //         selectedFolder?.id,
  //         selectedFolder?.endpointId
  //       )
  //     );
  //   }
  // }, [memoizedProjectId, selectedFolder]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (selectedFolder?.type === "text" || selectedFolder?.type === "image") {
        const data = {
          projectname: localStorage.getItem("project_name"),
          projectid: localStorage.getItem("project_id"),
          flashlistid: flashkeys,
          flashlistkeysdata: [
            {
              name: addTypeVariables,
            },
          ],
        };

        dispatch(addFlashlistKeys(data));
      }
    }
  };

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const apiCalledRef = useRef(false);

  useEffect(() => {
    if (
      selectedFolder?.type === "fetch" &&
      selectedFolder?.customDataType === true &&
      selectedFolder?.endpoint?.startsWith("/") &&
      selectedFolder?.services?.startsWith("http") &&
      selectedFolder?.endpointId !== 0 &&
      !apiCalledRef.current
    ) {
      apiCalledRef.current = true;
      dispatch(
        myApiData(
          selectedFolder?.services,
          selectedFolder?.endpoint,
          "get",
          apiCalledRef
        )
      );
      setTimeout(() => {
        apiCalledRef.current = false;
      }, 1000);
    }
  }, [selectedFolder]);

  function checkForKeys(myJsonData) {
    if (Array.isArray(myJsonData)) {
      const keysArray = myJsonData
        .map((obj) =>
          Object.keys(obj).map((key) => ({ key, type: typeof obj[key] }))
        )
        .flat();
      return keysArray.length > 0 ? keysArray : false;
    } else if (typeof myJsonData === "object" && myJsonData !== null) {
      const keys = Object.keys(myJsonData).map((key) => ({
        key,
        type: typeof myJsonData[key],
      }));
      return keys.length > 0 ? keys : false;
    } else {
      return false;
    }
  }

  // const endpointNames = getEndpoints.flatMap((endpoint) =>
  //   endpoint.Endpointdata.map((data) => data.name)
  // );

  // const optionsEndpoints = endpointNames.map((name) => ({
  //   value: `${name} (GET)`,
  //   label: `${name} (GET)`,
  // }));

  // const generateOptionsAndOnChange = () => {
  //   const optionsEndpoints = [];
  //   const handleChange = (selectedOption) => {
  //     setSelectedOption(selectedOption);
  //     // Perform desired actions with the selected value and additional data
  //     console.log("Selected value:", selectedOption);
  //     console.log(
  //       "Additional data - ID:",
  //       selectedOption.id,
  //       "Path Parameters:",
  //       selectedOption.pathParams
  //     );
  //   };

  //   // Generate options and populate options array
  //   getEndpoints.forEach((endpoint) => {
  //     endpoint.Endpointdata.forEach((data) => {
  //       optionsEndpoints.push({
  //         value: data.name,
  //         label: data.name,
  //         id: data._id,
  //         pathParams: data?.path_parameters?.auth,
  //       });
  //     });
  //   });

  //   return { optionsEndpoints, handleChange };
  // };

  const options =
    allFlashlist &&
    allFlashlist?.map((item) => ({
      value: item.name,
      label: item.name,
      id: item._id,
      url:
        localStorage?.getItem("urlType") === "dev"
          ? item.dev_url
          : item.prod_url,
    }));

  const optionIcon = Array.isArray(selectedFolder?.buttonIcon?.iconName)
    ? selectedFolder.buttonIcon.iconName?.map((icon) => ({
        label: icon,
        value: icon,
      }))
    : selectedFolder?.buttonIcon?.iconName
    ? [
        {
          label: selectedFolder.buttonIcon.iconName,
          value: selectedFolder.buttonIcon.iconName,
        },
      ]
    : [];

  const customStylesData = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#1a237e",
      borderRadius: "5px",
      width: "312px",
      border: "none",
      minHeight: "30px",
      fontSize: "14px",
      color: "#fff",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#1a237e",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "  rgb(88 103 138 / 56%)" : "#1a237e",
      color: isFocused ? "white" : "white",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "white",
    }),
    input: (styles) => ({
      ...styles,
      color: "#fff", // Text color set to white
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "white", // Customize the placeholder color
    }),
  };

  const selectboxFullwidth = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#1a237e",
      borderRadius: "5px",
      width: "100%",
      border: "none",
      minHeight: "30px",
      fontSize: "14px",
      color: "#fff",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#1a237e",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "  rgb(88 103 138 / 56%)" : "#1a237e",
      color: isFocused ? "white" : "white",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "white",
    }),
    input: (styles) => ({
      ...styles,
      color: "#fff", // Text color set to white
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "white", // Customize the placeholder color
    }),
  };

  const { handleStyleUpdate } = UpdateMyValues(
    setFolderStructureAction,
    setSelectedFolderAction
  );

  useEffect(() => {
    if (updateEndpointId) {
      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "endpointId",
        updateEndpointId
      );
    }
  }, [updateEndpointId]);

  const handleChange = (datas) => {
    const data = datas?.value;
    const url = selectedOperator?.value;
    const method = datas?.method;
    const id = datas?.id;
    localStorage.setItem("endpoint_id", id);

    setSelectedOption({
      ...selectedOperator,
      value: datas?.value,
      label: datas?.label,
    });
    setUpdateEndpointId(id);
    dispatch(myApiData(url, data, method));

    handleStyleUpdate(folderStructure, selectedFolder, "endpoint", data);
  };

  useEffect(() => {
    if (getFlashData) {
      dispatch(setMyMergeData(getFlashData));
    }
  }, [getFlashData]);

  useEffect(() => {
    if (allFlashlist) {
      const updateFlashList = allFlashlist?.map((item) => ({
        value: item.name,
        label: item.name,
        id: item._id,
        dat: curntUrlType,
        url: curntUrlType === "dev" ? item.dev_url : item.prod_url,
      }));

      setUpdatedFlashList(updateFlashList);
    }
  }, [allFlashlist, curntUrlType]);

  const flashlistOptions = [
    {
      value: "Top-Level-Response",
      label: "Top-Level-Response",
      type: "object",
    },
  ];

  useEffect(() => {
    if (fetchKeys) {
      const combinedOptions = [
        ...flashlistOptions,
        ...fetchKeys?.map((key) => ({
          value: key.key,
          label: key.key,
          type: key.type,
        })),
      ];

      setMyFetchOptions(combinedOptions);
    }
  }, [fetchKeys]);

  const reloadApiHandler = (e) => {
    e.stopPropagation();
    dispatch(
      myApiData(selectedFolder?.services, selectedFolder?.endpoint, "get")
    );
    setReload(true);
  };

  useEffect(() => {
    if (reload && reloadMergeApi && getFlashData) {
      if (selectedFolder?.mergeResponse === "Top-Level-Response") {
        DirectmergeNewArrayIntoFlashlist(
          folderStructure,
          getFlashData,
          selectedFolder?.id
        );
        dispatch(setMyMergeData(getFlashData));
        setReload(false);
      } else if (
        selectedFolder?.mergeResponse !== "Top-Level-Response" &&
        selectedFolder?.mergeResponse !== "response"
      ) {
        const value = selectedFolder?.mergeResponse;

        value &&
          DirectmergeNewArrayIntoFlashlist(
            folderStructure,
            getFlashData[value],
            selectedFolder?.id
          );
        dispatch(setMyMergeData(getFlashData[value]));
        setReload(false);
      }
    }
  }, [reload, reloadMergeApi, getFlashData]);

  const flashlistResponseChangeData = (data) => {
    setResponseValue(false);
    localStorage.removeItem("crnt_response");
    if (getFlashData) {
      const key = data?.value;
      if (data?.value === "Top-Level-Response") {
        mergeNewArrayIntoFlashlist(
          folderStructure,
          getFlashData,
          selectedFolder?.id
        );
        dispatch(setMyMergeData(getFlashData));
      } else if (
        data?.value !== "Top-Level-Response" &&
        data?.value !== "response"
      ) {
        mergeNewArrayIntoFlashlist(
          folderStructure,
          getFlashData[key],
          selectedFolder?.id
        );
        dispatch(setMyMergeData(getFlashData[key]));
      }
    }

    handleStyleUpdate(folderStructure, selectedFolder, "response", data?.value);
    setFlashlistResponse(data?.value);
  };

  // useEffect(() => {
  //   if (selectedFolder && selectedFolder.type === "image") {
  //     setSelectedVariable({ value: "url", label: "Url" });
  //   } else if (selectedFolder && selectedFolder.type === "text") {
  //     setSelectedVariable({ value: "name", label: "Name" });
  //   }
  // }, [selectedFolder]);

  // const variableNames = [

  // ];

  const handleChangeTab = (type) => {
    setSettingBar(type);
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#1a237e ",
      borderRadius: "5px",
      width: "240px",
      border: "none",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#1a237e ",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "  rgb(88 103 138 / 56%)" : "#1a237e ",
      color: isFocused ? "white" : "white",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "white",
    }),

    placeholder: (styles) => ({
      ...styles,
      color: "white", // Customize the placeholder color
    }),
  };

  const styleWidth = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#1a237e ",
      borderradius: "5px",
      width: "148px",
      height: " 36px",
      border: "none",
      color: "white",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#1a237e ",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "  rgb(88 103 138 / 56%)" : "#1a237e ",
      color: isFocused ? "white" : "white",
      cursor: "pointer",
    }),

    singleValue: (styles) => ({
      ...styles,
      color: "white",
    }),

    placeholder: (styles) => ({
      ...styles,
      color: "white", // Customize the placeholder color
    }),
  };

  // const onInputChange = (e, item, index) => {
  //   const newValue = e.target.value;

  //   const updatedItem = {
  //     ...selectedFolder,
  //     canvas_text: newValue,
  //   };

  //   const updatedSelectedFolder = {
  //     ...selectedFolder,
  //     canvas_text: newValue,
  //   };
  //   setSelectedFolderAction(updatedSelectedFolder);

  //   const updatedFolderStructure = folderStructure.map((folder) => {
  //     if (folder.children && folder.children.length > 0) {
  //       const updatedChildren = folder.children.map((child) => {
  //         if (child.id === selectedFolder.id) {
  //           return updatedItem;
  //         }
  //         return child;
  //       });
  //       return {
  //         ...folder,
  //         children: updatedChildren,
  //       };
  //     }
  //     return folder;
  //   });

  //   setFolderStructureAction(updatedFolderStructure);
  // };

  const onOperatorChangeHandler = (newValue) => {
    const { url, value } = newValue;

    // setSelectedOperator(newValue);
    setSelectedOperator({
      ...selectedOperator,
      value: url,
      label: value,
    });

    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "flashlistDataId",
      newValue?.id
    );

    setCurrentFlashListId(newValue?.id);

    dispatch(getEndpointsData(newValue?.id));
  };

  const customOptionRenderer = (option) => (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <span>{option?.label}</span>
      <span
        style={{
          marginLeft: "5px",
          backgroundColor: "gray",
          padding: "0px 5px",
          borderRadius: "4px",
          fontSize: "12px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {option?.type}
      </span>
    </div>
  );

  const changeRoleHandler = (e) => {
    setSelectedRole(e.target.value);
    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "sourceType",
      e.target.value
    );
  };

  const onInputChangeImage = (e, item, index) => {
    const newValue = e.target.value;
    const inputName = e.target.name;

    setMyInputValues(newValue);

    const keys = extractKeys(newValue);
    const options = keys.map((key) => ({ value: key, label: key }));
    setVariableNames2(options);

    handleStyleUpdate(folderStructure, selectedFolder, "image", newValue);
    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "variableName",
      options[0]?.value
    );
  };
  const onInputChange = (e, item, index) => {
    const newValue = e.target.value;
    const inputName = e.target.name;

    setMyInputValues(newValue);

    const keys = extractKeys(newValue);
    const options = keys.map((key) => ({ value: key, label: key }));

    setVariableNames3(options);

    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "variableName",
      options[0]?.value
    );

    handleStyleUpdate(folderStructure, selectedFolder, "canvas_text", newValue);
  };

  const iconClickHandler = (data) => {
    handleStyleUpdate(folderStructure, selectedFolder, "buttonIcon", data);
    setOpen(false);
  };

  const handleButtonTextChange = (event) => {
    const newButtonTextValue = event.target.value;
    setMyInputValues2(newButtonTextValue);

    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "button_text",
      newButtonTextValue
    );
  };
  const handleIconButton = (e) => {
    const newIconValue = e.target.value;
  };

  const extractKeys = (text) => {
    const regex = /{{(.*?)}}/g;
    let matches = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      const words = match[1].split(" "); // Split the matched text by spaces
      if (words?.length === 1) {
        // Check if it's a single word
        matches.push(words[0]); // Push the single word to matches
      }
    }
    return matches;
  };

  const onJsonOptionChangeHandler = (data) => {
    const values = JSON.parse(data);
    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "dropdownOptions",
      values
    );
  };

  console.log(selectedFolder);

  return (
    <>
      {/* <DateInputsandVariables
        radioType="radiobutton"
        buttonRowType="buttonRow"
      /> */}
      <div className="third-scroll">
        <div className="scrollable-inner">
          {selectedFolder?.type === "button" && (
            <ButtonTextCompoenent
              myInputValues2={myInputValues2}
              setMyInputValues2={setMyInputValues2}
              handleButtonTextChange={handleButtonTextChange}
              handleIconClick={handleIconClick}
              showTooltipData={showTooltipData}
              setTooltipContent={setTooltipContent}
              tooltipContent={tooltipContent}
              ToolTipBox={ToolTipBox}
            />
          )}
          {/* <DateInputsandVariables /> */}

          {selectedFolder?.type === "text" && (
            <div className="input-bar">
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Input Text</Accordion.Header>
                  <Accordion.Body>
                    <>
                      <textarea
                        className="input-area"
                        name="canvas_text"
                        rows="2"
                        cols={36}
                        placeholder="continue...."
                        value={myInputValues}
                        onChange={(e) => onInputChange(e)}
                      ></textarea>

                      <p>
                        This input supports static text or data & variables
                        through the use of brackets, ie . For example, you could
                        use "Hello" or "https://reddit.com/r/ "
                      </p>
                    </>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          )}

          {/* // Role Bar Sec  */}
          {/*  */}

          {selectedFolder && selectedFolder.type === "flashlist" ? (
            <div className="data-bar">
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Role</Accordion.Header>
                  <Accordion.Body>
                    <div className="main-content">
                      <div className="flex-select">
                        <h6>Role</h6>
                        <div className="select_con">
                          <select
                            name="select"
                            id="stylesheet"
                            onChange={(e) => setFlashlistRole(e.target.value)}
                          >
                            <option>data</option>
                            <option>loading</option>
                            <option>error</option>
                          </select>
                        </div>
                      </div>
                      <p>
                        This Component will be rendered when data has been
                        succesfullty returned from the endpoint
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          ) : null}

          {/* {selectedFolder && selectedFolder.type !== "fetch" ? (
            <div className="data-bar">
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Role</Accordion.Header>
                  <Accordion.Body>
                    <div className="main-content">
                      <div className="flex-select">
                        <h6>Role</h6>
                        <div className="select_con">
                          <select
                            name="select"
                            id="stylesheet"
                          >
                            <option>data</option>
                            <option>Static</option>
                            <option>Remote</option>
                          </select>
                        </div>
                      </div>
                      <p>
                        This Component will be rendered when data has been
                        succesfullty returned from the endpoint
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          ) : null} */}

          {/* // Setup Bar  */}

          {selectedFolder?.type === "image" && (
            <div className="data-bar setup-bar">
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Setup
                    <ToolTipBox
                      type="Setup"
                      showTooltipData={showTooltipData}
                      handleIconClick={handleIconClick}
                      tooltipContent={tooltipContent}
                    />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="main-flex">
                      <div className="first-box">
                        <div className="type-flex">
                          <img src={icon1} alt="" />
                          <p>Source Type</p>
                        </div>
                        <div className="slt-1">
                          <select
                            name="select"
                            id="stylesheet"
                            onChange={(e) => {
                              changeRoleHandler(e);
                            }}
                            value={selectedRole}
                          >
                            <option>Static</option>
                            <option>Remote</option>
                            <option>Data</option>
                          </select>
                        </div>
                      </div>

                      {selectedRole === "Static" && (
                        <>
                          <div className="sec-box">
                            <div className="d-flex gap-2">
                              <img src={icon1} alt="" />
                              <p>Source</p>
                            </div>

                            <div
                              className="img_source"
                              onClick={() => openModal("upImg")}
                            >
                              Upload Image
                            </div>
                          </div>

                          <div className="top-data">
                            <div className="d-flex gap-2">
                              <img src={icon1} alt="" />
                              <p>Data</p>
                            </div>
                            <Select
                              options={options}
                              name="colors"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              styles={colourStyles}
                            />
                          </div>

                          <div className="mid-data">
                            <p>Transform</p>
                            <Select
                              options={options}
                              name="colors"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              styles={colourStyles}
                            />
                          </div>

                          <div className="lower-data">
                            <div className="info-h5-flex">
                              <p>Item Unique Key</p>
                              <img src={info_icon} alt="" />
                            </div>
                            <Select
                              options={options}
                              name="colors"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              styles={styleWidth}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              {selectedRole === "Data" && (
                <div className="input-bar">
                  <Accordion flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Input Text</Accordion.Header>
                      <Accordion.Body>
                        <>
                          <textarea
                            className="input-area"
                            name="image"
                            rows="2"
                            cols={36}
                            placeholder=""
                            value={myInputValues}
                            onChange={(e) => onInputChangeImage(e)}
                          ></textarea>

                          <p className="paaofadat">
                            This input supports static text or data & variables
                            through the use of brackets, ie . For example, you
                            could use "Hello" or "https://reddit.com/r/ "
                          </p>
                        </>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}
            </div>
          )}

          {/* // Display Bar  */}
          {selectedFolder && selectedFolder.type === "fetch" ? (
            <div className="display-bar">
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Setup
                    <ToolTipBox
                      type="Setup"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="slt1">
                      <div className="d-flex ">
                        <img src={icon1} alt="" />
                        <p style={{ marginLeft: "5px" }}>Service</p>
                      </div>
                      <Select
                        value={selectedOperator}
                        options={updatedFlashList}
                        name="colors2"
                        // defaultValue={selectedOperator}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        styles={colourStyles}
                        onChange={onOperatorChangeHandler}
                      />
                    </div>

                    <div className="slt2">
                      <div className="d-flex ">
                        <img src={icon1} alt="" />
                        <p style={{ marginLeft: "5px" }}>Endpoint</p>
                      </div>
                      <Select
                        value={selectedOption}
                        onChange={handleChange}
                        options={
                          selectedFolder?.id &&
                          selectedEndpoint?.map((data) => ({
                            value: data.pathParams,
                            label: data.pathParams,
                            method: data?.method,
                            id: data?.id,
                          }))
                        }
                        name="endpoints"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        styles={colourStyles} // Assuming colourStyles is defined elsewhere
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          ) : null}

          {flashlistRole === "data" && selectedFolder?.type === "flashlist" ? (
            // <div className="display-bar">
            //   <Accordion flush>
            //     <Accordion.Item eventKey="0">
            //       <Accordion.Header>
            //         Setup
            //         <img src={info_icon} alt="" />
            //       </Accordion.Header>
            //       <Accordion.Body>
            //         <div className="slt1">
            //           <p>Data</p>
            //           <Select
            //             options={flashlistOptions}
            //             name="colors"
            //             className="basic-multi-select"
            //             classNamePrefix="select"
            //             styles={styleWidth}
            //             onChange={flashlistResponseChange}
            //             value={flashlistResponse}
            //           />
            //         </div>

            //         {/* <div className="slt2">
            //           <p>Value</p>
            //           <Select
            //             value={selectedOption}
            //             onChange={handleChange}
            //             options={optionsEndpoints}
            //             name="endpoints"
            //             className="basic-multi-select"
            //             classNamePrefix="select"
            //             styles={colourStyles} // Assuming colourStyles is defined elsewhere
            //           />
            //         </div> */}
            //       </Accordion.Body>
            //     </Accordion.Item>
            //   </Accordion>
            // </div>

            <div className="variable_bar">
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Setup
                    <ToolTipBox
                      type="Setup"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="main_variables">
                      <div className="inner_var">
                        <div className="var_title">
                          <span>Data</span>
                        </div>
                        <div className="var_selector">
                          <Select
                            options={myFetchOptions}
                            name="colors"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            styles={selectboxFullwidth}
                            onChange={flashlistResponseChangeData}
                            value={flashlistResponse?.value}
                            formatOptionLabel={customOptionRenderer}
                          />
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          ) : null}

          {/* // Configuration Bar  */}

          {selectedFolder && selectedFolder.type === "fetch" ? (
            <div className="Configuration-bar">
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Configuration
                    <ToolTipBox
                      type="Configuration"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    <h6>DATA REFETCHING</h6>
                    <div className="slt-para-flex">
                      <p className="paraslt">Poll Interval (ms)</p>
                      <Select
                        options={options}
                        name="colors"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        styles={styleWidth}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          ) : (
            <div className="Configuration-bar">
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Configuration
                    <ToolTipBox
                      type="Configuration"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    {/* <h6>icon</h6> */}
                    <div
                      className="slt-para-flex"
                      style={{ paddingBottom: "10px" }}
                    >
                      <p className="paraslt">
                        icon{" "}
                        {/* <ToolTipBox
                          type="Icon"
                          handleIconClick={handleIconClick}
                          showTooltipData={showTooltipData}
                          tooltipContent={tooltipContent}
                        />{" "} */}
                      </p>
                      <span
                        className="icon_config"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          gap: "10px",
                        }}
                      >
                        <Select
                          // options={optionIcon}
                          name={optionIcon}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          styles={styleWidth}
                          value={[
                            {
                              value: selectedFolder?.buttonIcon?.iconName,
                              label: selectedFolder?.buttonIcon?.iconName,
                            },
                          ]}
                        />
                        <img
                          src={iconconfiguration}
                          alt=""
                          width="5%"
                          onClick={onOpenModal}
                        />
                      </span>
                    </div>
                    <div
                      className="slt-para-flex"
                      style={{ paddingBottom: "10px" }}
                    >
                      <p className="paraslt">
                        Disabled{" "}
                        {/* <ToolTipBox
                          type="Disabled"
                          handleIconClick={handleIconClick}
                          showTooltipData={showTooltipData}
                          tooltipContent={tooltipContent}
                        /> */}
                      </p>
                      <span
                        className="icon_config"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          gap: "10px",
                        }}
                      >
                        <Select
                          // name={text}\
                          value={""}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          styles={styleWidth}
                        />
                        <SvgComponent onClick={openModal} />{" "}
                      </span>
                    </div>
                    <div className="slt-para-flex">
                      <p className="paraslt">
                        Loading{" "}
                        {/* <ToolTipBox
                          type="Loading"
                          handleIconClick={handleIconClick}
                          showTooltipData={showTooltipData}
                          tooltipContent={tooltipContent}
                        /> */}
                      </p>
                      <span
                        className="icon_config"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          gap: "10px",
                        }}
                      >
                        <Select
                          options={options}
                          name="colors"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          styles={styleWidth}
                        />
                        <img src={EditorIcon} alt="" width="5%" />
                      </span>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          )}

          {/* // Variable Bar  */}
          {selectedFolder.type === "view" ||
          selectedFolder.type === "fetch" ||
          selectedFolder.type === "flashlist" ? null : (
            // <div className="variable_bar">
            //   <Accordion flush>
            //     <Accordion.Item eventKey="0">
            //       <Accordion.Header>Variables</Accordion.Header>
            //       <Accordion.Body>
            //         <div className="main_variables">
            //           <div className="inner_var">
            //             <div className="var_title">
            //               <span>{selectedFolder.type}</span>
            //             </div>
            //             <div className="var_selector">
            //               <Select
            //                 options={variableNames}
            //                 name="colors"
            //                 value={selectedVariable}
            //                 onChange={setSelectedVariable}
            //                 className="basic-multi-select"
            //                 classNamePrefix="select"
            //                 styles={styleWidth}
            //               />
            //             </div>
            //           </div>
            //         </div>
            //       </Accordion.Body>
            //     </Accordion.Item>
            //   </Accordion>
            // </div>
            <>
              {variableNames2?.length > 0 &&
              selectedRole === "Data" &&
              selectedFolder?.type === "image" ? (
                <div className="variable_bar">
                  <Accordion flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Variables</Accordion.Header>
                      <Accordion.Body>
                        <div className="main_variables">
                          <div className="inner_var">
                            <div className="var_title">
                              {variableNames2?.map((variable) => (
                                <span key={variable.value}>
                                  {variable.label}
                                </span>
                              ))}
                              {/* <span>{variableNames2 ? variableNames2 : null}</span> */}
                            </div>
                            <div className="var_selector">
                              <Select
                                options={
                                  variableNames &&
                                  variableNames?.map((data) => ({
                                    value: data.name,
                                    label: data.name,
                                  }))
                                }
                                name="varImg"
                                value={selectedVariable}
                                onChange={onVariableChange}
                                onInputChange={changeInputValueHandler}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                styles={customStylesData}
                                onKeyDown={handleKeyDown}
                                defaultValue={selectedFolder?.lastSelectedKey}
                              />
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              ) : (
                selectedFolder?.type === "text" &&
                variableNames3?.length > 0 && (
                  <div className="variable_bar">
                    <Accordion flush>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Variables</Accordion.Header>
                        <Accordion.Body>
                          <div className="main_variables">
                            <div className="inner_var">
                              <div className="var_title">
                                {variableNames3?.map((variable) => (
                                  <span key={variable.value}>
                                    {variable.label}
                                  </span>
                                ))}
                                {/* <span>{variableNames2 ? variableNames2 : null}</span> */}
                              </div>
                              <div className="var_selector">
                                <Select
                                  options={
                                    variableNames &&
                                    variableNames?.map((data) => ({
                                      value: data.name,
                                      label: data.name,
                                    }))
                                  }
                                  name="varText"
                                  value={selectedVariable2}
                                  onChange={onVariableChange}
                                  onInputChange={changeInputValueHandler}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  styles={customStylesData}
                                  onKeyDown={handleKeyDown}
                                />
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                )
              )}
            </>
          )}

          {selectedFolder && selectedFolder.type === "fetch" ? (
            <div className="Preview-bar">
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Preview{" "}
                    <ToolTipBox
                      type="Preview"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />
                    <img
                      src={reload_icon}
                      alt=""
                      className="reload-mrg"
                      onClick={reloadApiHandler}
                    />
                  </Accordion.Header>
                  <Accordion.Body>
                    {/* {myJsonData > 0 ? (
                      <ReactJson src={myJsonData} theme="threezerotwofour" />
                    ) : (
                      <div className="bg_fetch_api">
                        <p>Preview Flashlist Data</p>
                      </div>
                    )} */}
                    {typeof getFlashData === "object" &&
                    Object.keys(getFlashData)?.length > 0 ? (
                      <ReactJson
                        src={getFlashData}
                        theme="threezerotwofour"
                        style={{ overflowX: "scroll", padding: "20px" }}
                      />
                    ) : (
                      <div className="bg_fetch_api">
                        <p>Preview Flashlist Data</p>
                      </div>
                    )}
                    {/* <img src={APIicon} alt="" /> */}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          ) : null}

          {(selectedFolder &&
            selectedFolder?.type === "datepicker" &&
            selectedFolder) ||
            (selectedFolder?.type === "dropdown" && (
              <DateInputsandVariables
                radioType="radiobutton"
                buttonRowType="buttonRow"
                handleIconClick={handleIconClick}
                showTooltipData={showTooltipData}
                setTooltipContent={setTooltipContent}
                tooltipContent={tooltipContent}
                ToolTipBox={ToolTipBox}
                setLabelValue={setLabelValue}
                labelValue={labelValue}
                onLabelChange={onLabelChange}
                onDisabledChange={onDisabledChange}
                selectedFolder={selectedFolder}
                folderStructure={folderStructure}
                onJsonOptionChangeHandler={onJsonOptionChangeHandler}
              />
            ))}
          {open && (
            <Configuration
              onCloseModal={onCloseModal}
              handleIconButton={handleIconButton}
              item={selectedFolder}
              iconClickHandler={iconClickHandler}
            />
          )}
          {/* 
          <ModalEditor
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
            openModal={openModal}
            closeModalEditor={closeModalEditor}
          /> */}

          {/* <div className="tlg-btn-tab-3">
            <ReverseSwitch />
            <p>Log action in this chain</p>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ThirdScroll;
