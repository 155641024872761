import React, { useState } from "react";
import Modal from "react-responsive-modal";
import IconsALL from "../TextAreaEditor/IconsALL";

export const Configuration = ({
  onCloseModal,
  handleIconButton,
  item,
  iconClickHandler,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const [currentType, setCurrentType] = useState("all");

  return (
    <div className="custom-modal">
      <div className="modal-overlay" onClick={onCloseModal}></div>
      <div className="modal-content">
        <button className="close-button" onClick={onCloseModal}>
          &times;
        </button>

        {/* Modal Body */}
        <div className="left-section">
          <ul className="icon-libraries">
            <li
              className={currentType == "all" && "active"}
              onClick={() => setCurrentType("all")}
            >
              All
            </li>
            <li
              className={currentType == "solid" && "active"}
              onClick={() => setCurrentType("solid")}
            >
              Solid
            </li>
            <li
              className={currentType == "regular" && "active"}
              onClick={() => setCurrentType("regular")}
            >
              Regular
            </li>
            <li
              className={currentType == "brands" && "active"}
              onClick={() => setCurrentType("brands")}
            >
              Brands
            </li>
            {/* <li>Feather</li>
            <li>Font Awesome</li>
            <li>Foundation</li>
            <li>Ionicons</li>
            <li>Material Icons</li>
            <li>Octicons</li>
            <li>Simple Line Icons</li>
            <li>Zocial</li> */}
          </ul>
        </div>

        <div className="right-section">
          <div className="search-bar">
            <h4>{currentType.toUpperCase()}</h4>
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <div className="icons-grid">
            {/* <div
              className="icon-item"
              title="arrow-bold-down"
              onClick={handleIconButton}
            >
              ⬇
            </div>
            <div
              className="icon-item"
              title="airplane"
              onClick={handleIconButton}
            >
              ✈
            </div>
            <div className="icon-item" title="apple" onClick={handleIconButton}>
              🍎
            </div>
            <div
              className="icon-item"
              title="clipboard"
              onClick={handleIconButton}
            >
              📋
            </div> */}
            <IconsALL
              currentType={currentType}
              searchQuery={searchQuery}
              iconClickHandler={iconClickHandler}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
