import React from "react";
import NotesBox from "../docPageNote/NotesBox";

const CommunityResources = () => {
  return (
    <div>
      {" "}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Community Resources</h1>
      </div>
      <div>
        <p className="clone_para">
          React and React Native community is evergrowing. Figma Clone is built
          on top of them and extensively shares concepts. There are many
          wonderful resources out there in the community.
        </p>
        <p className="clone_para">
          Below is a curated list of resources on various topics that are
          conceptually related to Figma Clone.
        </p>
        <NotesBox noteText="CommunityResources" />
        <h2 id="javaScript" className="clone_head">
          JavaScript
        </h2>
        <a href="#">
          <li style={{ paddingLeft: "15px" }}>
            JavaScript Notes and Reference - a beginner-friendly curated list of
            JavaScript fundamentals
          </li>
        </a>
        <a href="#">
          <li style={{ paddingLeft: "15px" }}>
            JavaScript MDN documentation is a good starting point to dive deep
            and use it as a reference
          </li>
        </a>
        <h2 id="react-and-react-native" className="clone_head">
          React and React Native{" "}
        </h2>
        <a href="#">
          <li style={{ paddingLeft: "15px" }}>
            What is JSX? React and React Native use this JavaScript extension to
            create elements
          </li>
        </a>
        <a href="#">
          {" "}
          <li style={{ paddingLeft: "15px" }}>Learn more about React Hooks:</li>
        </a>
        <a href="#">
          <li style={{ paddingLeft: "15px" }}>
            Reactjs documentation on Hooks
          </li>
        </a>
        &nbsp;
        <a href="#">
          <li style={{ paddingLeft: "15px" }}>
            React Native Express is a great resource to dive into the deep world
            of React Native
          </li>
        </a>
        &nbsp;
        <a href="#">
          <li style={{ paddingLeft: "15px" }}>
            Navigation: Learn about navigation patterns and how to implement
            them. Officially created by Expo team
          </li>
        </a>
        &nbsp;
        <a href="#">
          <li style={{ paddingLeft: "15px" }}>
            The Complete Guide to Layout in Figma Clone using React Native
            Flexbox
          </li>
        </a>
        <a href="#">
          <h2 id="data-and-apis" className="clone_head">
            Data and APIs
          </h2>
        </a>
        <a href="#">
          <li style={{ paddingLeft: "15px" }}>
            {" "}
            What Is a REST API? Examples, Uses, and Challenges
          </li>
        </a>
        <h2 id="payments" className="clone_head">
          Payments{" "}
        </h2>
        <a href="#">
          <li style={{ paddingLeft: "15px" }}>What are Stripe Payment Links</li>
        </a>
        <a href="#">
          <li style={{ paddingLeft: "30px" }}>
            {" "}
            How to integrate Stripe Payment Links in a Figma Clone app
          </li>
        </a>
      </div>
    </div>
  );
};

export default CommunityResources;
