// import React from "react";
// import * as Icons from "@mui/icons-material";

// const IconsALL = () => {
//   const iconNames = Object.keys(Icons).filter((icon) => icon !== "__esModule"); // Exclude metadata keys

//   return (
//     <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
//       {iconNames.map((iconName) => {
//         const IconComponent = Icons[iconName];
//         return (
//           <div key={iconName} style={{ textAlign: "center" }}>
//             <IconComponent style={{ fontSize: 30 }} />
//             {/* <p style={{ fontSize: "12px" }}>{iconName}</p> */}
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// export default IconsALL;

// import React from "react";
// import * as Icons from "@mui/icons-material";

// const IconsALL = () => {
//   // Categorize icons based on their names (you can customize the categories as needed)
//   const categories = {
//     Action: [],
//     Navigation: [],
//     Social: [],
//     Others: [],
//   };

//   // Loop through all icons and categorize them
//   Object.keys(Icons).forEach((iconName) => {
//     if (iconName === "__esModule") return; // Exclude metadata keys
//     if (iconName.includes("Action")) categories.Action.push(iconName);
//     else if (iconName.includes("Navigation")) categories.Navigation.push(iconName);
//     else if (iconName.includes("Social")) categories.Social.push(iconName);
//     else categories.Others.push(iconName);
//   });

//   // Handle icon click
//   const handleIconClick = (iconName) => {
//     alert(`You selected: ${iconName}`);
//     // Add additional logic to display or use the icon as needed
//   };

//   return (
//     <div>
//       {Object.entries(categories).map(([categoryName, icons]) => (
//         <div key={categoryName}>
//           <h2>{categoryName}</h2>
//           <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
//             {icons.map((iconName) => {
//               const IconComponent = Icons[iconName];
//               return (
//                 <div
//                   key={iconName}
//                   style={{ textAlign: "center", cursor: "pointer" }}
//                   onClick={() => handleIconClick(iconName)}
//                 >
//                   <IconComponent style={{ fontSize: 30 }} />
//                   <p style={{ fontSize: "12px" }}>{iconName}</p>
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default IconsALL;

// import React, { useState, useEffect } from "react";
// import * as Icons from "@mui/icons-material";

// const IconsALL = () => {
//   const iconNames = Object.keys(Icons).filter((icon) => icon !== "__esModule"); // Exclude metadata keys
//   const chunkSize = 200; // Number of icons to load per chunk
//   const [visibleIcons, setVisibleIcons] = useState(
//     iconNames.slice(0, chunkSize)
//   );

//   const loadMoreIcons = () => {
//     setVisibleIcons((prevIcons) => {
//       const nextIcons = iconNames.slice(
//         prevIcons.length,
//         prevIcons.length + chunkSize
//       );
//       return [...prevIcons, ...nextIcons];
//     });
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       if (
//         window.innerHeight + window.scrollY >=
//         document.documentElement.scrollHeight - 200
//       ) {
//         loadMoreIcons();
//       }
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, []);

//   return (
//     <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
//       {visibleIcons.map((iconName) => {
//         const IconComponent = Icons[iconName];
//         return (
//           <div key={iconName} style={{ textAlign: "center" }}>
//             <IconComponent style={{ fontSize: 30 }} />
//             {/* <p style={{ fontSize: "12px" }}>{iconName}</p> */}
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// export default IconsALL;

// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import * as SolidIcons from "@fortawesome/free-solid-svg-icons"; // Solid icons
// import * as RegularIcons from "@fortawesome/free-regular-svg-icons"; // Regular icons

// const IconsALL = () => {
//   const chunkSize = 300; // Number of icons to load per chunk
//   const [currentType, setCurrentType] = useState("solid"); // Current icon type
//   const [visibleIcons, setVisibleIcons] = useState([]);

//   const solidIcons = Object.keys(SolidIcons).filter((key) =>
//     key.startsWith("fa")
//   );
//   const regularIcons = Object.keys(RegularIcons).filter((key) =>
//     key.startsWith("fa")
//   );

//   const getIconsByType = (type) =>
//     type === "solid" ? solidIcons : regularIcons;

//   const loadMoreIcons = () => {
//     setVisibleIcons((prevIcons) => {
//       const icons = getIconsByType(currentType);
//       const nextIcons = icons.slice(
//         prevIcons.length,
//         prevIcons.length + chunkSize
//       );
//       return [...prevIcons, ...nextIcons];
//     });
//   };

//   useEffect(() => {
//     // Reset visible icons when the type changes
//     setVisibleIcons(getIconsByType(currentType).slice(0, chunkSize));
//   }, [currentType]);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (
//         window.innerHeight + window.scrollY >=
//         document.documentElement.scrollHeight - 500
//       ) {
//         loadMoreIcons();
//       }
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, [currentType]); // Re-attach scroll listener when type changes

//   return (
//     <div>
//       {/* Toggle Buttons */}
//       <div style={{ marginBottom: "20px", textAlign: "center" }}>
//         <button
//           onClick={() => setCurrentType("solid")}
//           style={{
//             padding: "10px 20px",
//             marginRight: "10px",
//             backgroundColor: currentType === "solid" ? "#007bff" : "#e0e0e0",
//             color: currentType === "solid" ? "#fff" : "#000",
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//           }}
//         >
//           Solid Icons
//         </button>
//         <button
//           onClick={() => setCurrentType("regular")}
//           style={{
//             padding: "10px 20px",
//             backgroundColor: currentType === "regular" ? "#007bff" : "#e0e0e0",
//             color: currentType === "regular" ? "#fff" : "#000",
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//           }}
//         >
//           Regular Icons
//         </button>
//       </div>

//       {/* Icon Grid */}
//       <div
//         style={{
//           display: "flex",
//           flexWrap: "wrap",
//           gap: "10px",
//           height: "250px",
//         }}
//       >
//         {visibleIcons.map((iconName) => {
//           const IconComponent =
//             currentType === "solid"
//               ? SolidIcons[iconName]
//               : RegularIcons[iconName];
//           return (
//             <div key={iconName} style={{ textAlign: "center" }}>
//               <FontAwesomeIcon icon={IconComponent} style={{ fontSize: 30 }} />
//               {/* <p style={{ fontSize: "12px" }}>{iconName}</p> */}
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export default IconsALL;

// import React, { useState, useEffect, useRef } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import * as SolidIcons from "@fortawesome/free-solid-svg-icons";
// import * as RegularIcons from "@fortawesome/free-regular-svg-icons";
// import * as BrandsIcons from "@fortawesome/free-brands-svg-icons";

// const IconsALL = () => {
//   const chunkSize = 200; // Number of icons to load per chunk
//   const [currentType, setCurrentType] = useState("solid");
//   const [visibleIcons, setVisibleIcons] = useState([]);
//   const scrollableDivRef = useRef(null); // Reference for the scrollable div

//   const solidIcons = Object.keys(SolidIcons).filter((key) =>
//     key.startsWith("fa")
//   );
//   const regularIcons = Object.keys(RegularIcons).filter((key) =>
//     key.startsWith("fa")
//   );
//   const brandsIcons = Object.keys(BrandsIcons).filter((key) =>
//     key.startsWith("fa")
//   );

//   const getIconsByType = (type) =>
//     type === "solid"
//       ? solidIcons
//       : type === "regular"
//       ? regularIcons
//       : brandsIcons;

//   const loadMoreIcons = () => {
//     setVisibleIcons((prevIcons) => {
//       const icons = getIconsByType(currentType);
//       const nextIcons = icons.slice(
//         prevIcons.length,
//         prevIcons.length + chunkSize
//       );
//       return [...prevIcons, ...nextIcons];
//     });
//   };

//   useEffect(() => {
//     // Reset visible icons when type changes
//     setVisibleIcons(getIconsByType(currentType).slice(0, chunkSize));
//   }, [currentType]);

//   useEffect(() => {
//     const handleScroll = () => {
//       const div = scrollableDivRef.current;
//       if (div && div.scrollTop + div.clientHeight >= div.scrollHeight - 100) {
//         loadMoreIcons();
//       }
//     };

//     const div = scrollableDivRef.current;
//     if (div) {
//       div.addEventListener("scroll", handleScroll);
//     }

//     return () => {
//       if (div) {
//         div.removeEventListener("scroll", handleScroll);
//       }
//     };
//   }, [currentType]); // Re-attach scroll listener when type changes

//   return (
//     <div>
//       {/* Toggle Buttons */}
//       <div style={{ marginBottom: "20px", textAlign: "center" }}>
//         <button
//           onClick={() => setCurrentType("solid")}
//           style={{
//             padding: "10px 20px",
//             marginRight: "10px",
//             backgroundColor: currentType === "solid" ? "#007bff" : "#e0e0e0",
//             color: currentType === "solid" ? "#fff" : "#000",
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//           }}
//         >
//           Solid Icons
//         </button>
//         <button
//           onClick={() => setCurrentType("regular")}
//           style={{
//             padding: "10px 20px",
//             backgroundColor: currentType === "regular" ? "#007bff" : "#e0e0e0",
//             color: currentType === "regular" ? "#fff" : "#000",
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//           }}
//         >
//           Regular Icons
//         </button>
//         <button
//           onClick={() => setCurrentType("brands")}
//           style={{
//             padding: "10px 20px",
//             backgroundColor: currentType === "regular" ? "#007bff" : "#e0e0e0",
//             color: currentType === "regular" ? "#fff" : "#000",
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//           }}
//         >
//           Brands Icons
//         </button>
//       </div>

//       {/* Scrollable Icon Container */}
//       <div
//         ref={scrollableDivRef}
//         style={{
//           height: "200px", // Set a fixed height for the scrollable container
//           overflowY: "auto", // Enable vertical scrolling
//           border: "1px solid #ddd",
//           padding: "10px",
//         }}
//       >
//         <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
//           {visibleIcons.map((iconName) => {
//             const IconComponent =
//               currentType === "solid"
//                 ? SolidIcons[iconName]
//                 : currentType === "regular"
//                 ? RegularIcons[iconName]
//                 : BrandsIcons[iconName];
//             return (
//               <div key={iconName} style={{ textAlign: "center" }}>
//                 <FontAwesomeIcon
//                   icon={IconComponent}
//                   style={{ fontSize: 30 }}
//                 />
//                 {/* <p style={{ fontSize: "12px" }}>{iconName}</p> */}
//               </div>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default IconsALL;

import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as SolidIcons from "@fortawesome/free-solid-svg-icons";
import * as RegularIcons from "@fortawesome/free-regular-svg-icons";
import * as BrandsIcons from "@fortawesome/free-brands-svg-icons";

const IconsALL = ({ currentType, iconClickHandler, searchQuery }) => {
  const chunkSize = 200; // Number of icons to load per chunk

  const [visibleIcons, setVisibleIcons] = useState([]);
  const scrollableDivRef = useRef(null); // Reference for the scrollable div

  const solidIcons = Object.keys(SolidIcons).filter((key) =>
    key.startsWith("fa")
  );
  const regularIcons = Object.keys(RegularIcons).filter((key) =>
    key.startsWith("fa")
  );
  const brandsIcons = Object.keys(BrandsIcons).filter((key) =>
    key.startsWith("fa")
  );

  const getIconsByType = (type) => {
    if (type === "solid") return solidIcons;
    if (type === "regular") return regularIcons;
    if (type === "brands") return brandsIcons;
    return [...solidIcons, ...regularIcons, ...brandsIcons]; // Show all icons
  };

  const loadMoreIcons = () => {
    setVisibleIcons((prevIcons) => {
      const icons = getIconsByType(currentType);
      const nextIcons = icons.slice(
        prevIcons.length,
        prevIcons.length + chunkSize
      );
      return [...prevIcons, ...nextIcons];
    });
  };

  useEffect(() => {
    if (searchQuery) {
      const filteredIcons = visibleIcons?.filter((iconName) =>
        iconName.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setVisibleIcons(filteredIcons);
    } else {
      setVisibleIcons(getIconsByType(currentType).slice(0, chunkSize));
    }
  }, [currentType, searchQuery]);

  useEffect(() => {
    const handleScroll = () => {
      const div = scrollableDivRef.current;
      if (div && div.scrollTop + div.clientHeight >= div.scrollHeight - 100) {
        loadMoreIcons();
      }
    };

    const div = scrollableDivRef.current;
    if (div) {
      div.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener("scroll", handleScroll);
      }
    };
  }, [currentType]);

  return (
    <div>
      {/* Toggle Buttons */}

      {/* <div style={{ marginBottom: "20px", textAlign: "center" }}>
        <button
          onClick={() => setCurrentType("all")}
          style={{
            padding: "10px 20px",
            marginRight: "10px",
            backgroundColor: currentType === "all" ? "#007bff" : "#e0e0e0",
            color: currentType === "all" ? "#fff" : "#000",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          All
        </button>
        <button
          onClick={() => setCurrentType("solid")}
          style={{
            padding: "10px 20px",
            marginRight: "10px",
            backgroundColor: currentType === "solid" ? "#007bff" : "#e0e0e0",
            color: currentType === "solid" ? "#fff" : "#000",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Solid Icons
        </button>
        <button
          onClick={() => setCurrentType("regular")}
          style={{
            padding: "10px 20px",
            backgroundColor: currentType === "regular" ? "#007bff" : "#e0e0e0",
            color: currentType === "regular" ? "#fff" : "#000",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Regular Icons
        </button>
        <button
          onClick={() => setCurrentType("brands")}
          style={{
            padding: "10px 20px",
            backgroundColor: currentType === "brands" ? "#007bff" : "#e0e0e0",
            color: currentType === "brands" ? "#fff" : "#000",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Brands Icons
        </button>
      </div> */}

      {/* Scrollable Icon Container */}
      <div
        ref={scrollableDivRef}
        style={{
          height: "460px", // Set a fixed height for the scrollable container
          width: "590px", // Set a fixed height for the scrollable container
          overflowY: "auto", // Enable vertical scrolling
          border: "1px solid #ddd",
          padding: "10px",
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          {visibleIcons?.map((iconName) => {
            const IconComponent =
              currentType === "solid"
                ? SolidIcons[iconName]
                : currentType === "regular"
                ? RegularIcons[iconName]
                : currentType === "brands"
                ? BrandsIcons[iconName]
                : SolidIcons[iconName];
            return (
              <div
                key={iconName}
                style={{ textAlign: "center" }}
                onClick={() => iconClickHandler(IconComponent)}
                className="icon-item"
              >
                <FontAwesomeIcon
                  icon={IconComponent}
                  style={{ fontSize: 30 }}
                />
                {/* <p>{iconName}</p> */}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default IconsALL;
