import React from "react";
import NotesBox from "../../docPageNote/NotesBox";
import TextField from "../../../assets/Image/TextField.png";
import Pressable from "../../../assets/Image/Pressable.png";
import ButtonSheet from "../../../assets/Image/ButtonSheet.png";
import ButtonBasic from "../../../assets/Image/ButtonBasic.png";
export const SubmittingData = () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Submitting Data</h1>
      </div>
      <p className="clone_para">
        In addition to fetching data, it is also common to submit data to an
        API. Draftbit lets you make API requests using the API Request Action to
        submit data (i.e., make an HTTP POST, PUT, or PATCH request) to an API
        endpoint.
      </p>
      <h2 id="configure-endpoint-submit" className="clone_head">
        Configure the Endpoint to Submit New Data
      </h2>
      <p className="clone_para">
        To create a new data record in the database of your choice, you will
        have to create an endpoint that submits a POST request. For an example,
        consider a REST API endpoint which accepts a JSON body in the request to
        create new todos structured like this:
      </p>
      <pre>
        <code>
          {`{
    "records": [
        {
            "fields": {
                "item": "Notebook"
            }
        },
        {
            "fields": {
                "item": "Apples"
            }
        },
        {
            "fields": {
                "item": "Bananas"
            }
        }
    ]
}`}
        </code>
      </pre>
      <NotesBox noteText="JsonBody" />{" "}
      <p className="clone_para">
        Your API will likely have its own JSON structure for creating records in
        your backend.
      </p>
      <p className="clone_para">
        Let's consider the above endpoint example to add a new todo to an
        existing Airtable base. You will add a new endpoint for the POST HTTP
        method by following these steps:
      </p>
      <ol>
        <li>
          Click <strong>Add endpoint</strong>.
        </li>
        <li>
          In Step 1: enter the Name for the endpoint. Make sure the Method
          selected is POST.
        </li>
        <li>
          Set the Role to <strong>Create</strong> since the endpoint is creating
          a new record. Set the Object Type to <strong>todos</strong>.
        </li>
        <li>
          In Step 2: add the base name path and/or any query parameters to the
          API's base URL. Currently, the endpoint has no query parameters or
          additional endpoint-specific paths.
        </li>
        <li>
          In Step 3: add a valid Body structure to submit a POST request. Add
          one or many <code>{"variable"}</code> for test values. Click{" "}
          <strong>Body Preview</strong> to validate the structure of the Body in
          the request. Using the example above, it would look like this:
        </li>
      </ol>
      <pre>
        <code>
          {`{
    "records": [
        {
            "fields": {
                "item": {{ todo }}
            }
        }
    ]
}`}
        </code>
      </pre>
      <ol start={6}>
        <li>
          In Step 5: click the <strong>Test</strong> button next to the Endpoint
          input to verify the response coming from the API service.
        </li>
        <li>
          The last step is to save the endpoint. Click the <strong>Save</strong>{" "}
          button.
        </li>
      </ol>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <h2 id="trigger-api-request" className="clone_head">
        Trigger an API Request Action to Submit a New Item
      </h2>
      <p className="clone_para">
        Once your components, API Service, and Endpoint are set up correctly,
        select the Button or Touchable component in the Components tree and go
        to the Interactions tab in the Properties panel (last tab on the
        right-hand side).
      </p>
      <ol>
        <li>
          Click the <strong>+</strong> button next to Action.
        </li>
        <li>
          From the dropdown, select the action <strong>API Request</strong>.
        </li>

        <span style={{ display: "flex", justifyContent: "center" }}>
          <img src={TextField} alt="" width="100%" />
        </span>
        <li>Select the Service name and Endpoint name from the dropdowns.</li>
        <li>
          After you've made your selections, a Configuration section will appear
          and there will be an input for each key/value pair you defined in your
          Body Structure. The dropdown next to each variable will be
          pre-populated with all the Data Source props you have set on the
          screen. Select the corresponding value from the dropdown that matches
          your variable.
        </li>
        <span style={{ display: "flex", justifyContent: "center" }}>
          <img src={Pressable} alt="" width="100%" />
        </span>
      </ol>
      <p className="clone_para">
        The <code>textInputValue</code> passed in the above example is the value
        of the Data Source prop on the TextInput component. To find it, go to
        the component and from the Properties panel, go to the Configs tab:
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={TextField} alt="" width="100%" />
      </span>
      <NotesBox noteText="RoleAndText" /> <NotesBox noteText="NoteText" />{" "}
      <p className="clone_para">
        Using the Role and Object Type lets a Draftbit app cache and re-fetch
        data under the hood. This is crucial for optimizing performance and
        ensuring that your app remains responsive.
      </p>
      <p className="clone_para">
        Please ignore the Field Name prop in the attached screenshots and videos
        as this property does not exist anymore. We will update the screenshots
        and videos soon.
      </p>
      <h2 id="configure-endpoint-update" className="clone_head">
        Configure the Endpoint to Update Existing Data
      </h2>
      <p className="clone_para">
        To update an existing data record in the database of your choice, you
        will have to create an endpoint that submits a PUT or PATCH request. For
        example, let's consider the same endpoint example as done in the
        previous section when submitting new data. This endpoint will also
        accept a JSON body in the request to submit new data from a Draftbit
        app.
      </p>
      <p className="clone_para">
        You will usually add a new endpoint for the PUT or PATCH HTTP method by
        following these steps:
      </p>
      <ol>
        <li>
          Click <strong>Add endpoint</strong>.
        </li>
        <li>
          In Step 1: enter the Name for the endpoint. Make sure the Method
          selected is PATCH.
        </li>
        <li>
          Set the Role to <strong>Update</strong> since the endpoint is creating
          a new record. Set the Object Type to <strong>todos</strong>.
        </li>
        <li>
          In Step 2: add the base name path and/or any query parameters to the
          API's base URL. Currently, the endpoint has no query parameters or
          additional endpoint-specific paths.
        </li>
        <li>
          In Step 3: add a valid Body structure to submit a PATCH request. Add
          one or many <code>{"variable"}</code> for test values. Click{" "}
          <strong>Body Preview</strong> to validate the structure of the Body in
          the request.
        </li>
        <li>
          Check back in your API documentation to get an idea of how your
          request's JSON body is structured when updating an existing record.
          Then, add the Body Structure in key-value pairs as shown below.
        </li>
        <li>
          In Step 5: click the <strong>Test</strong> button next to the Endpoint
          input to verify the response coming from the API service.
        </li>
        <li>
          The last step is to save the endpoint. Click the <strong>Save</strong>{" "}
          button.
        </li>
      </ol>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <h2 id="trigger-api-request-update" className="clone_head">
        Trigger an API Request Action to Update an Existing Item
      </h2>
      <p className="clone_para">
        To update an existing item, it will be done on a Details screen. In the
        example app, it was created when configuring an endpoint to fetch a
        single item record.
      </p>
      <p className="clone_para">
        Once your components, API Service, and Endpoint are set up correctly,
        select the Button or Touchable component in the Components tree and go
        to the Interactions tab in the Properties panel (last tab on the
        right-hand side).
      </p>
      <ol>
        <li>
          Click the <strong>+</strong> button next to Action.
        </li>
        <li>
          From the dropdown, select the action <strong>API Request</strong>.
        </li>
        <li>Select the Service name and Endpoint name from the dropdowns.</li>
        <li>
          After you've made your selections, a Configuration section will appear
          and there will be an input for each key/value pair you defined in your
          Body Structure. The dropdown next to each variable will be
          pre-populated with all the Data Source props you have set on the
          screen. Select the corresponding value from the dropdown that matches
          your variable.
        </li>
      </ol>
      <p className="clone_para">
        The <code>textInputValue</code> passed in the above example is the value
        of the Data Source prop on the TextInput component. To find it, go to
        the component and from the Properties panel, go to the Configs tab:
      </p>
      <h2 id="configure-endpoint-delete" className="clone_head">
        Configure the Endpoint to Delete Existing Data
      </h2>
      <p className="clone_para">
        To delete an existing data record in the database of your choice, you
        will have to create an endpoint that submits a DELETE request. For
        example, let's consider the endpoint below:
      </p>
      <pre>
        <code>
          {`cURL
https://fakestoreapi.com`}
        </code>
      </pre>
      <p className="clone_para">
        It accepts the value of the id of an existing record to delete. You will
        add a new endpoint for the DELETE HTTP method by following these steps:
      </p>
      <ol>
        <li>
          Click <strong>Add endpoint</strong>.
        </li>
        <li>
          In Step 1: enter the Name for the endpoint. Make sure the Method
          selected is DELETE.
        </li>
        <li>
          Set the Role to <strong>Delete</strong> since the endpoint is creating
          a new record. Set the Object Type to <strong>todos</strong>.
        </li>
        <li>
          In Step 2: add the base name path and/or any query parameters to the
          API's base URL. Currently, the endpoint uses a dynamic variable{" "}
          <code>/{"id"}</code> to delete a record based on the value of the id
          provided.
        </li>
        <li>
          Provide an id of the existing record under Path Test Values or include
          it in the Body section.
        </li>
        <li>
          In Step 4: click the <strong>Test</strong> button next to the Endpoint
          input to verify the response coming from the API service.
        </li>
        <li>
          The last step is to save the endpoint. Click the <strong>Save</strong>{" "}
          button.
        </li>
      </ol>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <h2 id="trigger-api-request-delete" className="clone_head">
        Trigger an API Request Action to Delete an Existing Item
      </h2>
      <p className="clone_para">
        Once your components, API Service, and Endpoint are set up correctly,
        select the Button or Touchable component in the Components tree and go
        to the Interactions tab in the Properties panel (last tab on the
        right-hand side).
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={ButtonSheet} alt="" width="100%" />
      </span>
      <ol>
        <li>
          Click the <strong>+</strong> button next to Action.
        </li>
        <li>
          From the dropdown, select the action <strong>API Request</strong>.
        </li>
        <span style={{ display: "flex", justifyContent: "center" }}>
          <img src={ButtonBasic} alt="" width="100%" />
        </span>
        <li>Select the Service name and Endpoint name from the dropdowns.</li>
        <li>
          After you've made your selections, a Configuration section will
          appear. It will allow you to define the value of the path variable
          defined when creating the endpoint. Select the corresponding value
          from the dropdown that matches your variable.
        </li>
      </ol>
      <p className="clone_para">
        For this example, the dropdown next to the variable id is pre-populated
        with all the Data Sources from the List component.
      </p>
      <h2 id="complete-example" className="clone_head">
        Complete Example of Deleting an Existing Item in the List
      </h2>
      <p className="clone_para">
        Here is the complete example of deleting an existing item in the list.
      </p>
    </>
  );
};
