import React from "react";

const ButtonsList = () => {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Buttons</h1>
      </div>

      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th scope="col">Components</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Button
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Icon Button
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Pressable
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ButtonsList;
