import React from "react";
import publish from "../../../assets/Image/publish.png";
import Publish_version from "../../../assets/Image/Publish_version.png";

export const IntroToPublish = () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Version Publishing Overview</h1>
      </div>

      <p className="clone_para">Figma Clone supports publishing Version.</p>

      <h2 id="app-publishing-status" className="clone_head">
        Version Publishing Status
      </h2>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={publish} alt="" width="50%" />
      </span>

      <h2 id="publishing-environment" className="clone_head">
        Publishing Environment
      </h2>
      <p className="clone_para">
        When publishing your version , you can choose which environment values
        to use (Development or Production). You can select which environment to
        use from the top of the Publishing menu. Learn more in the Environments
        doc.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Publish_version} alt="" width="50%" />
      </span>

      <h3 id="additional-resources" className="clone_head">
        Additional Resources
      </h3>
      <p className="clone_para">
        Learn more about Role and Object Type when configuring an endpoint and
        why they are necessary for caching.
      </p>
    </>
  );
};
