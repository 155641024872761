import React from "react";

const FlexList = () => {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Flex Layout</h1>
      </div>

      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th scope="col">Components</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Aspect Ratio
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Center
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Circle
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Square
              </a>
            </td>
          </tr>
          {/* <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Linear Gradient
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Modal
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                H Stack
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                V Stack
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Z Stack
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Divider
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style={{ textDecoration: "underline" }}>
                Spacer
              </a>
            </td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
};

export default FlexList;
