import React from "react";

const SubScreen = () => {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Screens</h1>
      </div>
      <p className="clone_para">
        Screens are the foundation of your app's user interface (UI). All apps
        have one or more Screens, and each Screen can have their own Styling,
        Variables, Functions, and more.
      </p>
      <p className="clone_para">
        All the other available components, such as View, Button, and Text are
        nested inside a Screen in the component tree as child components.
      </p>
      <p className="clone_para">
        Screens can be added to Stack and Tab navigators to organize different
        parts of your app. You can also navigate between Screens using the
        Navigate Action from an interactive component like a Button, Link, or
        Pressable.
      </p>

      <h2 className="clone_head">Adding a Screen to your app</h2>
      <ul className="clone_para">
        <li>Click the + button in the top-right of the Screens list.</li>
        <li>
          Select Add Blank to add an empty Screen to your app or Add Example
          Screen to open the templates modal to select a pre-built Screen.
        </li>
      </ul>

      <h2 className="clone_head">Renaming a Screen</h2>
      <p className="clone_para">
        A Screen can be renamed by double-clicking it in the Screens list,
        typing a new name, and then hitting the Enter key.
      </p>
      <p className="clone_para">
        You can also rename a Screen by going to the Config tab and updating the
        Name prop.
      </p>

      <h2 className="clone_head">Style Properties</h2>
      <p className="clone_para">
        Style properties allow you to customize the look and feel of the
        component. Combinations of styles applied to components can be saved as
        Stylesheets to easily reuse styles throughout your app. Styles can also
        be set dynamically using Variables.
      </p>

      <h2 className="clone_head">Configuration Properties</h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Name</td>
            <td>
              To alter the name of the screen. The name is reflected in the
              Screen list at the top left in the builder, used when navigating
              using custom code, and in the file name of the actual codebase.
              Defaults to Blank.
            </td>
          </tr>
          <tr>
            <td>Description</td>
            <td>Add an optional description of the Screen.</td>
          </tr>
          <tr>
            <td>Status Bar Color</td>
            <td>
              This adjusts the color of the text (clock...) and icons
              (battery...) in the operating system's Status Bar to accommodate
              the background color. Defaults to Default.
            </td>
          </tr>
          <tr>
            <td>Scrollable</td>
            <td>
              If enabled, screen height will increase as per the content within
              it and will be scrollable to show the content in the visible area.
              Disabled by default.
            </td>
          </tr>
        </tbody>
      </table>

      <h2 className="clone_head">Advanced Configuration</h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Safe Area View</td>
            <td>
              When enabled, Safe Area is calculated for all sides of the device.
            </td>
          </tr>
          <tr>
            <td>Top Area View</td>
            <td>
              When enabled, Safe Area is calculated for the top side of the
              device.
            </td>
          </tr>
          <tr>
            <td>Bottom Area View</td>
            <td>
              When enabled, Safe Area is calculated for the bottom of the
              device.
            </td>
          </tr>
          <tr>
            <td>Left Area View</td>
            <td>
              When enabled, Safe Area is calculated for the left side of the
              device.
            </td>
          </tr>
          <tr>
            <td>Right Area View</td>
            <td>
              When enabled, Safe Area is calculated for the right side of the
              device.
            </td>
          </tr>
        </tbody>
      </table>

      <h2 className="clone_head">Data Properties</h2>

      <h5 className="clone_heading">Navigation Params</h5>
      <p className="clone_para">
        Navigation parameters allow you to pass data to a Screen when navigating
        to it. For instance, if you have a Screen with a List of properties and
        another Screen that displays a single property's details, you can add a
        navigation parameter to the details Screen of id.
      </p>
      <p className="clone_para">
        Then you'll be able to pass the list item's id value from the list
        Screen when you navigate to the details Screen where you can make a
        request to your API to fetch the details to display.
      </p>

      <h5 className="clone_heading">Deep Linking</h5>
      <p className="clone_para">
        Deep Linking is an advanced feature which allows you to open a specific
        Screen in your app from a website or another app.
      </p>
      <p className="clone_para">
        In order to access a Screen from a different app, type the provided URL
        into a web browser. This will only work if you're using the Draftbit
        Live Preview app or if you export your project
      </p>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Create Deep Link for this Screen </td>
            <td>Enable to create a deep link to the current Screen</td>
          </tr>
        </tbody>
      </table>

      <h2 className="clone_head">Triggers</h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>On Screen Focus</td>
            <td>Runs every time the user navigates to the Screen.</td>
          </tr>
          <tr>
            <td>On Screen Blur</td>
            <td>Runs every time the user navigates away from the Screen.</td>
          </tr>
        </tbody>
      </table>

      {/* <h2 className="clone_head">Updated</h2>
      <p className="clone_para">Last updated 6 days ago.</p> */}
    </div>
  );
};

export default SubScreen;
