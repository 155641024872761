import React, { useEffect, useState } from "react";
import Content from "./Content";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Loader from "../common/Loader/Loader";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const MainDocsPage = ({
  listItems,
  location,
  docId,
  selectedData,
  setSelectedData,
  tabs,
  setTabs,
  documentLoading,
  setDocumentLoading,
  specialItems,
  navigateForward,
  navigateBackward,
}) => {
  useEffect(() => {
    if (listItems) {
      setSelectedData(listItems[0]);
    }
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (docId) {
      setDocumentLoading(true);
      setTimeout(() => {
        setTabs(docId);
        setDocumentLoading(false);
      }, 400);
    }
  }, [docId]);

  useEffect(() => {
    if (location?.hash) {
      const element = document.getElementById(location?.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    if (docId) {
      for (let i = 0; i < listItems.length; i++) {
        if (listItems[i]?.url === docId) {
          setSelectedData(listItems[i]);
        }
      }
    }
  }, [docId]);

  const [expandedItems, setExpandedItems] = useState([]);

  const itemsWithIcons = [
    "Basic",
    "Buttons",
    "Views",
    "Flex Layout",
    "Containers",
    "Media",
    "Lists & Data",
    "Section List",
    "Swipeable List",
    "Table",
    "Form Inputs",
    "Form Controls",
    "Picker",
    "Maps",
    "Map Marker Cluster",
    "Swipesrs",
    "Swiper",
    "Deck Swiper",
    "Tab View",
    "Text Elements",
    "Overlays",
    "Action Sheet",
    "Utilities",
    "Tab Navigator",
  ];

  const toggleExpand = (url) => {
    if (expandedItems.includes(url.url)) {
      setExpandedItems(expandedItems.filter((item) => item !== url?.url));
    } else {
      setExpandedItems([...expandedItems, url?.url]);
    }
  };

  const renderList = (items) => (
    <ul className="nav">
      {items?.map((item, index) => {
        // Check if the item has a URL
        const hasUrl = item.url;

        return (
          <li key={index} className="nav-item">
            {hasUrl ? (
              <Link
                to={`/docs/${item.url}`}
                className={`nav-link ${tabs === item.url ? "active" : ""}`}
              >
                <span onClick={() => toggleExpand(item)}>
                  {itemsWithIcons.includes(item.name) && (
                    <ArrowForwardIosIcon
                      className={` ${
                        expandedItems.includes(item.url) ? "rotated" : "icon"
                      }`}
                    />
                  )}
                  {item.name}
                </span>
              </Link>
            ) : (
              // If it doesn't have a URL, just render the name
              <span className="nav-link special">{item.name}</span>
            )}

            {/* Render children if they exist and are expanded */}
            {item.children && expandedItems.includes(item.url) && (
              <ul className="sub-nav " style={{ paddingLeft: "15px" }}>
                {renderList(item.children)}
              </ul>
            )}
          </li>
        );
      })}
    </ul>
  );

  return (
    <div className="main_wrapper">
      <div className="container">
        <Row>
          <Col className="sidebar">{renderList(listItems)}</Col>

          {documentLoading ? (
            <Col className="loader-container">
              <Loader />
            </Col>
          ) : (
            <>
              <Col className="midsection">
                <Content
                  tabs={tabs}
                  navigateForward={navigateForward}
                  navigateBackward={navigateBackward}
                  docId={docId}
                  listItems={listItems}
                />
              </Col>
              <Col className="lastsection">
                <div>
                  <ul className="flex-column table-list display-flex">
                    <div className="flex-table-icon">
                      <FormatAlignLeftIcon />
                      <li>TABLE OF CONTENTS</li>
                    </div>
                  </ul>
                  <li className="table-children">
                    <ul>
                      {selectedData?.headings?.map((item) => (
                        <li key={item.url}>
                          <Link to={`/docs/${docId}#${item?.url}`}>
                            {item.name}
                          </Link>
                        </li>
                      ))}
                      {/* If selectedData has children, render their headings as well */}
                      {selectedData?.children?.map(
                        (child) =>
                          child.headings &&
                          child.headings.map((heading) => (
                            <li key={heading.url}>
                              <Link to={`/docs/${child.url}#${heading.url}`}>
                                {heading.name}
                              </Link>
                            </li>
                          ))
                      )}
                    </ul>
                  </li>
                </div>
              </Col>
            </>
          )}
        </Row>
      </div>
    </div>
  );
};

export default MainDocsPage;
