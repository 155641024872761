import React from "react";
import NotesBox from "../docPageNote/NotesBox";
import block_digram from "../../assets/Image/block_digram.png";
import Block_list from "../../assets/Image/Block_list.png";
import BlockComponenet from "../../assets/Image/BlockComponenet.png";
import Fetchlist from "../../assets/Image/Fetchlist.png";
import Compoenet_list from "../../assets/Image/Compoenet_list.png";
import Block_delete from "../../assets/Image/Block_delete.png";
import block_displayprops from "../../assets/Image/block_displayprops.jpg";
import block_in_screen from "../../assets/Image/block_in_screen.jpg";
import Bit_componenets from "../../assets/Image/Bit_componenets.png";
import Duplicate from "../../assets/Image/Duplicate.png";
import CustomBlock from "../../assets/Image/CustomBlock.png";
import block_data from "../../assets/Image/block_data.png";
import blocks_bg_selector from "../../assets/Image/blocks_bg_selector.jpg";
import PopupSaveblock from "../../assets/Image/PopupSaveblock.png";

const BlockPage = () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Blocks </h1>
      </div>

      <p className="clone_para">
        Blocks are saved groups of components that you can reuse throughout your
        app. They help reduce the need for duplication and make your app more
        manageable.
      </p>
      <p className="clone_para">
        Blocks are great for common design elements that are used throughout
        your app, such as custom buttons, cards, list items, or even more
        complex UI elements.
      </p>
      <p className="clone_para">
        For example, you may have a group of components that represent a 'Card'
        in your UI and want to use that same design element on multiple Screens
        in your app. With Blocks, you can save that group of components as a
        single Block component and then add instances of the Block to your
        Screens or even other Blocks. Whenever you update the Block using the
        Block builder, all instances of that Block throughout your app will also
        be updated.
      </p>
      <p className="clone_para">
        Combined with Stylesheets, Blocks can make building your app's UI much
        easier by requiring a lot less work when making design changes to shared
        components.
      </p>
      <p className="clone_para">Here's a simple explanation of the concept</p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={block_digram} alt="" width="80%" />
      </span>
      <NotesBox noteText="Blockdiagram" />
      <h5 id="conditional-display" className="clone_heading">
        {" "}
        Blocks View
      </h5>
      <p className="clone_para">
        To switch from Screens to Blocks, toggle the switcher at the top of the
        left bar in the Builder.
      </p>

      <h2 id="block-list" className="clone_head">
        Block List
      </h2>
      <p className="clone_para">
        The Blocks list is very similar to the Screens list. It shows all the
        Blocks you've created in your app.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Bit_componenets} alt="" width="50%" />
      </span>
      <h2 id="block-components" className="clone_head">
        Block Components
      </h2>
      <p className="clone_para">
        Just like with Screens, your Block's components are listed in the bottom
        section of the left panel in the Builder once you've selected a Block
        from the Blocks list. You can add components to your Blocks in the same
        way they are added to Screens.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={BlockComponenet} alt="" width="50%" />
      </span>
      <h2 id="block-properties" className="clone_head">
        Block Properties
      </h2>
      <p className="clone_para">
        Block Properties allow you to pass data and functionality into your
        Blocks when using them on a Screen or in another Block. This is
        especially handy when you have a Block that represents an item in a
        List, for instance. You can pass data from the List on your Screen into
        the Block's props and then use those values in your Block's components.
      </p>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <h3 className="clone_subhead">There are 4 types of Block properties:</h3>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Data</td>
            <td>
              Pass a string, number, boolean, or JSON to a Block. You can then
              pass that value to any components, functions, etc., inside the
              Block.
            </td>
          </tr>
          <tr>
            <td>Icon</td>
            <td>
              Lets you select an Icon from the Block instance using the Icon
              picker to pass into your Block. You can then pass that value to
              the Icon prop of a component that supports an icon (Icon, Icon
              Button, Checkbox, etc.) inside your Block.
            </td>
          </tr>
          <tr>
            <td>Asset</td>
            <td>
              Lets you pick an Asset from the Block instance using the Asset
              picker to pass into your Block. Then the Asset will be available
              to use in supported components (Image, Image Background, etc.)
              within your Block.
            </td>
          </tr>
          <tr>
            <td>Workflow</td>
            <td>
              Workflow properties let you pass an Action Workflow into a Block
              instance. Read below for more details.
            </td>
          </tr>
        </tbody>
      </table>
      <p className="clone_para">
        Block Properties are managed in the right panel Data tab when the
        Block's root (top-level) component is selected in the component tree.
      </p>
      {/* <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Fetchlist} alt="" width="50%" />
      </span>
      <p>
        All Block Properties will be available in the Variables selector for any
        data-enabled components within your Block such as Text, Button, Image,
        etc.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={block_prop_vars} alt="" width="50%" />
      </span>
      <h2 id="block-workflows" className="clone_head">
        Block Workflows
      </h2>
      <p className="clone_para">
        As mentioned above, Workflow properties on Blocks let you pass in an
        Action Workflow which you can then assign to one of the Block
        component's Triggers. Imagine you have a Block for a reusable Button and
        you want to trigger different Actions for that Button depending on where
        it’s being used. Now, you can create a Workflow prop on the Block.
      </p>
      <p className="clone_para">
        So in the case of our example Button Block, you can use the Run Custom
        Function Action on our Button’s On Press Trigger and pass it the
        Workflow prop you created. Then on the Screen where you use the Button
        Block, for the Workflow prop you’re able to select the Workflow from the
        Block's Interactions tab to open the Action Editor and create an Action
        Workflow to pass into that Block.
      </p>
      <p className="clone_para">
        Now, when the Button in the Block is pressed, it will trigger the Action
        Workflow you passed into the Block using the Workflow prop.
      </p>

      <h2 id="block-variables" className="clone_head">
        Block Variables
      </h2>
      <p className="clone_para">
        You can create Block Variables for your Blocks. These variables are
        scoped to the Block and are equivalent to Screen Variables on Screens.
      </p>
      <h3 className="clone_subhead">Synced Variables</h3>
      <p className="clone_para">
        When you create a Block Variable, you have the option of making it a
        'synced' variable. When enabled, the Block will expose this synced
        variable in the 'Synced Variables' section of the Blocks Data panel
        where you can pass in a Screen Variable. Once you've associated the
        Screen Variable with the synced Block Variable, the Block will keep both
        variables updated with the same value.
      </p>

      <h2 id="block-functions" className="clone_head">
        Block Functions
      </h2>
      <p className="clone_para">
        You can create Block Functions for Blocks. These functions are scoped to
        the Block and are equivalent to Screen Functions on Screens.
      </p>

      <h2 id="background-color-switcher" className="clone_head">
        Background Color Switcher
      </h2>
      <p className="clone_para">
        When editing a Block, you can switch the background color used in the
        Draft View using the selector above the app preview. This background is
        only applied when in Draft View while editing a Block. You can choose
        between Transparent, Light, and Dark.
      </p> */}
      {/* <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={blocks_bg_selector} alt="" width="50%" />
      </span> */}

      <h2 id="creating-blocks" className="clone_head">
        Creating Blocks
      </h2>
      <p className="clone_para">
        Blocks can be created in several different ways.
      </p>
      <h2 id="using-blocks" className="clone_head">
        From Screen Components
      </h2>
      <p className="clone_para">
        If you have an existing set of components on a Screen that you'd like to
        convert to a Block, from the component tree open the overflow menu for
        the parent component you want to use as a Block and click Save Custom
        Block.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={block_data} alt="" width="50%" />
      </span>
      <h2 id="using-blocks" className="clone_head"></h2>
      <p className="clone_para">
        In the same way you can create Blocks from groups of components on a
        Screen, you can do the same from a component within an existing Block.
      </p>
      <h2 id="using-blocks" className="clone_head">
        From Scratch
      </h2>
      <p className="clone_para">
        If you'd like to build a Block from a blank state, you can click the +
        button in the top right corner of the Blocks list. This will create a
        new Block that's ready for you to build out.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={CustomBlock} alt="" width="50%" />
      </span>

      <h2 id="duplicating-a-block" className="clone_head">
        Duplicating a Block
      </h2>
      <p className="clone_para">
        You can duplicate a Block in the same way you can duplicate a Screen.
        From the Blocks list, open the overflow menu for the Block you want to
        duplicate and click Duplicate.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Duplicate} alt="" width="50%" />
      </span>

      <h2 id="using-blocks" className="clone_head">
        Using Blocks
      </h2>
      <h2 id="using-blocks" className="clone_head">
        In Screens
      </h2>

      <p className="clone_para">
        Blocks can be added to any Screen in your app, or even another Block,
        from the component picker. Blocks can also be nested inside other
        components.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Block_list} alt="" width="50%" />
      </span>
      <p className="clone_para">
        Block instances will show up in yellow with a special icon in the Screen
        or Block's component tree so you can easily identify where your Blocks
        are being used.
      </p>

      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Compoenet_list} alt="" width="50%" />
      </span>
      <h2 id="passing-data" className="clone_head">
        In Other Blocks
      </h2>
      <p>
        You can use Blocks in other Blocks the same way you can use them in
        Screens as shown above.
      </p>
      <h2 id="passing-data" className="clone_head">
        Passing Data
      </h2>
      <p className="clone_para">
        As shown above, you can create data properties on your Blocks. Then when
        you add an instance of a Block to your Screen, you can pass any data
        into the Block's data properties from the Block's Data tab.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={block_data} alt="" width="50%" />
      </span>

      <h2 id="conditional-display" className="clone_head">
        Conditional Display
      </h2>
      <p className="clone_para">
        You can conditionally display a component based on a given condition.
        Learn more about conditionally displaying components in the Conditional
        Display documentation.
      </p>

      <h2 id="updating-blocks" className="clone_head">
        Updating Blocks
      </h2>
      <p className="clone_para">
        Select the Block you want to update from the list of Blocks in your app
        then make any changes to the components included in the Block.
      </p>
      {/* <p className="clone_para">
        ⚠️ When you make any changes to the Block and its child components, all instances of that Block in any Screens or other Blocks in your app will be updated as well.
      </p> */}
      <NotesBox noteText="DeletingBlocks" />

      <h2 id="deleting-blocks" className="clone_head">
        Deleting Blocks
      </h2>
      <p className="clone_para">
        From the Blocks list, open the overflow menu for the Block you want to
        delete and click Delete.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Block_delete} alt="" width="50%" />
      </span>
      <NotesBox noteText="Deletinganddetaching" />

      <h2 id="detaching-blocks" className="clone_head">
        Detaching Blocks
      </h2>
      <p className="clone_para">
        There may be times when you need to make a one-off customization of a
        Block and in that case, you can detach instances from their Blocks.
        Detaching a Block instance will replace the Block with a copy of the
        Block's components.
      </p>
      <p className="clone_para">
        Select the instance of the Block you want to detach in the component
        tree and open the overflow menu then click Unpack Block.
      </p>
      <NotesBox noteText="DetachingBlocks" />
    </>
  );
};

export default BlockPage;
