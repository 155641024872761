import React from "react";
import justify_content_ordered from "../../../assets/Image/justify_content_ordered.gif";
import flexdirection from "../../../assets/Image/flexdirection.gif";
import align_items from "../../../assets/Image/align_items.gif";
import Align_content from "../../../assets/Image/Align_content.gif";
import Wrap_reverse from "../../../assets/Image/Wrap_reverse.jpg";
import Wrap from "../../../assets/Image/Wrap.jpg";
import No_wrap_shrink from "../../../assets/Image/No_wrap_shrink.jpg";
import No_wrap from "../../../assets/Image/No_wrap.jpg";
import Alignself from "../../../assets/Image/Alignself.gif";
import NotesBox from "../../docPageNote/NotesBox";

const FlexLayout = () => {
  return (
    <>
      {/* Page Header */}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Flex Layout</h1>
      </div>
      {/* Flex Layout Introduction */}
      <p className="clone_para">
        Some components, such as a Screen or View, can specify the layout of its
        children using the Flexbox algorithm. Flexbox is designed to provide a
        consistent layout on different screen sizes. A component's Layout
        properties are available under the Styles tab in the right panel.
      </p>
      <p className="clone_para">
        Flex settings will define how your items are going to “fill” the
        available space along your main axis. Space will be divided according to
        each element's Flex property.
      </p>
      <p className="clone_para">
        You will generally use a combination of Flex Direction, Align Items, and
        Justify Content to achieve the right layout.
      </p>
      {/* Flex Items Section */}
      <h2 className="clone_head">Flex Items</h2>
      <h3 className="clone_heading">Flex Direction</h3>
      <p className="clone_para">
        Flex Direction controls the direction in which the children of a node
        are laid out. This is also referred to as the main axis. The cross axis
        is the axis perpendicular to the main axis, or the axis in which the
        wrapping lines are laid out.
      </p>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Column</td>
            <td>
              Align children from top to bottom. If wrapping is enabled, the
              next line will start to the right of the first item on the top of
              the container. Default.
            </td>
          </tr>
          <tr>
            <td>Row</td>
            <td>
              Align children from left to right. If wrapping is enabled, the
              next line will start under the first item on the left of the
              container.
            </td>
          </tr>
          <tr>
            <td>Row Reverse</td>
            <td>
              Align children from right to left. If wrapping is enabled, the
              next line will start under the first item on the right of the
              container.
            </td>
          </tr>
          <tr>
            <td>Column Reverse</td>
            <td>
              Align children from bottom to top. If wrapping is enabled, the
              next line will start to the right of the first item on the bottom
              of the container.
            </td>
          </tr>
        </tbody>
      </table>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={justify_content_ordered} alt="" width="50%" />
      </span>
      <h3 className="clone_heading">Align Items</h3>
      <p className="clone_para">
        Align Items describes how to align children along the cross axis of
        their container. It is very similar to Justify Content but instead of
        applying to the main axis, Align Items applies to the cross axis.
      </p>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Flex Start</td>
            <td>
              Align children of a container to the start of the container's
              cross axis.
            </td>
          </tr>
          <tr>
            <td>Center</td>
            <td>
              Align children of a container in the center of the container's
              cross axis.
            </td>
          </tr>
          <tr>
            <td>Flex End</td>
            <td>
              Align children of a container to the end of the container's cross
              axis.
            </td>
          </tr>
          <tr>
            <td>Stretch</td>
            <td>
              Stretch children of a container to match the height of the
              container's cross axis. Default.
            </td>
          </tr>
          <tr>
            <td>Baseline</td>
            <td>
              Align children of a container along a common baseline. Individual
              children can be set to be the reference baseline for their
              parents.
            </td>
          </tr>
        </tbody>
      </table>
      <NotesBox noteText="AlignItemsPage" />{" "}
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={align_items} alt="" width="50%" />
      </span>
      <h3 className="clone_heading">Justify Content</h3>
      <p className="clone_para">
        Justify Content describes how to align children within the main axis of
        their container. For example, you can use this property to center a
        child horizontally within a container with Flex Direction set to Row or
        vertically within a container with Flex Direction set to Column.
      </p>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Flex Start</td>
            <td>
              Align children of a container to the start of the container's main
              axis. Default.
            </td>
          </tr>
          <tr>
            <td>Flex End</td>
            <td>
              Align children of a container to the end of the container's main
              axis.
            </td>
          </tr>
          <tr>
            <td>Center</td>
            <td>
              Align children of a container in the center of the container's
              main axis.
            </td>
          </tr>
          <tr>
            <td>Space Between</td>
            <td>
              Evenly space off children across the container's main axis,
              distributing the remaining space between the children.
            </td>
          </tr>
          <tr>
            <td>Space Around</td>
            <td>
              Evenly space off children across the container's main axis,
              distributing the remaining space around the children. Compared to
              space-between, using space-around will result in space being
              distributed to the beginning of the first child and end of the
              last child.
            </td>
          </tr>
          <tr>
            <td>Space Evenly</td>
            <td>
              Evenly distribute children within the alignment container along
              the main axis. The spacing between each pair of adjacent items,
              the main-start edge and the first item, and the main-end edge and
              the last item, are all exactly the same.
            </td>
          </tr>
        </tbody>
      </table>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={flexdirection} alt="" width="50%" />
      </span>
      <h3 className="clone_heading">Gap</h3>
      <p className="clone_para">
        Gap sets the size of the gap (gutter) between rows and columns. In the
        Advanced section, you can set the value of Row Gap and Column Gap
        independently.
      </p>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Row Gap</td>
            <td>
              Sets the size of the gap (gutter) between an element's rows.
            </td>
          </tr>
          <tr>
            <td>Column Gap</td>
            <td>
              Sets the size of the gap (gutter) between an element's columns.
            </td>
          </tr>
        </tbody>
      </table>
      {/* New Section: Selected Item */}
      <h2 className="clone_head">Selected Item</h2>
      <h5 id="component-specific-styles" className="clone_heading">
        Align Item{" "}
      </h5>
      <p className="clone_para">
        Align Item for the Selected Item has the same options and effect as
        Align Items for Flex Items, but instead of affecting the children within
        a container, you can apply this property to a single child to change its
        alignment within its parent. This overrides any alignment option set by
        the parent for the specific item.
      </p>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Flex Start</td>
            <td>Align item to the start of the container's cross axis.</td>
          </tr>
          <tr>
            <td>Center</td>
            <td>Align item in the center of the container's cross axis.</td>
          </tr>
          <tr>
            <td>Flex End</td>
            <td>Align item to the end of the container's cross axis.</td>
          </tr>
          <tr>
            <td>Stretch</td>
            <td>
              Stretch item to match the height of the container's cross axis.
              Default.
            </td>
          </tr>
          <tr>
            <td>Baseline</td>
            <td>
              Align item along a common baseline. Individual children can be set
              to be the reference baseline for their parents.
            </td>
          </tr>
        </tbody>
      </table>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Alignself} alt="" width="50%" />
      </span>
      {/* Example of Flex Item */}
      <h2 className="clone_head">Flex Item </h2>
      <p className="clone_para">
        The Flex Item will define how your items are going to “fill” over the
        available space along your main axis. Space will be divided according to
        each element's flex property.
      </p>
      <h3 className="clone_heading">Advanced Flex Properties</h3>
      <p className="clone_para">
        In the Advanced section, you can also set values for the following
        properties:
      </p>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Basis</td>
            <td>
              Basis is an axis-independent way of providing the default size of
              an item along the main axis. The Basis of an item is the default
              size of that item, the size of the item before any Grow and Shrink
              calculations are performed.
            </td>
          </tr>
          <tr>
            <td>Grow</td>
            <td>
              Grow describes how much space within a container should be
              distributed among its children along the main axis. A container
              will distribute any remaining space among its children weighted by
              the children’s Grow values.
            </td>
          </tr>
          <tr>
            <td>Shrink</td>
            <td>
              Shrink describes how to shrink children along the main axis when
              the total size of the children overflows the size of the container
              on the main axis.
            </td>
          </tr>
        </tbody>
      </table>
      {/* Wrap Content */}
      <h3 className="clone_heading">Wrap Content</h3>
      <p className="clone_para">
        The Wrap property is set on containers and controls what happens when
        children overflow the size of the container along the main axis. By
        default, children are forced into a single line (which can shrink
        elements). If wrapping is allowed, items are wrapped into multiple lines
        along the main axis if needed.
      </p>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>None</td>
            <td>
              Forces all of the child elements onto one line, with the potential
              to force shrink the elements to make them fit.
            </td>
          </tr>
          <tr>
            <td>Wrap</td>
            <td>
              The child elements will be placed along multiple lines as needed,
              retaining their intended sizes.
            </td>
          </tr>
          <tr>
            <td>Reverse</td>
            <td>
              The same as Wrap, but the order of the child elements is reversed.
            </td>
          </tr>
        </tbody>
      </table>
      <p>No Wrap Example</p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={No_wrap} alt="" width="50%" />
      </span>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={No_wrap_shrink} alt="" width="50%" />
      </span>
      <p>Wrap Example</p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Wrap} alt="" width="50%" />
      </span>
      <p>Wrap Example</p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Wrap_reverse} alt="" width="50%" />
      </span>
      {/* Align Content */}
      <h3 className="clone_heading">Align Content</h3>
      <p className="clone_para">
        Align Content is only applicable when children are in a container set to
        Wrap Content, with multiple lines. It determines how the lines of
        elements are positioned along the cross-axis (opposite of the main
        axis).
      </p>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Flex Start</td>
            <td>
              Lines are grouped together and positioned at the beginning of the
              cross axis.
            </td>
          </tr>
          <tr>
            <td>Flex End</td>
            <td>
              Lines are grouped together and positioned at the end of the cross
              axis.
            </td>
          </tr>
          <tr>
            <td>Stretch</td>
            <td>
              Lines are stretched to the height of the container's cross axis.
            </td>
          </tr>
          <tr>
            <td>Center</td>
            <td>
              Lines are grouped together and positioned in the center of the
              container's cross axis.
            </td>
          </tr>
          <tr>
            <td>Space Between</td>
            <td>
              The first line is positioned at the beginning of the cross axis,
              the last element at the end, and all remaining lines are spread
              out evenly between.
            </td>
          </tr>
          <tr>
            <td>Space Around</td>
            <td>
              All lines are spread out across the cross axis of the container,
              with equal amounts of space surrounding them.
            </td>
          </tr>
        </tbody>
      </table>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Align_content} alt="" width="50%" />
      </span>
    </>
  );
};

export default FlexLayout;
