import React from "react";
import weight from "../../../assets/Image/weight.png";
import typo_fonts from "../../../assets/Image/typo_fonts.png";
import Typography from "../../../assets/Image/Typography.png";

export const TypographyPage = () => {
  return (
    <>
      {/* Page Header */}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Typography</h1>
      </div>

      {/* Typography Overview */}

      <p className="clone_para">
        Typography applies to components such as Text, Text Input, Styled Text
        Area, Button, etc. It is available in the Styles Panel.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Typography} alt="" width="50%" />
      </span>

      {/* Font Section */}
      <h3 className="clone_heading">Style</h3>
      <h4 className="clone_heading">Font</h4>
      <p className="clone_para">
        Draftbit supports Google Fonts. You can select the font of the Text with
        any of the pre-defined Google Fonts from the Font Picker.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={typo_fonts} alt="" width="50%" />
      </span>
      <p className="clone_para">
        For each font, you can also select the available font-weight on each
        font.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={weight} alt="" width="50%" />
      </span>
      <p className="clone_para">
        You can find a complete list of fonts at{" "}
        <a
          href="https://fonts.google.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          fonts.google.com
        </a>
        .
      </p>

      {/* Color Section */}
      <h4 className="clone_heading">Color</h4>
      <p className="clone_para">Select the color of the text.</p>

      {/* Align Section */}
      <h4 className="clone_heading">Align</h4>
      <p className="clone_para">Set the text alignment for the component.</p>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Auto</td>
            <td>
              Default value. Aligns text in the context to the parent component.
            </td>
          </tr>
          <tr>
            <td>Left</td>
            <td>Aligns text to the left.</td>
          </tr>
          <tr>
            <td>Right</td>
            <td>Aligns text to the right.</td>
          </tr>
          <tr>
            <td>Center</td>
            <td>Aligns text in the center.</td>
          </tr>
          <tr>
            <td>Justify</td>
            <td>
              Aligns text so it is spaced to line up its left and right edges to
              the left and right edges of the line box, except for the last
              line.
            </td>
          </tr>
        </tbody>
      </table>

      {/* Transform Section */}
      <h4 className="clone_heading">Transform</h4>
      <p className="clone_para">
        Transform the text using the following properties:
      </p>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Capitalize</td>
            <td>Capitalizes the first letter of each word.</td>
          </tr>
          <tr>
            <td>Uppercase</td>
            <td>Makes all text uppercase.</td>
          </tr>
          <tr>
            <td>Lowercase</td>
            <td>Makes all text lowercase.</td>
          </tr>
        </tbody>
      </table>

      {/* Decoration Section */}
      <h4 className="clone_heading">Decoration</h4>
      <p className="clone_para">Line decorations can be added to the text.</p>

      <h5 className="clone_heading">Line</h5>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Underline</td>
            <td>Adds a line under the text.</td>
          </tr>
          <tr>
            <td>Line-Through</td>
            <td>Adds a line through the text.</td>
          </tr>
          <tr>
            <td>Underline Line-Through</td>
            <td>Adds a line under the text and through the text.</td>
          </tr>
        </tbody>
      </table>

      {/* Decoration Color Section */}
      <h5 className="clone_heading">Color</h5>
      <p className="clone_para">
        Select the color of the line decoration. This is applicable only when a
        Line property is selected.
      </p>

      {/* Decoration Style Section */}
      <h5 className="clone_heading">Style</h5>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Solid</td>
            <td>Sets a solid line.</td>
          </tr>
          <tr>
            <td>Double</td>
            <td>Sets a double line.</td>
          </tr>
          <tr>
            <td>Dotted</td>
            <td>Sets a dotted line.</td>
          </tr>
          <tr>
            <td>Dashed</td>
            <td>Sets a dashed line.</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default TypographyPage;
