import React from "react";
import CreateEndpoint from "../../../assets/Image/CreateEndpoint.png";
import EditUpdate from "../../../assets/Image/EditUpdate.png";
import Urlanotomy from "../../../assets/Image/Urlanotomy.png";
import EndPointHeader from "../../../assets/Image/EndPointHeader.png";
import NotesBox from "../../docPageNote/NotesBox";

export const RestApiEndpoints = () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">REST API Endpoints</h1>
      </div>
      <p className="clone_para">
        Endpoints work with API Services to let your app connect to specific
        resources from your server or backend.
      </p>
      <p className="clone_para">
        First, make sure you've created at least one REST API Service in your
        app. After you've created a REST API Service, you'll be prompted to add
        new Endpoints for your service.
      </p>
      <p className="clone_para">
        An Endpoint is a specific path associated with your API service that is
        appended to the Dev URL or Prod URL. These are used to make HTTP
        requests from your application to your API using standard GET, POST,
        PUT, PATCH, and DELETE methods.
      </p>
      <p className="clone_para">
        Endpoints are usually associated with a particular resource. For
        example, if your app is about cooking, you might have a group of
        Endpoints for a 'recipes' resource so that users can perform CRUD
        operations (create, read, update, and delete) on recipes. And maybe
        another group of Endpoints for an 'articles' resource.
      </p>
      <p className="clone_para">
        These Endpoints can then be used by a Fetch component or API Request
        Action in your app to work with your data.
      </p>
      <NotesBox noteText="EndPointPage" />{" "}
      <h2 id="adding-endpoint" className="clone_head">
        Adding an Endpoint
      </h2>
      <p className="clone_para">
        To add an Endpoint, select one of your existing REST API Services in the
        API & Cloud Services modal, then simply click the{" "}
        <strong>Add New Endpoint</strong> button located in the Endpoints
        section.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={EditUpdate} alt="" width="80%" />
      </span>
      <h2 id="configuring-endpoint" className="clone_head">
        Configuring an Endpoint
      </h2>
      <p className="clone_para">
        Creating a new Endpoint requires several configuration steps that you'll
        be guided through. Depending on the type of Endpoint you are creating,
        some settings may not be available and others may be optional.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={CreateEndpoint} alt="" width="80%" />
      </span>
      <h3 id="basics" className="clone_heading">
        Basics
      </h3>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Name</td>
            <td>
              Add a name to the Endpoint to identify it in the Draftbit builder
              interface.
            </td>
          </tr>
          <tr>
            <td>Method</td>
            <td>
              Select an HTTP method to make the appropriate request depending on
              your API endpoint. Options include GET, POST, PUT, PATCH, and
              DELETE.
            </td>
          </tr>
        </tbody>
      </table>
      <h4 className="clone_heading">Object & Role</h4>
      <p className="clone_para">
        We utilize Objects and Roles in queries to optimize fetching and caching
        data in your app. They also help keep the UI updated when data changes
        somewhere in your app.
      </p>
      {/* <h4 className="clone_heading">
         You should only have one Object and Role combination (e.g., Get Many
        Posts) per API service.
      </h4> */}
      <NotesBox noteText="ObjectandRolePage" />{" "}
      <h5 className="clone_heading">Properties</h5>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Role</td>
            <td>
              Roles describe what this Endpoint intends to do, i.e., the verb
              (e.g., Get Many). You can select an option from the dropdown.
            </td>
          </tr>
          <tr>
            <td>Object</td>
            <td>
              Object describes what data type the Endpoint manipulates and is
              typically described with nouns (e.g., Post).
            </td>
          </tr>
        </tbody>
      </table>
      <h3 id="path-parameters" className="clone_heading">
        Path & Parameters
      </h3>
      <p className="clone_para">
        The Path will be appended to the Base URL you defined when creating the
        associated REST API Service. In this example,{" "}
        <code>https://api.example.com</code> is the Base URL for the API service
        and <code>/posts/123</code> is the Endpoint Path.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={Urlanotomy} alt="" width="80%" />
      </span>
      <p className="clone_para">
        You can use <code> variable </code> syntax in the path to create dynamic
        paths. For example, if we had a connection to the above example API, our
        Endpoint path to get a post by a dynamic value like id would look like
        this: <code>/posts/ id </code>.
      </p>
      <p className="clone_para">
        In the Query Parameters section, you can specify any standard query
        string parameters, and these can also be combined with the{" "}
        <code>variable </code> syntax. For example, we might have an endpoint
        for our API that accepts query string parameters to further refine the
        request, such as:
      </p>
      <code>/posts?author_id= author_id </code>
      <p className="clone_para">Or even something more complex, like this:</p>
      <code>
        /team/ id /posts?where[author_id][eq]=author_id
        &where[is_published][eq]= is_published
      </code>
      <h3 id="request-body" className="clone_heading">
        Request Body
      </h3>
      <p className="clone_para">
        Only available for POST, PUT, and PATCH requests. Here you can define a
        body structure using valid JSON format for the request. To create a
        variable for passing data, use <code>variable </code> syntax as values
        for the keys defined in the Body Structure. This way, you can map these
        variables when sending an API request from a Fetch component or API
        Request Action.
      </p>
      <p className="clone_para">
        For example, if we are creating an Endpoint that is making a POST
        request to our API that stores a new article in our database, we might
        pass a JSON structure like this as the body of our request:
      </p>
      {/* <code>
      {
        "title": {{title}},
        "body": {{content}}
      }
      </code> */}
      <p className="clone_para">
        Of course, you can also pass more complex JSON structures if your app
        requires it:
      </p>
      {/* <code>
      {
        "create": {
          "type": "post",
          "fields": [
            { "key": "title", "value": {{title}} },
            { "key": "content", "value": {{content}} }
          ]
        }
      }
      </code> */}
      <p className="clone_para">
        To make specific keys require a value within the Body Structure, you can
        add an exclamation point (!) to the end of the variable name. In the
        example below, the title is required, but body would be optional:
      </p>
      {/* <code>
      {
        "title": {{title!}},
        "body": {{body}}
      }
      </code> */}
      <h3 id="headers" className="clone_heading">
        Headers
      </h3>
      <p className="clone_para">
        Here you can set HTTP headers for this specific Endpoint. If you need to
        set HTTP headers for ALL Endpoints that will be associated with this
        Service, you can set them in the Service's configuration. Any header
        defined by the API service will be automatically added here.
      </p>
      <p className="clone_para">
        If you have existing headers set on the Service level and create the
        same headers for an Endpoint, the headers for the Endpoint will be used.
      </p>
      <p className="clone_para">
        You can type a value directly into the value field or select a variable
        that you've created from the dropdown.
      </p>
      <span style={{ display: "flex", justifyContent: "center" }}>
        <img src={EndPointHeader} alt="" width="100%" />
      </span>
      <h5 className="clone_heading">Header Properties</h5>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
            <th>Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Key</td>
            <td>The HTTP header name</td>
            <td>Authorization</td>
          </tr>
          <tr>
            <td>Value</td>
            <td>The value or variable associated with the header</td>
            <td>auth_token (Device Variable)</td>
          </tr>
        </tbody>
      </table>
      <NotesBox noteText="JsonDataPage" />{" "}
      <h3 id="variables" className="clone_heading">
        Variables
      </h3>
      <h5 className="clone_heading">
        {" "}
        Only available for requests using <code>{`{{variables}}`}</code>.
      </h5>
      <p className="clone_para">
        Here you can provide test values for any <code>{`{{variables}}`} </code>{" "}
        that you've included in the Path and Query Parameters, in addition to
        the Body Structure of a request using the POST, PUT, or PATCH methods.
        Type a value directly into the value field or select a Variable that
        you've created from the dropdown.
      </p>
      <NotesBox noteText="TestDefaultValuePage" />{" "}
      <h3 className="clone_heading">Test & Preview Response</h3>
      <p className="clone_para">
        Once you configured your Endpoint, you can then click to preview the
        full Endpoint URL and click the Test button to test the request before
        saving.
      </p>
      <p className="clone_para">
        When the request is successful, the response will be displayed, and you
        can finish by clicking the Save button. If the server returns a response
        with an error, the status code and message will be displayed.
      </p>
      <NotesBox noteText="SkipPage" />{" "}
      <p className="clone_para">
        Using Skip will let you use your Endpoint without testing, BUT you will
        not be shown a list of available fields for data returned by this
        Endpoint when using it in a Component or Action, and you'll lose other
        benefits from having a data schema associated with your Endpoint.
      </p>
      <p className="clone_para">
        If for some reason you really need to skip, you should come back and
        Test then re-save the Endpoint as soon as possible.
      </p>
      <h3 className="clone_heading">Editing an Endpoint</h3>
      <p className="clone_para">
        Click on one of your existing Services in the API & Cloud Services modal
        and select the Endpoint from the Endpoints section to adjust any of the
        previously configured settings.
      </p>
      <NotesBox noteText="ConfigurationPage" />{" "}
      <p className="clone_para">
        To make changes, it's recommended to update and re-fetch your schema or
        switch the API service to Manual Configuration Mode.
      </p>
      <h3 className="clone_heading">Duplicating an Endpoint</h3>
      <p className="clone_para">
        To duplicate an Endpoint, click the overflow menu (...) for the Endpoint
        you want to duplicate and then click Duplicate.
      </p>
      <h3 className="clone_heading">Deleting an Endpoint</h3>
      <p className="clone_para">
        To delete an Endpoint, click the overflow menu (...) for the Endpoint
        you want to delete and then click Delete.
      </p>
      <NotesBox noteText="Permanent" />{" "}
      <p className="clone_para">
        When you delete an Endpoint, all Fetch components and API Request
        Actions that reference the Endpoint will also be reset. This can't be
        undone!
      </p>
      <h3 className="clone_heading">CORS Issues</h3>
      <p className="clone_para">
        If you encounter CORS issues, you can update your server to allow access
        from the following origins:
      </p>
      <a href="https://figmaclone.webdemozone.com/#/workspace/feb-27">
        <li>https://figmaclone.webdemozone.com</li>
      </a>
      <h3 className="clone_heading">Additional Resources</h3>
      <li>
        <a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Request.mode">
          MDN Docs - Request.mode
        </a>
      </li>
      <li>
        <a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Request_Credentials">
          MDN Docs - Request Credentials
        </a>
      </li>
      <li>
        <a href="https://www.example.com/endpoint-what-is-an-api-endpoint">
          Endpoint – What is an API Endpoint?
        </a>
      </li>
    </>
  );
};
