import React from "react";
import NotesBox from "../docPageNote/NotesBox";

const Facq = () => {
  return (
    <div>
      {" "}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">FAQs</h1>
      </div>
      <div>
        <h2 id="why-react-native?" className="clone_head">
          Why React Native?{" "}
        </h2>
        <p className="clone_para">
          <a href="#">React Native </a>allows you to create cross-platform
          applications with JavaScript, eliminating the need to deal with
          separate codebases for Web, iOS, and Android.
        </p>
        <p className="clone_para">
          React Native lets you build mobile apps using JavaScript — but, unlike
          other JavaScript app building frameworks, a React Native app is a real
          mobile app using real iOS and Android native components. The tooling
          and ecosystem around React Native are the best of both worlds — the
          proven, efficient developer experience of working with React, and the
          user experience of the native mobile platforms.
        </p>
        <p className="clone_para">
          Before React Native, it was a requirement to know languages like Swift
          and Java (generally considered to be more difficult) to build mobile
          applications. Now, with React Native, it's easier than ever to get
          started in mobile app development thanks to the amount of resources
          available and size of the developer community.
        </p>
        <p className="clone_para">
          If you've developed web applications with React already, cool! React
          Native is very similar. If you haven't used React before, that's okay
          too! You don't need to write a single line of code to build a screen
          in Draftbit so don't be afraid to jump in and start learning.
        </p>
        <p className="clone_para">
          For further reading, check out the{" "}
          <a href="#">React Native Documentation.</a>
        </p>
        <h2 id="why-expo?" className="clone_head">
          Why Expo?
        </h2>
        <p className="clone_para">
          {" "}
          Expo is a free and open source toolchain built around React Native to
          help you build native iOS and Android projects using JavaScript and
          React. Expo is a layer on top of React Native that allows us to do
          things like easily work with the native APIs of the phone's operating
          system like the camera, GPS, photo library and more, live preview
          Draftbit apps during development, publish apps over the air in real
          time, and much more.
        </p>
        <h5 id="settings" className="clone_heading">
          Expo CLI
        </h5>

        <p className="clone_para">
          In order to continue working on your project outside of the Draftbit
          builder, you'll need to set up <a href="#">Expo CLI </a>on your
          computer. All you have to do is open up your terminal and run yarn
          global add expo-cli or npm install -g expo-cli.
        </p>

        <h2 id="expo-cli" className="clone_head">
          Why doesn't my app in the browser (Web Preview) match my app in Live
          Preview?
        </h2>

        <p className="clone_para">
          Web Preview is what your app will look like when run in a browser,
          whereas the iOS and Android Previews will render your app as a native
          app. The two are very similar, but due to differences in the
          environments there will be differences between web and native
          previews. There can also be differences between iOS and Android
          previews because each of these platforms also have their own way of
          interpreting styling.
        </p>
        <p className="clone_para">
          It's generally better to preview the most on whichever environment
          (web or native) you intend to launch your app on. Native previews will
          give let you preview the native experience and web preview the web
          experience.
        </p>
        <p className="clone_para">
          Some components have specific props for different platforms. There's
          also some helpers that let you identify which platform the app is
          being used on so that you can conditionally apply styling based on
          that.
        </p>
        <p className="clone_para">
          Here are some things you may encounter when viewing your app between
          the two:
        </p>

        <li>Small visual discrepancies</li>
        <p className="clone_para">
          Properties like fonts, line heights, can be calculated differently
          between Live Preview and Web Preview.
        </p>
        <li>Certain components will not appear in the browser</li>
        <p className="clone_para">
          You will be alerted to the components and prompted to view them in
          Live Preview
        </p>
        <p className="clone_para">
          If you are experiencing Web Preview/Live Preview issues that fall
          outside of the ones listed above, please feel free to reach out to our
          team for assistance.
        </p>

        <h2
          id="why-doesn't-my-app-in-the-browser-(Web-Preview)-match-my-app-in-Live-Preview?"
          className="clone_head"
        >
          Can I put my app on the App Store/Google Play Store?
        </h2>
        <p className="clone_para">
          Yes, you're able to deploy apps you build in Draftbit to the App
          Store/Google Play Store directly through our Builder OR by exporting
          your app code and building/publishing manually.
        </p>
        <p className="clone_para">
          Check out our documentation on publishing your app to the{" "}
          <a href="#">Apple App Store </a>and
          <a href="#">Google Play Store </a> for more on automated publishing.
        </p>
        <p className="clone_para">
          For self-publishing, take a look at the docs on{" "}
          <a href="#">Exporting Your Project</a>
          and self-publishing to app
          <a href="#"> Google Play </a> and <a href="#"> Apple App</a> .
        </p>
        <h2
          id="can-I-put-my-app-on-the-app-store/Google-play-store?"
          className="clone_head"
        >
          How do I request a feature?
        </h2>
        <p className="clone_para">
          Are we missing a critical capability or feature for you? If so, please
          head over to the <a href="#"> Roadmap </a> and see if it's already
          been requested.
        </p>
        <p className="clone_para">
          If it has, please upvote the topic and add a comment to let us know
          any additional thoughts or clarifications that are specific to you.
        </p>
        <p className="clone_para">
          If it hasn't, create a new request and let us know what you're looking
          for and any background or details.
        </p>
        <h2 id="how-do-i-request-a-feature?" className="clone_head">
          How do I report a bug or issue?{" "}
        </h2>
        <p className="clone_para">
          Sorry to hear you've found a bug or issue. The easiest way to report a
          bug to us is to head over to the <a href="#"> Help </a> category of
          our Community and create a topic with your bug/issue.
        </p>
        <p className="clone_para">
          By creating a topic, you'll get notified as we address these issues
          automatically.
        </p>
        <p className="clone_para">
          If the issue is more complex, or you're not sure how to describe it,
          just shoot us an email <a href="#"> help@draftbit.com </a> and we'll
          be happy to take a look!
        </p>
        <h2 id="how-do-i-report-a-bug-or-issue?" className="clone_head">
          How much does Draftbit cost?
        </h2>
        <p className="clone_para">
          Yes, you're able to deploy apps you build in Draftbit to the App
          Store/Google Play Store directly through our Builder OR by exporting
          your app code and building/publishing manually.
        </p>

        <h2 id="how-much-does-draftbit-cost?" className="clone_head">
          How much does Draftbit cost?
        </h2>
        <p className="clone_para">
          Please visit your account's <a href="#"> pricing page </a> for pricing
          information.
        </p>

        <h2 id="how-do-i-cancel-my-account?" className="clone_head">
          How do I cancel my account?
        </h2>
        <p className="clone_para">
          Please visit your <a href="#"> account page </a> to upgrade or
          downgrade your plan.
        </p>
        <p className="clone_para">
          If you wish to delete your account entirely, fill out this{" "}
          <a href="#"> Account Deletion Form</a> . Once we've received the
          results, we'll process your request and confirm your account and/or
          apps have been deleted. This could take up to 72 hours.
        </p>

        <h2
          id="can-i-share-my-draftbit-app-with-others?"
          className="clone_head"
        >
          Can I share my Draftbit app with others?
        </h2>
        <p className="clone_para">
          If you want to share a visual preview of the screen you're working on
          now, you can share your progress and changes live to as many people as
          you want through our Live Preview. Using the QR code generated for
          each project, you can have as many people as you want to follow along
          as you build.
        </p>
        <p className="clone_para">
          You can also share your project by publishing your project on the web.
          Doing so generates a unique URL that you can send to collaborators,
          clients, and friends that will allow them to view your project on an
          Android/iOS simulator.
        </p>
        <h2
          id="i-didn't-mean-to-delete-my-app-can-i-get-it-back?"
          className="clone_head"
        >
          I didn't mean to delete my app. Can I get it back?{" "}
        </h2>
        <p className="clone_para">
          Yes, it is possible for us to recover deleted applications. We retain
          deleted apps for 14 days from the time it was initially deleted.
          Please contact us within this time and we will be able to help you.
        </p>
        <h2 id="additional-resources" className="clone_head">
          Additional Resources
        </h2>
        <a href="#">
          <li style={{ paddingLeft: "15px" }}>
            Expo: Already used React Native?
          </li>
        </a>
        <a href="#">
          <li style={{ paddingLeft: "15px" }}>
            Expo: Frequently Asked Questions
          </li>
        </a>
      </div>
    </div>
  );
};

export default Facq;
