import React from "react";

const IconButtonPage = () => {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Icon Button</h1>
      </div>

      <p className="clone_para">
        An Icon Button is used to display only an icon without a label that can
        be interacted with. You can use it to trigger actions.
      </p>
      <p className="clone_para">
        See the Icon doc for a list of available open source icon libraries to
        browse from.
      </p>

      <h2 id="style-properties" className="clone_head">
        Style Properties
      </h2>
      <p className="clone_para">
        Style properties allow you to customize the look and feel of the
        component. Combinations of styles applied to components can be saved as
        Stylesheets to easily reuse styles throughout your app. Styles can also
        be set dynamically using Variables. To learn more about all the
        different styling properties and how they work, take a look at Intro to
        Styling.
      </p>

      <h2 id="component-specific-styles" className="clone_head">
        Component Specific Styles
      </h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Color</td>
            <td>To set the color of the Icon Button.</td>
          </tr>
          <tr>
            <td>Icon Size</td>
            <td>To set the size of the icon. Defaults to 32.</td>
          </tr>
        </tbody>
      </table>

      <h2 id="configuration-properties" className="clone_head">
        Configuration Properties
      </h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Component Name</td>
            <td>
              To alter the name of the component. The name is reflected in the
              Components tree. Defaults to Icon Button.
            </td>
          </tr>
        </tbody>
      </table>

      <h2 id="data-properties" className="clone_head">
        Data Properties
      </h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Disabled</td>
            <td>Disables the button when turned on.</td>
          </tr>
          <tr>
            <td>Icon</td>
            <td>Allows you to select an icon from the icon picker.</td>
          </tr>
          <tr>
            <td>Custom Icon</td>
            <td>
              Render an icon using a string of the icon's identifier. Overrides
              the 'icon' property. Full list of icons here. Ex:
              'Foundation/align-right', 'Feather/check'.
            </td>
          </tr>
        </tbody>
      </table>

      <h2 id="conditional-display" className="clone_head">
        Conditional Display
      </h2>
      <p className="clone_para">
        You can conditionally display a component based on a given condition.
        Learn more about conditionally displaying components in the Conditional
        Display doc.
      </p>

      <h2 id="triggers" className="clone_head">
        Triggers
      </h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>On Press</td>
            <td>Runs the Actions when the component is pressed.</td>
          </tr>
          <tr>
            <td>On Long Press</td>
            <td>Runs the Actions when the component is long pressed.</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default IconButtonPage;
