import React from "react";

const ViewPage = () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">View</h1>
      </div>
      <p className="clone_para">A fundamental container for building UIs.</p>
      <iframe
        className="video_iframe"
        src="https://www.youtube.com/embed/ePAPd9qzGyM"
        title="Server Components in React (Simple Tutorial)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <p className="clone_para">
        The most fundamental component for building a UI. View is a container
        that supports layout with flexbox, style, some touch handling, and
        accessibility controls. View maps directly to the native view equivalent
        on whatever platform React Native is running on, whether it's a UIView,
        android.view, etc.
      </p>

      <p className="clone_para">
        A View is designed to be nested inside other views and can have zero to
        many children of any type.
      </p>

      <h2 id="style-properties" className="clone_head">
        Style Properties
      </h2>
      <p className="clone_para">
        Style properties allow you to customize the look and feel of the
        component. Combinations of styles applied to components can be saved as
        Stylesheets to easily reuse styles throughout your app. Styles can also
        be set dynamically using Variables. To learn more about all the
        different styling properties and how they work, take a look at Intro to
        Styling.
      </p>

      <h2 id="configuration-properties" className="clone_head">
        Configuration Properties
      </h2>
      <h3>Basic</h3>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Component Name</td>
            <td>
              To alter the name of the component. The name is reflected in the
              Components tree. Defaults to View.
            </td>
          </tr>
        </tbody>
      </table>

      <h3>Advanced</h3>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>collapsable</td>
            <td>
              Enable to automatically remove the View from the native hierarchy
              if it's unneeded in order to optimize performance.
            </td>
          </tr>
          <tr>
            <td>hitSlop</td>
            <td>
              Defines how far away a touch event will be registered from the
              View.
            </td>
          </tr>
          <tr>
            <td>removeClippedSubviews</td>
            <td>
              Used for scrolling content that contains offscreen subviews
              (subviews must have overflow set to hidden).
            </td>
          </tr>
          <tr>
            <td>shouldRasterizeIOS</td>
            <td>Enable to render the View as a bitmap before compositing.</td>
          </tr>
          <tr>
            <td>pointerEvents</td>
            <td>Determines if a View can handle touch events.</td>
          </tr>
          <tr>
            <td>renderToHardwareTextureAndroid</td>
            <td>
              Determine if the View and its children should be rendered into one
              hardware texture on the GPU.
            </td>
          </tr>
          <tr>
            <td>needsOffscreenAlphaCompositing</td>
            <td>
              Determine if the View should be rendered offscreen and composited
              with alpha in order to preserve original colors and blending
              behaviour.
            </td>
          </tr>
        </tbody>{" "}
      </table>

      <h2 id="accessibility" className="clone_head">
        Accessibility
      </h2>
      <p className="clone_para">
        You can apply the following accessibility configurations on a View
        component:
      </p>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>accessibilityLiveRegion</td>
            <td>
              Determine how accessibility services should inform the user of any
              changes made to the View.
            </td>
          </tr>
          <tr>
            <td>importantForAccessibility</td>
            <td>
              Determines the View's importance for accessibility and also how
              accessibility events work.
            </td>
          </tr>
          <tr>
            <td>accessible</td>
            <td>
              When enabled, the element will be defined as accessible (defaults
              to false).
            </td>
          </tr>
          <tr>
            <td>accessibilityLabel</td>
            <td>
              Text in this input will override what the user's screen reader
              would read by default.
            </td>
          </tr>
          <tr>
            <td>accessibilityHint</td>
            <td>
              Additional explanation of what happens when a user interacts with
              an accessibility element.
            </td>
          </tr>
          <tr>
            <td>accessibilityElementsHidden</td>
            <td>
              Enable to hide accessibility elements within the View (defaults to
              false).
            </td>
          </tr>
          <tr>
            <td>accessibilityRole</td>
            <td>
              Defines the role of the element for the user's screen reader.
            </td>
          </tr>
          <tr>
            <td>accessibilityIgnoresInvertColors</td>
            <td>
              Enable to prevent the color of the View from being inverted if
              color inversion is turned on.
            </td>
          </tr>
        </tbody>
      </table>

      <h2 id="data-properties" className="clone_head">
        Data Properties
      </h2>
      <p className="clone_para">
        Conditional Display: You can conditionally display a component based on
        a given condition. Learn more about conditionally displaying components
        in the Conditional Display doc.
      </p>

      <h2 id="triggers" className="clone_head">
        Triggers
      </h2>
      <table
        className="table table-striped"
        style={{
          border: "1px solid var(--table-edges, #dfe2e5)",
          padding: "6px 13px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>On Layout</td>
            <td>
              Runs the Actions immediately once the layout has been calculated
              when the component is mounted and on layout changes. Actions for
              the On Layout Trigger will have access to an event variable which
              contains the event object.
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ViewPage;
